import moment from "moment/moment"
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { getHandleErrorVerify } from "src/container/DashboardPage/reducer/authHandlerReducer"
import { RequestOptions } from "src/helpers/RequestOptions"
import { formatDate } from "src/utils/formatter"
import request from "src/utils/request"
import { addCallLogsSuccess, addInboundCallLogsSuccess, addOutboundCallLogsSuccess, editInboundCallLogsSuccess, editOutboundCallLogsSuccess, exportDataSuccess, getAccountNoSuccess, getCallLogAddressSuccess, getCallLogDetailsSuccess, getCallLogEachDetailsSuccess, getComplainListSuccess, getViewAccountDetailsSuccess, updateCallStatusSuccess, editCallLogsSuccess, fetchMunicipalitySuccess } from "../reducer/callLogsReducer"
import { getCounterLogListSuccess, getCounterPerLocationSuccess } from "../reducer/counterCallLogsReducer"


const API_URL = process.env.REACT_APP_API_URL

function* getAccountNoCallLogs() {
    try {
        const response = yield call(request, `${API_URL}/cxp/call-logs/account-number/`)

        yield put(getAccountNoSuccess({
            res: {
                list: response.data,
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.status
            }
        }))
    }

}

function* getComplainList() {
    try {
        const response = yield call(request, `${API_URL}/settings/complains/list`)

        let inboundComplain = []
        let outboundComplain = []

        response?.map((el) => {
            if (el.TYPE === 'inbound') {
                inboundComplain.push(el)
            } else if (el.TYPE === 'outbound') {
                outboundComplain.push(el)
            }
        })

        yield put(getComplainListSuccess({
            res: {
                inboundComplain,
                outboundComplain
            }
        }))

    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.status
            }
        }))
    }

}

function* addCallInbound(payload) {
    try {
        const fields = payload.payload.fields
        const newFields = {
            DATE: fields.DATE,
            CUSTOMERTYPE: fields.CUSTOMERTYPE,
            ACCOUNTNUMBER: fields.ACCOUNTNUMBER,
            COMPLETENAME: fields.COMPLETENAME,
            STREET: fields.STREET,
            BARANGAY: fields.BARANGAY,
            MUNICIPALITY: fields.MUNICIPALITY,
            PROVINCE: fields.PROVINCE,
            CONTACTNO: fields.CONTACTNO,
            PURPOSEOFCALL: fields.PURPOSEOFCALL,
            CHANNEL: fields.CHANNEL,
            STATUS: fields.STATUS,
            REMARKS: fields.REMARKS
        }
        const requestBody = yield call(RequestOptions, 'POST', newFields)
        const response = yield call(request, `${API_URL}/cxp/call-logs/inbound`, requestBody)
        const { message, status } = response
        yield put(addInboundCallLogsSuccess({
            res: {
                message, status
            }
        }))
    } catch (error) {
        console.log(error.message)
    }


}

function* addCallLogs(payload) {
    try {
        const fields = payload.payload.fields
        const newFields = {
            ...fields,
        }
        const requestBody = yield call(RequestOptions, 'POST', newFields)
        const response = yield call(request, `${API_URL}/cxp/call-logs`, requestBody)

        const { message, status } = response

        yield put(addCallLogsSuccess({
            res: {
                message, status
            }
        }))
    } catch (error) {
        console.log(error.message)
    }
}

function* editCallLogs(payload) {
    try {
        const obj = payload.payload.fields
        const newFields = {
            ACCOUNTNUMBER: obj.ACCOUNTNUMBER,
            BARANGAY: obj.BARANGAY,
            CHANNEL: obj.CHANNEL,
            CONTACTNO: obj.CONTACTNO,
            CREATEATICKET: obj.CREATEATICKET,
            CUSTOMERNAME: obj.CUSTOMERNAME,
            DATE: moment(obj.DATE).format("yyyy-MM-DD"),
            DATETIMERECEIVED: obj.DATETIMERECEIVED,
            FROMCONVERGE: obj.FROMCONVERGE,
            ISSUE: obj.ISSUE,
            MUNICIPALITY: obj.MUNICIPALITY,
            PROVINCE: obj?.PROVINCE,
            REMARKS: obj.REMARKS,
            STATUS: obj.STATUS,
            STREET: obj.STREET,
            TIMEENDED: obj.TIMEENDED,
            TIMESTARTED: obj.TIMESTARTED,
            TYPEOFCOMPLAIN: obj.TYPEOFCOMPLAIN,
            TYPEOFCALL: obj.TYPEOFCALL
        }
        const id = obj.id
        if (id.trim().length !== 0) {
            const requestBody = yield call(RequestOptions, 'PUT', newFields)
            const response = yield call(request, `${API_URL}/cxp/call-logs/${id}`, requestBody)
            const { message, status } = response
            yield put(editCallLogsSuccess({
                res: {
                    message,
                    status
                }
            }))
        }
    } catch (error) {
        console.log(error.message)
    }
}


// function* fetchAddressSalesCall(payload) {
//     try {
//         const { location } = payload.payload
//         const response = yield call(request, `${API_URL}/sales/address?municipality=${location}`)
//         yield put(getCallLogAddressSuccess({
//             res: {
//                 data: response.barangays
//             }
//         }))
//     } catch (error) {
//         console.log(error.message)
//     }
// }


function* fetchAddressSalesCall(payload) {
    const { location, province } = payload.payload
    const response = yield call(request, `${API_URL}/sales/address?province=${province}&municipality=${location}`)
    yield put(getCallLogAddressSuccess({
        res: {
            data: response.barangays
        }
    }))
}

function* fetchAddressMunicipality(action){
    const { province } = action.payload
    const response = yield call(request, `${API_URL}/sales/address/municipality?province=${province}`)
    const { municipality } = response
    yield put(fetchMunicipalitySuccess({
        res: {
            data: municipality
        }        
    }))
}


function* addCallOutbound(payload) {
    try {
        const fields = payload.payload.fields
        const newFields = {
            ACCOUNTNUMBER: fields.ACCOUNTNUMBER,
            CALLTYPE: fields.CALLTYPE,
            DATE: fields.DATE,
            COMPLETENAME: fields.COMPLETENAME,
            ISCONTACTED: fields.ISCONTACTED,
            CHANNEL: fields.CHANNEL,
            REMARKS: fields.REMARKS
        }
        const requestBody = yield call(RequestOptions, 'POST', newFields)
        const response = yield call(request, `${API_URL}/cxp/call-logs/outbound`, requestBody)
        const { message, status } = response
        yield put(addOutboundCallLogsSuccess({
            res: {
                message, status
            }
        }))
    } catch (error) {
        console.log(error.message)
    }
}

function* fetchInboundCall() {
    const { dateRange } = yield select((state) => state.countersCallLogsReducer.filter)
    const response = yield call(request, `${API_URL}/cxp/call-logs/inbound?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    yield put(getCounterLogListSuccess({
        inbound: response.data
    }))
}

function* fetchOutbounddCall() {
    const { dateRange } = yield select((state) => state.countersCallLogsReducer.filter)
    const response = yield call(request, `${API_URL}/cxp/call-logs/outbound?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    yield put(getCounterLogListSuccess({
        outbound: response.data
    }))
}

function* fetchCallCard() {
    const { dateRange } = yield select((state) => state.countersCallLogsReducer.filter)
    const { fiber } = yield select((state) => state.callLogsReducer.filter)

    let response = {}

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/cxp/call-logs/call_card?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/cxp/call-logs/bida/call_card?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    }

    yield put(getCounterLogListSuccess({
        call_card: response.data
    }))
}

function* fetchCallBound() {
    yield all([
        call(fetchInboundCall),
        call(fetchOutbounddCall),
        call(fetchCallCard)
    ])
}

function* getPurposeCall() {
    const { dateRange } = yield select((state) => state.countersCallLogsReducer.filter)
    // const response = yield call(request, `${API_URL}/cxp/call-logs/counters/perpurpose?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    // const arr = {
    //     purpose: response.purposes || [],
    //     message: response.message
    // }
    // let labels = []
    // let values = []
    // arr?.purpose?.forEach(element => {
    //     labels.push(element.purpose)
    //     values.push(element.count)
    // });
    // yield put(getCounterPurposeSuccess({
    //     res: {
    //         labels,
    //         values
    //     }
    // }))

}


function* getLogsCall() {
    const { dateRange } = yield select((state) => state.countersCallLogsReducer.filter)
    // const response = yield call(request, `${API_URL}/cxp/call-logs/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    // const { counters, status } = response

    // const newCounter = {
    //     inbound: counters.inbound || 0,
    //     outbound: counters.outbound || 0,
    //     smm: counters.smm || 0
    // }
    // yield put(getCounterLogsCallSuccess({
    //     res: {
    //         newCounter,
    //         status
    //     }
    // }))
}

function* fetchCounterPerLocation() {
    const { dateRange } = yield select((state) => state.countersCallLogsReducer.filter)
    const { location } = yield select((state) => state.countersCallLogsReducer.graph)
    const locEntry = location.filtered.location
    const response = yield call(request, `${API_URL}/cxp/call-logs/counters/permunicipality?municipality=${locEntry}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    let locations = [],
        inbounds = [],
        outbounds = [];
    response?.barangays?.forEach(element => {
        locations.push(element.barangay)
        outbounds.push(element.outbound)
        inbounds.push(element.inbound)
    })
    yield put(getCounterPerLocationSuccess({
        res: {
            locations,
            inbounds,
            outbounds
        }
    }))
}

function* fetchCallLogsDetails(payload) {
    try {
        const { id, entry } = payload.payload.param
        const { fiber } = yield select((state) => state.callLogsReducer.filter)

        if (id !== undefined && entry !== undefined) {
            let response = []
            if (entry === 'inbound' || entry === 'outbound') {
                response = yield call(request, `${API_URL}/cxp/call-logs/${entry}/${id}`)

            }
            else if (entry === 'callcard') {
                if (fiber === 'fiberx') {
                    response = yield call(request, `${API_URL}/cxp/call-logs/call_card/${id}`)
                } else if (fiber === 'fiberbida') {
                    response = yield call(request, `${API_URL}/cxp/call-logs/bida/call_card/${id}`)
                }
            } else {
                return;
            }
            yield put(getCallLogDetailsSuccess({
                res: {
                    inbound: response?.data,
                    outbound: response?.data,
                    callcard: response?.data,
                    entry, id
                }
            }))
        }
    } catch (error) {
        console.log(error)
    }
}

function* fetchCallEachDetails(payload) {
    try {
        const { id, entry } = payload.payload.param
        if (id !== undefined && entry !== undefined) {
            const response = yield call(request, `${API_URL}/cxp/call-logs/${entry}/${id}`)
            yield put(getCallLogEachDetailsSuccess({
                res: {
                    id,
                    entry,
                    inbound: response?.data,
                    outbound: response?.data,
                }
            }))
        }
    } catch (error) {
        console.log(error.message)
    }
}

function* editCallInbound(payload) {
    try {
        const field = payload.payload.fields
        const newFields = {
            DATE: field.DATE,
            CUSTOMERTYPE: field.CUSTOMERTYPE,
            ACCOUNTNUMBER: field.ACCOUNTNUMBER,
            COMPLETENAME: field.COMPLETENAME,
            STREET: field.STREET,
            BARANGAY: field.BARANGAY,
            MUNICIPALITY: field.MUNICIPALITY,
            PROVINCE: field.PROVINCE,
            CONTACTNO: field.CONTACTNO,
            PURPOSEOFCALL: field.PURPOSEOFCALL,
            CHANNEL: field.CHANNEL,
            STATUS: field.STATUS,
            REMARKS: field.REMARKS,
        }
        const requestBody = yield call(RequestOptions, 'PUT', newFields)
        const response = yield call(request, `${API_URL}/cxp/call-logs/inbound/${field._id}`, requestBody)
        const { message, status } = response
        yield put(editInboundCallLogsSuccess({
            res: {
                message,
                status
            }
        }))
    } catch (error) {
        console.log(error.message)
    }

}

function* editCallOutbound(payload) {
    try {
        const field = payload.payload.fields
        const newFields = {
            DATE: field.DATE,
            CALLTYPE: field.CALLTYPE,
            ACCOUNTNUMBER: field.ACCOUNTNUMBER,
            COMPLETENAME: field.COMPLETENAME,
            ISCONTACTED: field.ISCONTACTED,
            CHANNEL: field.CHANNEL,
            REMARKS: field.REMARKS
        }
        const requestBody = yield call(RequestOptions, 'PUT', newFields)
        const response = yield call(request, `${API_URL}/cxp/call-logs/outbound/${field._id}`, requestBody)
        const { message, status } = response
        yield put(editOutboundCallLogsSuccess({
            res: {
                message,
                status
            }
        }))
    } catch (error) {
        console.log(error.message)
    }
}

function* updateEachCallStatus(action) {
    try {
        const { fiber } = yield select((state) => state.callLogsReducer.filter)

        const field = action.payload.payload
        const newFields = {
            CXP_REMARKS: field?.CXP_REMARKS,
            CXP_STATUS: field?.CXP_STATUS,
            INSTALLATION_SCHEDULE: field?.INSTALLATION_SCHEDULE,
            CXP_DATETIMESTARTED: field?.CXP_DATETIMESTARTED,
            CXP_DATETIMEENDED: field?.CXP_DATETIMEENDED,
        }

        const requestBody = yield call(RequestOptions, 'PATCH', newFields)

        let response = {}

        if (fiber === 'fiberx') {
            response = yield call(request, `${API_URL}/cxp/call-logs/${field?.CXP_ID}/status`, requestBody)
        } else if (fiber === 'fiberbida') {
            response = yield call(request, `${API_URL}/cxp/call-logs/bida/${field?.CXP_ID}/status`, requestBody)
        }

        const { message, status } = response

        yield put(updateCallStatusSuccess({
            res: {
                newFields,
                message,
                status
            }
        }))
    } catch (error) {
        console.log(error.message)
    }

}

function* getDetailsAccNo(action) {
    try {
        const { accNo } = action.payload
        const response = yield call(request, `${API_URL}/cxp/call-logs/account-number/${accNo}`)
        const { data, message, status } = response
        yield put(getViewAccountDetailsSuccess({
            res: {
                data: data[0],
                message,
                status,
            }
        }))
    } catch (error) {
        console.log(error.message)
    }
}

function* exportData(action) {
    try {
        let { type } = action.payload
        const { dateRange } = yield select((state) => state.countersCallLogsReducer.filter)
        const response = yield call(request, `${API_URL}/cxp/call-logs/${type}/export?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`, {}, true)
        if (response?.status === 'error') {
            const { message, status } = response
            yield put(exportDataSuccess({
                res: {
                    message,
                    status,
                }
            }))
        }
        else {
            var file = window.URL.createObjectURL(response);
            window.location.assign(file);

            yield put(exportDataSuccess({
                res: {
                    message: 'Export Data Success',
                    status: 'success',
                }
            }))
        }
    } catch (error) {

    }
}




function* callLogsSaga() {
    yield takeLatest('callLogs/getAccountNo', getAccountNoCallLogs)
    yield takeLatest('callLogs/getComplainList', getComplainList)
    yield takeEvery('callLogs/addInboundCallLogs', addCallInbound)
    yield takeLatest('callLogs/getCallLogAddress', fetchAddressSalesCall)
    yield takeLatest('callLogs/fetchMunicipality', fetchAddressMunicipality)
    yield takeEvery('callLogs/addOutboundCallLogs', addCallOutbound)
    yield takeLatest('callLogsCounter/getCounterLogList', fetchCallBound)
    yield takeLatest('callLogsCounter/getCounterPurpose', getPurposeCall)
    yield takeLatest('callLogsCounter/getCounterLogsCall', getLogsCall)
    yield takeLatest('callLogsCounter/getCounterPerLocation', fetchCounterPerLocation)
    yield takeLatest('callLogs/getCallLogDetails', fetchCallLogsDetails)
    yield takeEvery('callLogs/getCallLogEachDetails', fetchCallEachDetails)
    yield takeEvery('callLogs/editInboundCallLogs', editCallInbound)
    yield takeEvery('callLogs/editOutboundCallLogs', editCallOutbound)
    yield takeEvery('callLogs/updateCallStatus', updateEachCallStatus)
    yield takeLatest('callLogs/getViewAccountDetails', getDetailsAccNo)
    yield takeEvery('callLogs/addCallLogs', addCallLogs)
    yield takeEvery('callLogs/exportData', exportData)
    yield takeEvery('callLogs/editCallLogs', editCallLogs)
    
}

export default callLogsSaga