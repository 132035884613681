import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
    name: 'sidebars',
    initialState: {
        sidebar: {
            unfoldable: false,
            sidebarShow: false
        }
    },
    reducers: {
        getSidebarShow: (state, payload) => {
            state.sidebar.sidebarShow = payload.payload
        },
        getUnfoldable: (state, payload) => {
            state.sidebar.unfoldable = payload.payload
        }
    }
})

export const { getUnfoldable, getSidebarShow } = sidebarSlice.actions
export default sidebarSlice.reducer