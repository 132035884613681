import { createSlice } from "@reduxjs/toolkit";

const portLocationSliceData = createSlice({
    name: 'portLocationData',
    initialState: {
        isLoading: false,
        data: {
            locations: [],
            utilized: [],
            deployed: [],
            targets: []
        }
    },
    reducers: {
        getPortPerLocationData: (state) => {
            state.isLoading = true
        },
        getPortPerLocationDataSuccess: (state, payload) => {
            state.isLoading = false
            state.data.locations = payload.payload.data.locations
            state.data.utilized = payload.payload.data.utilized
            state.data.deployed = payload.payload.data.deployed
            state.data.targets = payload.payload.data.targets

        }
    }
})


export const { getPortPerLocationData, getPortPerLocationDataSuccess } = portLocationSliceData.actions
export default portLocationSliceData.reducer
