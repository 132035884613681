import { createSlice } from "@reduxjs/toolkit";

const authHandlerSlice = createSlice({
    name: 'authHandler',
    initialState: {
        isLoading: false,
        error: {
            message: null,
            status: null
        },
        user: []
    },
    reducers: {
        getHandleError: (state) => {
            state.isLoading = true
        },
        getHandleErrorVerify: (state, payload) => {
            state.isLoading = false
            state.error.message = payload.payload.res.message
        },
        getUserDetails: (state) => {
            state.isLoading = true
        },
        getUserDetailsSuccess: (state, payload) => {
            state.isLoading = false
            state.user = payload.payload.res?.response?.user
        },
        setDispose: (state, payload) => {
            state.error = payload.payload
        },
        setDisposeUser: (state) => {
            state.user = []
        }
    }
})

export const {
    getHandleError,
    getHandleErrorVerify,
    setDispose,
    getUserDetails,
    getUserDetailsSuccess,
    setDisposeUser
} = authHandlerSlice.actions
export default authHandlerSlice.reducer