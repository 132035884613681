import { takeEvery, call, put } from 'redux-saga/effects'
import request from 'src/utils/request'
import { RequestOptions } from 'src/helpers/RequestOptions'
import { getTargetSettingsSuccess, updateTargetSettingsSuccess } from '../reducer/targetSettingsReducer'
import { getHandleError, getHandleErrorVerify } from '../reducer/authHandlerReducer';

const API_URL = process.env.REACT_APP_API_URL;

function* fetchTargetSettings() {
  // call endpoint for targetSettings
  const targets = yield call(request, API_URL + '/targets');
  // set targetSettings value from response
  yield put(getTargetSettingsSuccess({ targets }));
}

function* putTargetSettings(inputs) {

  try {
    const obj = {
      AVERAGEREVENUEPERLINE: inputs.payload.AVERAGEREVENUEPERLINE,
      PORTUTILIZATIONRATE: inputs.payload.PORTUTILIZATIONRATE,
      TURNINACCOUNTS: inputs.payload.TURNINACCOUNTS,
      CLAWBACKRATE: inputs.payload.CLAWBACKRATE,
      CUSTOMERCHURNRATE: inputs.payload.CUSTOMERCHURNRATE,
      TICKETSRESOLVEDPERDAY: inputs.payload.TICKETSRESOLVEDPERDAY,
      AVERAGERESOLUTIONTIMET1: inputs.payload.AVERAGERESOLUTIONTIMET1,
      AVERAGERESOLUTIONTIMET2: inputs.payload.AVERAGERESOLUTIONTIMET2,
      AVERAGEINSTALLATIONTIME: inputs.payload.AVERAGEINSTALLATIONTIME,
      SALESFORECASTRATE: inputs.payload.SALESFORECASTRATE
    };
    // call endpoint for targetSettings
    const ro = yield call(RequestOptions, 'post', obj);
    const targets = yield call(request, API_URL + '/targets', ro);
    // set targetSettings value from response
    yield put(updateTargetSettingsSuccess({ targets }));

  } catch (error) {
    yield put(getHandleErrorVerify({
      res: {
        message: error.response.status
      }
    }))
  }

}
// watcher saga: will listen for actions and call worker saga
// takeEvery({reducer name}/function, worker saga, args for worker saga)
function* targetSettingsSaga() {
  yield takeEvery('targetSettings/getTargetSettings', fetchTargetSettings)
  yield takeEvery('targetSettings/updateTargetSettings', putTargetSettings)
}

export default targetSettingsSaga
