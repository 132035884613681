import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

const initialState = {
    filter: {
        dateRange: datePresets[2].value,
        fiber: 'fiberx'
    },
    materials: {
        isLoading: false,
        counters: [],
        data: []
    },
    graph: {
        status: {
            isLoading: false,
            data: [],
            value: []
        },
        issue: {
            isLoading: false,
            data: [],
            value: []
        },
    },
    counters: {
        isLoading: false,
        data: {
            totalJOReceived: 0,
            totalFOCInstalled: 0,
            totalJOCompleted: 0,
            JOPercentCompletion: 0,
            averageInstallationPerTeam: 0,
            totalTeam: 0,
            totalInstallers: 0,
            aveTimeSpentPerInstallation: 0,
            kpiTotalJOReceived: 0,
            kpiTotalFOCInstalled: 0,
            kpiTotalJOCompleted: 0,
            kpiJOPercentCompletion: 0,
            kpiAveInstallationPerTeam: 0,
            kpiTotalTeam: 0,
            kpiTotalInstallers: 0,
            kpiAveTimeSpentPerTeam: 0
        },
        update: {
            field: {
                TOTALJORECEIVED: '',
                AVERAGEINSTALLATIONTEAM: '',
                TOTALTEAM: '',
                TOTALINSTALLERS: '',
                AVERAGETIMESPENTINSTALLATION: '',
                EFFECTIVEDATE: ''
            },
            response: {
                message: '',
                status: '',
                error: ''
            }
        }
    },
    employee: {
        isLoading: false,
        data: {
            list: []
        },
        field: {
            name: '',
            members: []
        },
        teams: []
    }
}

const installtionCounterSlice = createSlice({
    name: 'installCounter',
    initialState,
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        getMaterials: (state) => {
            state.materials.isLoading = true
        },
        getMaterialsSuccess: (state, action) => {
            state.materials.isLoading = false
            state.materials.data = action.payload.res.list
        },
        getMaterialsUsed: (state) => {
            state.materials.isLoading = true
        },
        getMaterialsUsedSuccess: (state, action) => {
            state.materials.isLoading = false
            state.materials.counters = action.payload.res.data
        },
        getStatusCardGraph: (state) => {
            state.graph.status.isLoading = true
        },
        getStatusCardGraphSuccess: (state, payload) => {
            state.graph.status.isLoading = false

            state.graph.status.data = payload.payload.data
            state.graph.status.value = payload.payload.value
        },
        getIssueCardGraph: (state) => {
            state.graph.issue.isLoading = true
        },
        getIssueCardGraphSuccess: (state, payload) => {
            state.graph.issue.isLoading = false

            state.graph.issue.data = payload.payload.data
            state.graph.issue.value = payload.payload.value
        },
        getCardTotalCounters: (state) => {
            state.counters.isLoading = true
        },
        getCardTotalCountersSuccess: (state, payload) => {
            state.counters.isLoading = false

            state.counters.data.totalJOReceived = payload.payload.total_jo_received
            state.counters.data.totalFOCInstalled = payload.payload.total_foc_installed
            state.counters.data.totalJOCompleted = payload.payload.total_jo_completed
            state.counters.data.JOPercentCompletion = payload.payload.jo_percent_completion
            state.counters.data.averageInstallationPerTeam = payload.payload.average_installation_per_team
            state.counters.data.totalTeam = payload.payload.total_team
            state.counters.data.totalInstallers = payload.payload.total_installers
            state.counters.data.aveTimeSpentPerInstallation = payload.payload.average_time_spent_per_installation

            state.counters.data.kpiTotalJOReceived = payload.payload.kpi_total_jo_received
            state.counters.data.kpiTotalFOCInstalled = payload.payload.kpi_total_foc_installed
            state.counters.data.kpiTotalJOCompleted = payload.payload.kpi_total_jo_completed
            state.counters.data.kpiJOPercentCompletion = payload.payload.kpi_jo_percent_completion
            state.counters.data.kpiAveInstallationPerTeam = payload.payload.kpi_average_installation_per_team
            state.counters.data.kpiTotalTeam = payload.payload.kpi_total_team
            state.counters.data.kpiTotalInstallers = payload.payload.kpi_total_installers
            state.counters.data.kpiAveTimeSpentPerTeam = payload.payload.kpi_average_time_spent_per_team

            state.counters.update.field.TOTALJORECEIVED = payload.payload.kpi_total_jo_received
            state.counters.update.field.AVERAGEINSTALLATIONTEAM = payload.payload.kpi_average_installation_per_team
            state.counters.update.field.TOTALTEAM = payload.payload.kpi_total_team
            state.counters.update.field.TOTALINSTALLERS = payload.payload.kpi_total_installers
            state.counters.update.field.AVERAGETIMESPENTINSTALLATION = payload.payload.kpi_average_time_spent_per_team
        },
        updateCardTotalCounters: (state) => {
            state.counters.isLoading = true
        },
        updateCardTotalCountersSuccess: (state, payload) => {
            state.counters.isLoading = false
            state.counters.update.response.message = payload.payload.res.message
            state.counters.update.response.status = payload.payload.res.status
            state.counters.update.response.error = payload.payload?.res?.error
        },
        setDispose: (state, payload) => {
            state.counters.update.response = payload.payload
        },
        getEmployeeListDept: (state) => {
            state.employee.isLoading = true
        },
        getEmployeeListDeptSuccess: (state, action) => {
            state.employee.isLoading = false
            state.employee.data = { ...action.payload.res }
            state.employee.teams = action.payload.res.teams
        },
        addTeams: (state) => {
            state.employee.isLoading = true
        },
        addTeamsSuccess: (state, action) => {
            state.employee.isLoading = false
        },
        setFieldEmp: (state, action) => {
            state.employee.field[action.payload.field] = action.payload.value
        },
        getEachTeams: (state) => {
            state.employee.isLoading = true
        },
        getEachTeamsSuccess: (state, action) => {
            state.employee.isLoading = false
            state.employee.field.members = action.payload.res.list
            state.employee.field.name = action.payload.res.name
        },
        setSelectedMember: (state, action) => {
            state.employee.field.members = action.payload
        }
    }
})

export const {
    setFilter,
    getMaterials,
    getMaterialsSuccess,
    getMaterialsUsed,
    getMaterialsUsedSuccess,
    getStatusCardGraph,
    getStatusCardGraphSuccess,
    getIssueCardGraph,
    getIssueCardGraphSuccess,
    getCardTotalCounters,
    getCardTotalCountersSuccess,
    updateCardTotalCounters,
    updateCardTotalCountersSuccess,
    setDispose,
    getEmployeeListDept,
    getEmployeeListDeptSuccess,
    addTeams,
    addTeamsSuccess,
    setFieldEmp,
    getEachTeams,
    getEachTeamsSuccess,
    setSelectedMember
} = installtionCounterSlice.actions

export default installtionCounterSlice.reducer