import { call, put, takeLatest } from 'redux-saga/effects'
import request from '../../utils/request'
import { RequestOptions } from 'src/helpers/RequestOptions'
import LS from 'src/helpers/LocalStorage'
import { loginError, loginSuccess } from './reducer';

const API_URL = process.env.REACT_APP_API_URL;


function* tryLogin(params) {
  // call endpoint for recent activity list
  let res;
  try {
    const obj = {
      USERNAME: params.payload.USERNAME,
      PASSWORD: params.payload.PASSWORD,
    }
    const ro = yield call(RequestOptions, 'post', obj);
    res = yield call(request, API_URL + '/auth/login', ro);
    yield put(loginError(res.message))
    if (res?.accessToken) {
      LS.writeLocalStorage('token', res.accessToken)
      LS.writeLocalStorage('name', res.employee.NAME)
      yield put(loginSuccess(res.employee))
    }
  }
  catch (e) {
    yield put(loginError(e.response.body))
  }
}


// watcher saga: will listen for actions and call worker saga
// takeEvery({reducer name}/function, worker saga, args for worker saga)
function* loginSaga() {
  yield takeLatest('login/tryLogin', tryLogin)

}

export default loginSaga
