import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

const initialState = {
    bound: 'inbound',
    inbound: {
        isLoading: false,
        fields: {
            id: '',
            DATE: '',
            CUSTOMERTYPE: '',
            ACCOUNTNUMBER: '',
            COMPLETENAME: '',
            STREET: '',
            BARANGAY: '',
            MUNICIPALITY: '',
            PROVINCE: '',
            CONTACTNO: '',
            PURPOSEOFCALL: '',
            CHANNEL: '',
            STATUS: '',
            REMARKS: ''
        }
    },
    outbound: {
        isLoading: false,
        fields: {
            id: '',
            DATE: '',
            CALLTYPE: '',
            ACCOUNTNUMBER: '',
            COMPLETENAME: '',
            ISCONTACTED: '',
            CHANNEL: '',
            REMARKS: ''
        }
    },
    logs: {
        isLoading: false,
        fields: {
            id: '',
            DATE: '',
            TIMESTARTED: '',
            TIMEENDED: '',
            TYPEOFCALL: '',
            ACCOUNTNUMBER: '',
            CUSTOMERNAME: '',
            CONTACTNO: '',
            STREET: '',
            PROVINCE: '',
            MUNICIPALITY: '',
            BARANGAY: '',
            TYPEOFCOMPLAIN: null,
            ISSUE: null,
            ISSUENAME: '',
            STATUS: '',
            CHANNEL: '',
            REMARKS: '',
            CREATEATICKET: false,
            FROMCONVERGE: false,
            DATETIMERECEIVED: '',
            CALLHISTORY: '',
            initIsTicket: false
        }
    },
    cxpStatus: {
        isLoading: false,
        fields: {
            INSTALLATION_SCHEDULE: '',
            CXP_DATETIMESTARTED: '',
            CXP_DATETIMEENDED: '',
            CXP_STATUS: '',
            CXP_REMARKS: '',
        }
    },
    response: {
        message: '',
        status: ''
    },

    accountNo: {
        isLoading: false,
        list: []
    },
    complains: {
        isLoading: false,
        inboundComplain: [],
        outboundComplain: []
    },
    address: {
        params: {
            location: ''
        },
        isLoading: false,
        data: [],
        municipality: []
    },

    details: {
        isLoading: false,
        inbound: {},
        outbound: {},
        callcard: {}
    },

    filter: {
        fiber: 'fiberx'
    },

    isExporting: false,

}

const callLogsSlice = createSlice({
    name: 'callLogs',
    initialState,
    reducers: {
        setBound: (state, action) => {
            state.bound = action.payload
        },
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        setInboundField: (state, payload) => {
            state.inbound.fields[payload.payload.field] = payload.payload.value
        },
        setLogsField: (state, payload) => {
            state.logs.fields[payload.payload.field] = payload.payload.value
        },
        setDisposeMsg: (state, action) => {
            state.response = action.payload
        },
        addInboundCallLogs: (state) => {
            state.inbound.isLoading = true
        },
        addInboundCallLogsSuccess: (state, payload) => {
            state.inbound.isLoading = false
            state.response = { ...payload.payload.res }
        },
        getAccountNo: (state) => {
            state.accountNo.isLoading = true
        },
        getAccountNoSuccess: (state, payload) => {
            state.accountNo.isLoading = false
            state.accountNo.list = payload.payload.res.list
        },
        getComplainList: (state) => {
            state.complains.isLoading = true
        },
        getComplainListSuccess: (state, payload) => {
            state.complains.isLoading = false
            state.complains.inboundComplain = payload.payload.res.inboundComplain
            state.complains.outboundComplain = payload.payload.res.outboundComplain
        },
        getCallLogAddress: (state) => {
            state.address.isLoading = true
        },
        getCallLogAddressSuccess: (state, payload) => {
            state.address.isLoading = false
            state.address.data = payload.payload.res.data
        },
        setOutboundField: (state, payload) => {
            state.outbound.fields[payload.payload.field] = payload.payload.value
        },
        addOutboundCallLogs: (state) => {
            state.outbound.isLoading = true
        },
        addOutboundCallLogsSuccess: (state, payload) => {
            state.outbound.isLoading = false
            state.response = { ...payload.payload.res }
        },
        getCallLogDetails: (state) => {
            state.details.isLoading = true
        },
        getCallLogDetailsSuccess: (state, payload) => {
            state.details.isLoading = false
            const { inbound, outbound, callcard, entry, id } = payload.payload.res
            const res1 = inbound[0]
            const res2 = outbound[0]
            const res3 = callcard[0]
            let strExplode = null
            switch (entry) {
                case 'inbound':
                    state.details.inbound = payload.payload.res.inbound[0]
                    strExplode = res1.COMPLETEADDRESS.split(',')
                    state.logs.fields = {
                        ...state.logs.fields,
                        ACCOUNTNUMBER: res1.ACCOUNTNUMBER,
                        CUSTOMERNAME: res1.CUSTOMERNAME,
                        DATE: new Date(),
                        TIMESTARTED: moment().format("HH:mm"),
                        TIMEENDED: moment().format("HH:mm"),
                        TYPEOFCALL: entry,
                        CONTACTNO: res1.CONTACTNO,
                        STREET: strExplode[0]?.trim(),
                        PROVINCE: strExplode[3]?.trim().toLowerCase(),
                        MUNICIPALITY: strExplode[2]?.trim(),
                        BARANGAY: strExplode[1]?.trim(),
                        TYPEOFCOMPLAIN: res1.TYPEOFCOMPLAIN,
                        ISSUE: res1.ISSUE,
                        ISSUENAME: res1.ISSUENAME,
                        STATUS: res1.CALLHISTORY.at(-1).STATUS,
                        CHANNEL: res1.CHANNEL,
                        REMARKS: res1.REMARKS,
                        CREATEATICKET: res1.CREATEATICKET,
                        FROMCONVERGE: res1.FROMCONVERGE,
                        DATETIMERECEIVED: res1.DATETIMERECEIVED,
                        initIsTicket: res1.CREATEATICKET,
                        CALLHISTORY: res1.CALLHISTORY,
                        id
                    }
                    break;
                case 'outbound':
                    strExplode = res2.COMPLETEADDRESS.split(',')
                    state.details.outbound = payload.payload.res.outbound[0]
                    state.logs.fields = {
                        ...state.logs.fields,
                        ACCOUNTNUMBER: res2.ACCOUNTNUMBER,
                        CUSTOMERNAME: res2.CUSTOMERNAME,
                        DATE: new Date(),
                        TIMESTARTED: moment().format("HH:mm"),
                        TIMEENDED: moment().format("HH:mm"),
                        TYPEOFCALL: entry,
                        CONTACTNO: res2.CONTACTNO,
                        STREET: strExplode[0]?.trim(),
                        PROVINCE: strExplode[3]?.trim().toLowerCase(),
                        MUNICIPALITY: strExplode[2]?.trim(),
                        BARANGAY: strExplode[1]?.trim(),
                        TYPEOFCOMPLAIN: res2.TYPEOFCOMPLAIN,
                        ISSUE: res2.ISSUE,
                        ISSUENAME: res2.ISSUENAME,
                        STATUS: res2.CALLHISTORY.at(-1).STATUS,
                        CHANNEL: res2.CHANNEL,
                        REMARKS: res2.REMARKS,
                        CREATEATICKET: res2.CREATEATICKET,
                        FROMCONVERGE: res2.FROMCONVERGE,
                        DATETIMERECEIVED: res2.DATETIMERECEIVED,
                        initIsTicket: res2.CREATEATICKET,
                        CALLHISTORY: res2.CALLHISTORY,
                        id
                    }
                    break;
                case 'callcard':
                    state.details.callcard = payload.payload.res.callcard[0]
                    break;
                default:
                    break;
            }

        },
        getCallLogEachDetails: (state) => {
            state.inbound.isLoading = true
            state.outbound.isLoading = true
        },
        getCallLogEachDetailsSuccess: (state, action) => {
            state.inbound.isLoading = false
            state.outbound.isLoading = false
            if (action.payload.res.entry === "inbound") {
                const resFields = action.payload.res.inbound[0]
                const strExplode = resFields.COMPLETEADDRESS.split(',')
                state.inbound.fields.STREET = strExplode[0]?.trim()
                state.inbound.fields.BARANGAY = strExplode[1]?.trim()
                state.inbound.fields.MUNICIPALITY = strExplode[2]?.trim()
                state.inbound.fields.PROVINCE = strExplode[3]?.trim()
                state.inbound.fields = { ...state.inbound.fields, ...resFields, id: resFields._id }
            }
            if (action.payload.res.entry === "outbound") {
                const resFields = action.payload.res.outbound[0]
                state.outbound.fields = { ...state.outbound.fields, ...resFields, id: resFields._id }
            }
        },
        editInboundCallLogs: (state) => {
            state.inbound.isLoading = true
        },
        editInboundCallLogsSuccess: (state, action) => {
            state.inbound.isLoading = false
            state.response = { ...action.payload.res }
        },
        editOutboundCallLogs: (state) => {
            state.outbound.isLoading = true
        },
        editOutboundCallLogsSuccess: (state, action) => {
            state.outbound.isLoading = false
            state.response = { ...action.payload.res }
        },
        setStatusField: (state, action) => {
            state.cxpStatus.fields[action.payload.field] = action.payload.value
        },
        updateCallStatus: (state) => {
            state.cxpStatus.isLoading = true
        },
        updateCallStatusSuccess: (state, action) => {
            state.cxpStatus.isLoading = false
            state.response = { ...action.payload.res }
        },
        getViewAccountDetails: (state) => {

        },
        getViewAccountDetailsSuccess: (state, action) => {
            String.prototype.toCapitalize = function () { return this.toLowerCase().replace(/^.|\s\S/g, function (a) { return a.toUpperCase(); }); }
            // if (state.bound === "inbound") {
            if (action.payload.res.data !== undefined) {
                const {
                    BARANGAYNAME,
                    COMPLETEADDRESS,
                    MUNICIPALITYNAME,
                    SUBDIVISIONNAME,
                    SUBSCRIBERNAME,
                } = action.payload.res.data
                const explode = COMPLETEADDRESS?.toLowerCase()?.split(',')
                let newAddress = null
                if (explode.length > 5) {
                    newAddress = (SUBDIVISIONNAME.trim() + " " + explode.length >= 6 ? `${explode[0]}, ${explode[1]}` : explode[0])
                } else if (explode.length <= 4) {
                    newAddress = SUBDIVISIONNAME.trim()
                }
                // state.inbound.fields.COMPLETENAME = SUBSCRIBERNAME?.toLowerCase()?.toCapitalize()
                // state.inbound.fields.STREET = newAddress?.toCapitalize()
                // state.inbound.fields.BARANGAY = BARANGAYNAME?.toLowerCase()
                // state.inbound.fields.MUNICIPALITY = MUNICIPALITYNAME?.toLowerCase()
                state.logs.fields.CUSTOMERNAME = SUBSCRIBERNAME?.toLowerCase()?.toCapitalize()
                state.logs.fields.STREET = newAddress?.toCapitalize()
                state.logs.fields.BARANGAY = BARANGAYNAME?.toLowerCase()
                state.logs.fields.MUNICIPALITY = MUNICIPALITYNAME?.toLowerCase()
            } else {
                // state.inbound.fields.COMPLETENAME = ''
                // state.inbound.fields.STREET = ''
                // state.inbound.fields.BARANGAY = ''
                // state.inbound.fields.MUNICIPALITY = ''
                state.logs.fields.CUSTOMERNAME = ''
                state.logs.fields.STREET = ''
                state.logs.fields.BARANGAY = ''
                state.logs.fields.MUNICIPALITY = ''
            }
            // } else if (state.bound === "outbound") {
            //     if (action.payload.res.data !== undefined) {
            //         const {
            //             SUBSCRIBERNAME,
            //         } = action.payload.res.data
            //         state.outbound.fields.COMPLETENAME = SUBSCRIBERNAME?.toLowerCase()?.toCapitalize()
            //     } else {
            //         state.outbound.fields.COMPLETENAME = ''
            //     }
            // }
        },
        addCallLogs: (state) => {
            state.logs.isLoading = true
        },
        addCallLogsSuccess: (state, payload) => {
            state.logs.isLoading = false
            state.response = { ...payload.payload.res }
        },
        editCallLogs: (state) => {
            state.logs.isLoading = true
        },
        editCallLogsSuccess: (state, payload) => {
            state.logs.isLoading = false
            state.response = { ...payload.payload.res }
        },
        exportData: (state, payload) => {
            state.isExporting = true
        },
        exportDataSuccess: (state, action) => {
            state.isExporting = false
            state.response = action.payload.res
        },
        setCallLogsFields: (state, action) => {
            state.logs.fields[action.payload.field] = action.payload.value
        },
        fetchMunicipality: (state) => {
            state.address.isLoading = true
        },
        fetchMunicipalitySuccess: (state, action) => {
            state.address.isLoading = false
            state.address.municipality = action.payload.res.data
        }
    }
})

export const {
    setBound,
    setFilter,
    setInboundField,
    setLogsField,
    setDisposeMsg,
    addInboundCallLogs,
    addInboundCallLogsSuccess,
    getAccountNo,
    getAccountNoSuccess,
    getComplainList,
    getComplainListSuccess,
    getCallLogAddress,
    getCallLogAddressSuccess,
    addOutboundCallLogs,
    addOutboundCallLogsSuccess,
    setOutboundField,
    getCallLogDetails,
    getCallLogDetailsSuccess,
    getCallLogEachDetails,
    getCallLogEachDetailsSuccess,
    editInboundCallLogs,
    editInboundCallLogsSuccess,
    editOutboundCallLogs,
    editOutboundCallLogsSuccess,
    setStatusField,
    updateCallStatus,
    updateCallStatusSuccess,
    getViewAccountDetails,
    getViewAccountDetailsSuccess,
    addCallLogs,
    addCallLogsSuccess,
    editCallLogs,
    editCallLogsSuccess,
    exportData,
    exportDataSuccess,
    setCallLogsFields,
    fetchMunicipality,
    fetchMunicipalitySuccess
} = callLogsSlice.actions

export default callLogsSlice.reducer