import { createSlice } from "@reduxjs/toolkit";

const locationCounterSlice = createSlice({
    name: 'locationCounter',
    initialState: {
        filter: {
            location: 'city of calapan',
        },
        data: {
            isLoading: false,
            values: {
                locations: [],
                visits: [],
                closed: []
            },
        }
    },
    reducers: {
        getLocationCounters: (state) => {
            state.data.isLoading = true
        },
        getLocationCountersSuccess: (state, payload) => {
            state.data.isLoading = false
            state.data.values = { ...payload.payload.res }
        },
        setFilter: (state, payload) => {
            state.filter = { ...payload.payload }
        }
    }
})

export const { getLocationCounters, getLocationCountersSuccess, setFilter } = locationCounterSlice.actions
export default locationCounterSlice.reducer