import { createSlice } from "@reduxjs/toolkit";


const totalTicketSlice = createSlice({
    name: 'totalTicket',
    initialState: {
        isLoading: false,
        data: {
            recieved: 0,
            resolved: 0
        }
    },
    reducers: {
        getTotalTicket: (state) => {
            state.isLoading = true
        },
        getTotalTicketSuccess: (state, payload) => {
            state.isLoading = false
            state.data = payload.payload.data
        }
    }
})

export const { getTotalTicket, getTotalTicketSuccess } = totalTicketSlice.actions
export default totalTicketSlice.reducer