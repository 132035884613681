import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

const filterSlice = createSlice({
    name: 'filterSales',
    initialState: {
        params: {
            dateRange: datePresets[2].value
        }
    },
    reducers: {
        setParams: (state, payload) => {
            state.params = payload.payload
        }
    }
})

export const { setParams } = filterSlice.actions
export default filterSlice.reducer