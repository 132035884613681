import { useEffect, useState } from "react";
import { formatDate } from 'src/utils/formatter'
import { startOfDay, endOfDay, subDays } from 'date-fns';

import moment from "moment-timezone";
moment.suppressDeprecationWarnings = true


export const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const handleChangeFilter1 = (param, arr) => {
  let data = []
  switch (param) {
    case "Day":
      arr.map((obj) => (
        data.push(obj.split(' ')[0] + ' ' + obj.split(' ')[1])
      ))
      break;
    case "Month":
      arr.map((obj) => (
        data.push(obj.split(' ')[0] + ' ' + obj.split(' ')[3])
      ))
      break;
    case "Week":
      arr.forEach((obj) => {
        const dt = moment(obj, "MMMM DD dddd YYYY hh:m:ss A");
        data.push('Week ' + dt.week() + ', ' + dt.format('YYYY'))
      })
      break;
    case "Quarter":
      let month = []
      arr.map((obj) => month.push(obj.split(' ')[0] + ' ' + obj.split(' ')[3]))
      let filtMonthByQuart = [...new Set(month)];
      filtMonthByQuart?.filter((obj) => {
        if (obj.includes("January")) { data.push((obj + "-Q1").split(' ')[1]) }
        else if (obj.includes("Febraury")) { data.push((obj + "-Q1").split(' ')[1]) }
        else if (obj.includes("March")) { data.push((obj + "-Q1").split(' ')[1]) }
        else if (obj.includes("April")) { data.push((obj + "-Q2").split(' ')[1]) }
        else if (obj.includes("May")) { data.push((obj + "-Q2").split(' ')[1]) }
        else if (obj.includes("June")) { data.push((obj + "-Q2").split(' ')[1]) }
        else if (obj.includes("July")) { data.push((obj + "-Q3").split(' ')[1]) }
        else if (obj.includes("August")) { data.push((obj + "-Q3").split(' ')[1]) }
        else if (obj.includes("September")) { data.push((obj + "-Q3").split(' ')[1]) }
        else if (obj.includes("October")) { data.push((obj + "-Q4").split(' ')[1]) }
        else if (obj.includes("November")) { data.push((obj + "-Q4").split(' ')[1]) }
        else if (obj.includes("December")) { data.push((obj + "-Q4").split(' ')[1]) }
      });
      break;
    case "Annual":
      arr.map((obj) => (
        data.push(obj.split(' ')[3])
      ))
      break;
    default:
      break;
  }
  return [...new Set(data)] || []
}

export const getDaysArray = (s, e) => { for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) { const temp = new Date(d); a.push(moment(temp, "MM/DD/YYYY").format("MMMM DD dddd YYYY hh:m:ss A")); } return a; };

export const dateRangeParam = (e) => {
  const d = e.split('-')
  const start = moment(d[0], "MM/DD/YYYY").format("YYYY/MM/DD")
  const end = moment(d[1], "MM/DD/YYYY").format("YYYY/MM/DD")
  return getDaysArray(start, end)
}

export const getDaysbyDates = (dates) => {
  const start = moment(dates[0], "MMMM DD dddd YYYY hh:m:ss A").format("YYYY/MM/DD")
  const end = moment(dates[1], "MMMM DD dddd YYYY hh:m:ss A").format("YYYY/MM/DD")
  return getDaysArray(start, end)
}

export const GetWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

/**
* It takes two dates, formats them, and returns a string that represents the date range
* @param dt1 - the start date
* @param dt2 - the end date of the event
* @returns A string that is formatted based on the date range.
*/
export const dateSetFormatter = (dt1, dt2) => {
  let dt = ""
  const s = new Date(moment(formatDate(dt1), "MM-DD-YYYY").format("YYYY-MM-DD")).getTime()
  const e = new Date(moment(formatDate(dt2), "MM-DD-YYYY").format("YYYY-MM-DD")).getTime()
  if (s === e) {
    dt = moment(dt1, "MM-DD-YYYY").format("MMMM DD, YYYY")
  } else {
    const s = formatDate(dt1).split("-")
    const e = formatDate(dt2).split("-")
    if (s[2] === e[2] && s[1] !== e[1]) { dt = moment(formatDate(dt1), "MM-DD-YYYY").format("MMMM DD") + " - " + moment(formatDate(dt2), "MM-DD-YYYY").format("MMMM DD, YYYY") }
    if (s[0] === e[0]) { dt = moment(formatDate(dt1), "MM-DD-YYYY").format("MMMM DD") + " - " + moment(formatDate(dt2), "MM-DD-YYYY").format("DD, YYYY") }
    if (s[2] !== e[2]) { dt = moment(formatDate(dt1), "MM-DD-YYYY").format("MMM DD, YYYY") + " - " + moment(formatDate(dt2), "MM-DD-YYYY").format("MMM DD, YYYY") }
  }
  return dt
}

/**
 * It takes in an array of objects, an array of values, an array of dates, a type of filter, and a
 * boolean value. It then returns an array of objects with the dates as labels and the values as y
 * @param data - the data from the database
 * @param values - the values to be plotted on the chart
 * @param dates - an array of dates
 * @param types - the type of filter you want to use.
 * @param load - boolean,
 * @returns - A function that takes in data, values, dates, types, and load
 *     - The function returns an array of objects
 *     - The array of objects contains a label and y property
 *     - The label property is the date
 *     - The y property is an array of values
 *     - The array of values is empty if load is true
 *     - The array
 */
export const alignDataWithDates = (data, values, dates, types, load) => {
  let newData = new Array(dates.length)
  newData.fill(0)
  let dt = []
  switch (types.toLowerCase()) {
    case "day":
      data.forEach((obj, index) => {
        let pos = dates.indexOf(moment([obj.year, obj.month, obj.day].join('-')).format("MMMM DD"))
        if (pos > -1) {
          newData[pos] = values[index]
        }
      })
      break;
    case "week":
      data.forEach((obj, index) => {
        let pos = dates.indexOf('Week ' + obj.week + ", " + obj.year)
        if (pos > -1) {
          newData[pos] = values[index]
        }
      }
      )
      break;
    case "month":
      data.forEach((obj, idx) => {
        let pos = dates.indexOf(moment([obj.year, obj.month].join('-')).format("MMMM YYYY"))
        if (pos > -1) {
          newData[pos] = values[idx]
        }
      })
      break;
    case "quarter":
      data.forEach((obj, index) => {
        let pos = dates.indexOf(obj.year + '-Q' + obj.quarter)
        if (pos > -1) {
          newData[pos] = values[index]
        }
      })
      break;
    case "annual":
      data.forEach((obj, index) => {
        let pos = dates.indexOf(obj.year.toString())
        if (pos > -1) {
          newData[pos] = values[index]
        }
      })
      break;
    default:
      console.error('filter type not found')
      break;
  }

  dates.map((item, idx) => (
    dt.push({ label: item, y: load ? [] : newData[idx] })
  ))
  return dt
}

/* An array of objects that are used to populate the date presets dropdown. */
export const datePresets = [
  {
    label: 'Today',
    value: [startOfDay(new Date()), endOfDay(new Date())]
  },
  {
    label: 'Last 7 Days',
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
  },
  {
    label: 'This Month',
    value: [moment().startOf('month')["_d"], startOfDay(new Date()),]
  },
  {
    label: 'This Year',
    value: [startOfDay(new Date(new Date().getFullYear(), 0, 1)), endOfDay(new Date())]
  },

]


export const alignDataWithDatesFilter = (datadates, data, dates, filter) => {
  let newData = new Array(dates.length)
  newData.fill(0)
  switch (filter.toLowerCase()) {
    case "day":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(moment([obj.year, obj.month, obj.day].join('-')).format("MMMM DD"))
        if (pos > -1) {
          newData[pos] = data[index]
        }
      })
      break;
    case "week":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf('Week ' + obj.week + ", " + obj.year)
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    case "month":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(moment([obj.year, obj.month].join('-')).format("MMMM YYYY"))
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    case "quarter":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(obj.year + '-Q' + obj.quarter)
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    case "annual":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(obj.year.toString())
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    default:
      console.error(' filter type not found')
      break;
  }
  return newData
}

export const getGraphLabels = (dateRange, filter) => handleChangeFilter1(filter, getDaysbyDates(dateRange))


export const dateFormatter = (date, format, fromFormat = "MMMM DD dddd YYYY hh:m:ss A") => {
  return moment(date, "MMMM DD dddd YYYY hh:m:ss A").format(format)
}

export const portLegends = [
  { title: 'Utilized', value: 0, percent: 0, color: 'success' },
  { title: 'Deployed', value: 0, percent: 0, color: 'info' },
  { title: 'Target', value: 0, percent: 0, color: 'danger' },
]

export const formatDateTime = (format = 'MM/D/YYYY', dateString, offset = false, offsetval = 0) => {
  const parsed = moment(new Date(dateString))
  if (!parsed.isValid()) {
    return dateString
  }

  if (!offset)
    return parsed.format(format)
  else
    return parsed.utcOffset(offsetval).format(format)
}


/**
 * It takes a string parameter, and returns a function that takes two objects, and returns a number
 * @param property - The property to sort by.
 * @returns A function that takes two arguments, a and b, and returns a number.
 */
export const dynamicSort = (property) => {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}


/**
 * It takes a number and returns a string with the number rounded to the nearest thousand, million,
 * billion, etc
 * @param num - The number to be formatted
 * @param digits - The number of digits to round to.
 * @returns A function that takes two arguments, num and digits.
 *  nFormatter(0, 1) = 0
    nFormatter(12, 1) = 12
    nFormatter(1234, 1) = 1.2k
    nFormatter(100000000, 1) = 100M
    nFormatter(299792458, 1) = 299.8M
    nFormatter(759878, 1) = 759.9k
    nFormatter(759878, 0) = 760k
    nFormatter(123, 1) = 123
    nFormatter(123.456, 1) = 123.5
    nFormatter(123.456, 2) = 123.46
    nFormatter(123.456, 4) = 123.456
 */
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

