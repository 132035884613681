import { createSlice } from '@reduxjs/toolkit'

const recentActivityListSlice = createSlice({
  name: 'recentActivityList',
  initialState: {
    isLoading: false,
    data: [
      // {
      //   message: 'No Data',
      //   timestamp: new Date()
      // }
    ],
  },
  reducers: {
    getRecentActivityList(state) {
      // set isLoading to true
      state.isLoading = true;
    },
    getRecentActivityListSuccess(state, payload) {
      // set isLoading to false
      state.isLoading = false;
      // set data
      state.data = payload.payload.activities;
    },
  }
})

export const { getRecentActivityList, getRecentActivityListSuccess } = recentActivityListSlice.actions
export default recentActivityListSlice.reducer