
import { call, put, select, takeLatest } from "redux-saga/effects";
import { formatDate } from "src/utils/formatter";
import request from "src/utils/request";
import { getResolvedSLASuccess } from "../reducer/ticketSummaryGraphReducer";
import { getTotalJOSuccess } from '../reducer/totalJOReducer'
import { getTotalTicketSuccess } from "../reducer/totalTicketReducer";
import { getSalesSummarySuccess } from "../reducer/salesSummaryGraphReducer";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;
function* fetchTicketSummaryGraph() {
    const { dateRange, time, filtertype } = yield select(state=>state.filterSummaryReducer.params)
    const resolved = yield call(request, `${API_URL}/get-resolved-classed?filtertype=${filtertype}&startdate=${formatDate(dateRange[0])}&enddate=${formatDate(dateRange[1])}&sla=${time}`)
    let date = [];
    let value = [];
    let total = [];
    resolved.forEach((el)=>{
      date.push(el._id)
      value.push(el.classes[0]?.count || 0)
      total.push(el)
    })

    yield put(getResolvedSLASuccess({
        data: {
            dates: date,
            values: value,
            total: total
        }
    })) 
}

function* fetchJODoughnutGraph() {
    /* Destructuring the state.totalJOReducer.params object. */
    const { dateRange } = yield select((state) => state.filterSummaryReducer.params);
    const jo = yield call(request, API_URL + '/get-total-jo-created-and-returned?start_date=' + formatDate(dateRange[0]) + '&end_date=' + formatDate(dateRange[1]));
    
    yield put(getTotalJOSuccess({
      data: {
        total_jo_created: jo.jo_created || 0,
        jo_returned: jo.jo_returned || 0,
      },
    }));
    
}
function* fetchSalesSummaryGraph() {
  /* Destructuring the state.filterSummaryReducer.params object. */
  const { dateRange, time, filtertype } = yield select(state=>state.filterSummaryReducer.params)
  const sla = yield call(request, `${API_URL}/get-total-number-of-sla?filter_type=${filtertype}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&sla_type=${time}`);
  let date = [];
  let value = [];
  let total = [];
  let arr = [];
  if (time == 1) {
    arr = sla.result.within24;
  } else if (time == 2) {
    arr = sla.result.within48;
  } else if (time == 3) {
    arr = sla.result.beyond48
  }
  if (arr.length) {
    arr.forEach((element) => {
      date.push(element._id);
      value.push(element.count);
      total.push(element.totalCount);
    });
  }
  yield put(getSalesSummarySuccess({
    data: {
      dates: date,
      values: value,
      total: total
    }
  }));
}

function* fetchTicketNoData() {
  const { dateRange } = yield select((state)=>state.filterSummaryReducer.params)
  const s = moment(formatDate(dateRange[0]),'MM-DD-YYYY').format('YYYY-MM-DD')
  const e = moment(formatDate(dateRange[1]),'MM-DD-YYYY').format('YYYY-MM-DD')  
  const recieved = yield call (request, `${API_URL}/numberOfTicketsRecieved?startdate=${s}&enddate=${e}`)
  const resolved = yield call (request, `${API_URL}/numberOfTicketsResolved?startdate=${s}&enddate=${e}`)
  yield put(getTotalTicketSuccess({
    data: {
      recieved : recieved[0]?.count,
      resolved : resolved.num_of_ticks_res[0]?.count
    }
  }))
}




function* summaryGraphSaga() {
    yield takeLatest('totalTicket/getTotalTicket', fetchTicketNoData)
    yield takeLatest('ticketSummaryGraph/getResolvedSLA', fetchTicketSummaryGraph)
    yield takeLatest('totalJO/getTotalJO', fetchJODoughnutGraph)
    yield takeLatest('salesSummaryGraph/getSalesSummary', fetchSalesSummaryGraph);
}

export default summaryGraphSaga