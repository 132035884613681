import { createSlice } from "@reduxjs/toolkit"
import { datePresets } from "src/utils/helper"

const initialState = {
    filter: {
        type: '',
        dateRange: datePresets[2].value,
        search: '',
        fiber: 'fiberx'
    },
    list: {
        isLoading: false,
        data: [],
        response: {
            message: '',
            status: ''
        }
    },
    graph: {
        status: {
            isLoading: false,
            data: [],
            value: []
        }
    }
}

const modelSlice = createSlice({
    name: 'modelDev',
    initialState,
    reducers: {
        getListModel: (state) => {
            state.list.isLoading = true
        },
        getListModelSuccess: (state, payload) => {
            state.list.isLoading = false
            state.list.data = payload.payload.res.data
        },
        updateVerifyStatusOmd: (state) => {
            state.list.isLoading = true
        },
        updateVerifyStatusOmdSuccess: (state, payload) => {
            state.list.isLoading = false
            state.list.response = { ...payload.payload.res }
        },
        setDisposeVerify: (state, payload) => {
            state.list.response = { ...payload.payload }
        },
        setFilter: (state, payload) => {
            state.filter = payload.payload
        },
        getStatusCardGraph: (state) => {
            state.graph.status.isLoading = true
        },
        getStatusCardGraphSuccess: (state, payload) => {
            state.graph.status.isLoading = false

            state.graph.status.data = payload.payload.data
            state.graph.status.value = payload.payload.value
        },
    }

})

export const {
    getListModel,
    getListModelSuccess,
    updateVerifyStatusOmd,
    updateVerifyStatusOmdSuccess,
    setDisposeVerify,
    setFilter,
    getStatusCardGraph,
    getStatusCardGraphSuccess
} = modelSlice.actions
export default modelSlice.reducer 