import  { createSlice }  from "@reduxjs/toolkit";

const initialState = {
    article: {
        isLoading: false,
        field: {
            title: '',
            category: '',
            content: ''
        },
        list: []
    
    },

    category: {
        isLoading: false,
        field: {
            name: '',
            desc: '',
            logo: {}
        },
        list: []   
    },
    fileUpload: {
        uploading: false,
        file: [],
        data: {
            isLoading: false,
            list: []
        }
    },
    response: {
        message: '',
        status: ''
    }
}

const cmsSlice = createSlice({
    name: 'cms',
    initialState,
    reducers: {
        setField: (state, action) => {
            const target = action.payload.target
            if(target.trim().length === 0)return;
            switch (target) {
                case 'article':
                    state.article.field[action.payload.field] = action.payload.value
                    break;
                case 'category':
                    state.category.field[action.payload.field] = action.payload.value
                    break;
                case 'notif':
                    state.response[action.payload.field] = action.payload.value
                    break;
                default:
                    console.log("Something went wrong! Check slices state on cms")
                break;
            }
        },
        manageCategory: (state) => {
            state.category.isLoading = true
        },
        manageCategorySuccess: (state, action) => {
            state.category.isLoading = false
            state.response = { ...action.payload.res }
        },
        makeArticle : (state) => {
            state.article.isLoading = true
        },
        makeArticleSuccess: (state, action)=>{
            state.article.isLoading = false
            state.response = { ...action.payload.res  }
        },
        getCategory: (state) => {
            state.category.isLoading = true
        },
        getCategorySuccess: (state, action) => {
            state.category.isLoading = false
            const { req, list } = action.payload.res
            if(req==="all"){
                state.category.list = action.payload.res
            }else if(req==="details"){
                state.category.field.name = list[0].NAME
                state.category.field.desc = list[0].DESCRIPTION
                state.category.field.logo = list[0].LOGO
            }
        },
        getArticle: (state) => {
            state.article.isLoading = true
        },
        getArticleSuccess: (state, action) => {
            state.article.isLoading = false
            const { req, list } = action.payload.res
            if(req==="all"){
                state.article.list = list
            }else if(req === "each"){
                state.article.field.title = list[0].TITLE
                state.article.field.category = list[0].CATEGORYID
                state.article.field.content = list[0].BODY
            }

        },
        fileUpload: (state) => {
            state.fileUpload.uploading = true
        },
        fileUploadSuccess: (state, action) => {
            const { res, target } = action.payload
            switch (target) {
                case 'banner':
                    state.fileUpload.uploading = false
                    state.response = { ...res }
                    break;
                default:
                    break;
            }
        },
        getFileUpload: (state) => {
            state.fileUpload.data.isLoading = true
        },
        getFileUploadSuccess: (state, { payload }) => {
            state.fileUpload.data.isLoading = false
            state.fileUpload.data.list = payload.res.list
        },
        removeArticle: (state) => {
            state.article.isLoading = true
        },
        removeArticleSuccess: (state, action) => {
            state.article.isLoading = false
            state.response = { ...action.payload.res  }
        }
    }
});

export const {
    setField, 
    makeArticle, 
    makeArticleSuccess, 
    manageCategory, 
    manageCategorySuccess, 
    getCategory, 
    getCategorySuccess,
    getArticle,
    getArticleSuccess,
    fileUpload,
    fileUploadSuccess,
    getFileUpload,
    getFileUploadSuccess,
    removeArticle,
    removeArticleSuccess
} = cmsSlice.actions
export default  cmsSlice.reducer