import { createSlice } from "@reduxjs/toolkit";

const portLocationSlice = createSlice({
    name: 'location',
    initialState: {
        isLoading: false,
        municipality: []
    },
    reducers: {
        getLocations: (state) => {
            state.isLoading = true
        },
        getLocationsSuccess: (state, payload) => {
            state.isLoading = false
            state.municipality = payload.payload
        }
    }
})

export const { getLocations, getLocationsSuccess } = portLocationSlice.actions
export default portLocationSlice.reducer