import { createSlice } from "@reduxjs/toolkit";

const updateTurnInSlice = createSlice({
    name: 'upTurnInListBida',
    initialState: {
        status: {
            isLoading: false,
            value: null,
            id: null,
            response: {
                message: '',
                status: ''
            }
        }
    },
    reducers: {
        updateTurnInStatus: (state) => {
            state.status.isLoading = true
        },
        updateTurnInStatusSuccess: (state, payload) => {
            state.status.isLoading = false
            state.status.value = payload.payload.res.status
            const { message, status, name } = payload.payload.res.response
            state.status.response = { ...state.status.response, message: `${message} ${name}`, status: status }
        },
        setStatusResDispose: (state, payload) => {
            state.status.response = payload.payload
        }
    }
})

export const {
    updateTurnInStatus,
    updateTurnInStatusSuccess,
    setStatusResDispose
} = updateTurnInSlice.actions
export default updateTurnInSlice.reducer