import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "../helper";

const filterTicketSlice = createSlice({
    name: 'filterTicket',
    initialState: {
        isLoading: false,
        params: {
            dateRange: datePresets[2].value,
            filtertype: 'Day'
        },
        data: {
            recieved: {
                dates: [],
                values: [],
                total: 0
            },
            resolved: {
                dates: [],
                values: [],
                total: 0
            },
            target: 0
        }

    },
    reducers: {
        getFilterTicketSummary: (state) => {
            state.isLoading = true
        },
        getFilterTicketSummarySuccess: (state, payload) => {
            state.isLoading = false
            state.data = { ...state.data, ...payload.payload }
        },
        setParams: (state, payload) => {
            state.params = payload.payload
        }
    }
})

export const { setParams, getFilterTicketSummary, getFilterTicketSummarySuccess } = filterTicketSlice.actions
export default filterTicketSlice.reducer