import { createSlice } from "@reduxjs/toolkit";

const salesSummarySlice = createSlice({
    name: 'salesSummaryGraph',
    initialState: {
        isLoading: false,
        data: {
            dates:[],
            values:[],
            total:[],
        },
    },
    reducers: {
        getSalesSummary: (state) => {
            state.isLoading = true;
        },
        getSalesSummarySuccess: (state, payload) => {
            state.isLoading = false;
            state.data = {...state.data, ...payload.payload.data}
        }
    }
})

export const { getSalesSummary, getSalesSummarySuccess } = salesSummarySlice.actions
export default salesSummarySlice.reducer

