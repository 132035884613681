import { createSlice } from "@reduxjs/toolkit";

const portDataPerMunSlice = createSlice({
    name: 'portDataPerMun',
    initialState: {
        isLoading: false,
        filter: {
            location: 'city of calapan'
        },
        data: {
            utilized: [],
            deployed: [],
            targets: []
        },
    },
    reducers: {
        getPortDataPerMun: (state) => {
            state.isLoading = true
        },
        getPortDataPerMunSuccess: (state, payload) => {
            state.isLoading = false
        },
        setFilter: (state, payload) => {
            state.filter = payload.payload
        }
    }
})

export const { getPortDataPerMun, getPortDataPerMunSuccess, setFilter } = portDataPerMunSlice.actions
export default portDataPerMunSlice.reducer