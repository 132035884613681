import { takeEvery, call, put, takeLatest, all, select } from 'redux-saga/effects'
import request from '../../../utils/request'
import { getCountersSuccess } from '../reducer/counterReducer'
import { getSalesSummarySuccess } from '../reducer/salesSummaryReducer'
import { getRecentActivityListSuccess } from '../reducer/recentActivityListReducer'
import { getNetPromoterScoreSuccess } from '../reducer/netPromoterScoreReducer'
import { getTicketsSummarySuccess } from '../reducer/ticketSummaryReducer'
import { formatDate } from '../../../utils/formatter'
import { getFilterSalesSummarySuccess } from '../reducer/filterSalesReducer'
import { getFilterTicketSummarySuccess } from '../reducer/filterTicketReducer'

const API_URL = process.env.REACT_APP_API_URL;

// fetch averaage revenue per line
function* fetch1() {
  const { dateRange } = yield select((state) => state.filterReducer.params)
  const ave_rev_per_line = yield call(request, `${API_URL}/getAveRevPerLine?startdate=${formatDate(dateRange[0])}&enddate=${formatDate(dateRange[1])}`);
  yield put(getCountersSuccess({
    ave_rev_per_line,
  }));
}
// fetch total subscribers
function* fetch2() {
  const { dateRange } = yield select((state) => state.filterReducer.params)
  const total_subscribers = yield call(request, `${API_URL}/get-total-subscribers?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`);
  yield put(getCountersSuccess({
    total_subscribers,
  }));
}
// fetch installed ratio vs turnin
function* fetch3() {
  const { dateRange } = yield select((state) => state.filterReducer.params)
  const installed_ratio_vs_turnin = yield call(request, `${API_URL}/installed-ratio-vs-turn-ins?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`);
  yield put(getCountersSuccess({
    installed_ratio_vs_turnin,
  }));
}
// fetch averange time from turnin to installation
function* fetch4() {
  const { dateRange } = yield select((state) => state.filterReducer.params)
  const ave_time_turnin_to_installation = yield call(request, `${API_URL}/getAveTurnin?startdate=${formatDate(dateRange[0])}&enddate=${formatDate(dateRange[1])}`);
  yield put(getCountersSuccess({
    ave_time_turnin_to_installation,
  }));
}
// fetch average resolution time
function* fetch5() {
  const { dateRange } = yield select((state) => state.filterReducer.params)
  const ave_res_time = yield call(request, `${API_URL}/getAveResTime?startdate=${formatDate(dateRange[0])}&enddate=${formatDate(dateRange[1])}`);
  yield put(getCountersSuccess({
    ave_res_time,
  }));
}

// fetch counters
function* fetchCounters() {
  yield all([
    call(fetch1),
    call(fetch2),
    call(fetch3),
    call(fetch4),
    call(fetch5),
  ])
}

// fetch turnins
function* fetchTurnins() {
  const { filtertype, dateRange } = yield select((state) => state.filterReducer.params)
  const turnins = yield call(request, API_URL + '/number-of-turn-in-accounts?filter_type=' + filtertype + '&start_date=' + formatDate(dateRange[0]) + '&end_date=' + formatDate(dateRange[1]));
  let dates1 = [];
  let values1 = [];
  turnins.result.forEach(cur => {
    dates1.push(cur._id);
    values1.push(cur.count);
  })
  yield put(getSalesSummarySuccess({
    turnins: {
      dates: dates1,
      values: values1,
      total: turnins.totalCount
    },
    target: turnins.targets,
    dateRange
  }));
}
// fetch installed
function* fetchInstalled() {
  const { filtertype, dateRange } = yield select((state) => state.filterReducer.params)
  const installed = yield call(request, API_URL + '/number-of-installed-accounts?filter_type=' + filtertype + '&start_date=' + formatDate(dateRange[0]) + '&end_date=' + formatDate(dateRange[1]));
  let dates2 = [];
  let values2 = [];
  installed.result.forEach(cur => {
    dates2.push(cur._id);
    values2.push(cur.count);
  })
  yield put(getSalesSummarySuccess({
    installed: {
      dates: dates2,
      values: values2,
      total: installed.totalCount
    },
    dateRange
  }));
}
// fetch sales summary
function* fetchSalesSummary() {
  yield call(fetchTurnins)
  yield call(fetchInstalled)
}


function* fetchTurnins1() {
  const { filtertype, dateRange } = yield select((state) => state.filterSalesReducer.params)
  const turnins = yield call(request, API_URL + '/number-of-turn-in-accounts?filter_type=' + filtertype + '&start_date=' + formatDate(dateRange[0]) + '&end_date=' + formatDate(dateRange[1]));
  let dates1 = [];
  let values1 = [];

  turnins.result.forEach(cur => {
    dates1.push(cur._id);
    values1.push(cur.count);
  })
  yield put(getFilterSalesSummarySuccess({
    turnins: {
      dates: dates1,
      values: values1,
      total: turnins.totalCount
    },
    target: turnins.targets,
    dateRange
  }))
}

function* fetchInstalled1() {
  const { filtertype, dateRange } = yield select((state) => state.filterSalesReducer.params)
  const installed = yield call(request, API_URL + '/number-of-installed-accounts?filter_type=' + filtertype + '&start_date=' + formatDate(dateRange[0]) + '&end_date=' + formatDate(dateRange[1]));
  let dates2 = [];
  let values2 = [];
  installed.result.forEach(cur => {
    dates2.push(cur._id);
    values2.push(cur.count);
  })
  yield put(getFilterSalesSummarySuccess({
    installed: {
      dates: dates2,
      values: values2,
      total: installed.totalCount
    },
    dateRange
  }));
}

function* fetchSalesSummary1() {
  const { filtertype, dateRange } = yield select((state) => state.filterSalesReducer.params)
  yield call(fetchInstalled1, filtertype, dateRange)
  yield call(fetchTurnins1, filtertype, dateRange)
}



// fetch recieve tickets
function* fetchRecieved() {
  const { filtertype, dateRange } = yield select((state) => state.filterReducer.params)
  const recieved = yield call(request, API_URL + '/numberOfTicketsRecieved?filtertype=' + filtertype + '&start_date=' + formatDate(dateRange[0]) + '&end_date=' + formatDate(dateRange[1]));
  let dates1 = [];
  let values1 = [];
  recieved.forEach(cur => {
    dates1.push(cur._id);
    values1.push(cur.count);
  })
  yield put(getTicketsSummarySuccess({
    recieved: {
      dates: dates1,
      values: values1,
    }
  }));
}
// fetch resolved tickets
function* fetchResolved() {
  const { filtertype, dateRange } = yield select((state) => state.filterReducer.params)
  const resolved = yield call(request, API_URL + '/numberOfTicketsResolved?filtertype=' + filtertype + '&startdate=' + formatDate(dateRange[0]) + '&enddate=' + formatDate(dateRange[1]));
  let dates2 = [];
  let values2 = [];
  resolved.num_of_ticks_res.forEach(cur => {
    dates2.push(cur._id);
    values2.push(cur.count);
  })
  yield put(getTicketsSummarySuccess({
    resolved: {
      dates: dates2,
      values: values2,
      // total:installed.totalCount
    },
    target: resolved.targets
  }));
}
function* fetchTicketsSummary() {
  const { filtertype, dateRange } = yield select((state) => state.ticketSummaryReducer.params)
  yield call(fetchRecieved, filtertype, dateRange)
  yield call(fetchResolved, filtertype, dateRange)
}

function* fetchRecieved1() {
  const { filtertype, dateRange } = yield select((state) => state.filterTicketReducer.params)
  const recieved = yield call(request, API_URL + '/numberOfTicketsRecieved?filtertype=' + filtertype + '&start_date=' + formatDate(dateRange[0]) + '&end_date=' + formatDate(dateRange[1]));
  let dates1 = [];
  let values1 = [];
  recieved.forEach(cur => {
    dates1.push(cur._id);
    values1.push(cur.count);
  })

  yield put(getFilterTicketSummarySuccess({
    recieved: {
      dates: dates1,
      values: values1,
    }
  }))
}

function* fetchResolved1() {
  const { filtertype, dateRange } = yield select((state) => state.filterTicketReducer.params)
  const resolved = yield call(request, API_URL + '/numberOfTicketsResolved?filtertype=' + filtertype + '&startdate=' + formatDate(dateRange[0]) + '&enddate=' + formatDate(dateRange[1]));
  let dates2 = [];
  let values2 = [];
  resolved.num_of_ticks_res.forEach(cur => {
    dates2.push(cur._id);
    values2.push(cur.count);
  })
  yield put(getFilterTicketSummarySuccess({
    resolved: {
      dates: dates2,
      values: values2,
      // total:installed.totalCount
    },
    target: resolved.targets
  }));
}


function* fetchTicketsSummary1() {
  const { filtertype, dateRange } = yield select((state) => state.ticketSummaryReducer.params)
  yield call(fetchRecieved1, filtertype, dateRange)
  yield call(fetchResolved1, filtertype, dateRange)
}


function* fetchRecentActivityList() {
  // call endpoint for recent activity list
  const activities = yield call(request, API_URL + '/get-recent-activity-list');
  yield put(getRecentActivityListSuccess({
    activities
  }));
}
function* fetchNetPromoterScore() {
  // call endpoint for recent activity list
  const scores = yield call(request, API_URL + '/net-promoter-score');

  let mgm = 0, erp = 0, others = 0;

  scores.result.forEach((cur) => {
    if (cur.ref_code_type.toLowerCase().indexOf('mgm') >= 0)
      mgm = cur.count;
    else if (cur.ref_code_type.toLowerCase().indexOf('erp') >= 0)
      erp = cur.count;
    else if (cur.ref_code_type.toLowerCase().indexOf('others') >= 0)
      others = cur.count;
  });

  yield put(getNetPromoterScoreSuccess({
    mgm: mgm,
    erp: erp,
    others: others,
  }));
}





// watcher saga: will listen for actions and call worker saga
// takeEvery({reducer name}/function, worker saga, args for worker saga)
function* dashboardSaga() {
  yield takeLatest('filters/setParams', fetchCounters)
  yield takeLatest('counters/getCounters', fetchCounters)
  yield takeLatest('salesSummary/getSalesSummary', fetchSalesSummary)
  yield takeEvery('recentActivityList/getRecentActivityList', fetchRecentActivityList)
  yield takeEvery('netPromoterScore/getNetPromoterScore', fetchNetPromoterScore)
  yield takeLatest('ticketSummary/getTicketsSummary', fetchTicketsSummary)
  yield takeEvery('filterSales/getFilterSalesSummary', fetchSalesSummary1)
  yield takeEvery('filterTicket/getFilterTicketSummary', fetchTicketsSummary1)

}

export default dashboardSaga
