import LS from './LocalStorage'
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300 || response.status === 400 || response.status === 500) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export default function request(url, options, isDownload = false) {
  const token = LS.readLocalStorage('token')
  if (options?.headers) {
    options.headers['x-access-token'] = token
  } else {
    options = { ...options, headers: { 'x-access-token': token } }
  }
  if (isDownload) {
    return fetch(url, options)
      .then(response => checkStatus(response))
      .then(res => {
        if (res.headers.get("content-type").search('application/json') !== -1)
          return parseJSON(res)
        return res.blob()
      });
  }
  return fetch(url, options)
    .then(response => checkStatus(response))
    .then(response => parseJSON(response))
    .catch((error) => {
      if (error.response.status === 403) {
        window.location = '#/403'
      }

      return false
    });
}
