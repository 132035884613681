import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getHandleErrorVerify } from "src/container/DashboardPage/reducer/authHandlerReducer";
import { RequestOptions } from "src/helpers/RequestOptions";
import { formatDate } from "src/utils/formatter";
import request from "src/utils/request";
import { exportAgentCounterClientsSuccess, getAgentCounterClientsSuccess, getCardPlanTypesSuccess, getCardTotalCountersSuccess, getPositionsSuccess, getStatusCardGraphSuccess, updateCardTotalCountersSuccess } from "../reducer/cardGraphCountersReducer";
import { getLocationCountersSuccess } from "../reducer/locationGraphCountersReducer";
import { getEmployeesTurnListSuccess, getTurnInAddressSuccess, getTurnInDetailsSuccess, getTurnInListSuccess, updateEachTurnInListSuccess, updateTurnInListSuccess, exportTrackerSuccess, updateVerifyStatusSuccess, setField, fetchMunicipalitySuccess } from "../reducer/TurnListReducer";
import { updateTurnInStatusSuccess } from "../reducer/updateTurnInListReducer";

const API_URL = process.env.REACT_APP_API_URL;

function* fetchTurnInList() {
    try {
        const { search, agentId, statList, planType } = yield select(state => state.turnInListReducer.params)
        const { dateRange } = yield select((state) => state.cardGraphCountersReducer.filter)
        const stat = statList?.map((el) => `&status[]=${el.value}`).join('')
        const param1 = search === undefined || search === null ? '' : search
        const param2 = agentId === undefined || agentId === null ? '' : agentId
        const param3 = stat === undefined ? '' : stat
        const param4 = planType === undefined || planType === null || planType === 'All' ? '' : planType
        const response = yield call(request, `${API_URL}/sales/callcard?keyword=${param1}&agent_id=${param2}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}${param3}&plan_type=${param4}`)
        const {
            data,
            message,
            status
        } = response
        const res = {
            data,
            message,
            status
        }
        yield put(getTurnInListSuccess({
            res
        }))
    } catch (error) {
        console.log(error.message)
    }

}

function* fetchCurrentDate(payload) {
    const response = yield call(request, `${API_URL}/curdate`)

    yield put(setField({
        field: payload.payload.field,
        value: response.date
    }))
}

function* updateTurnInList(payload) {
    const fields = payload.payload
    const { finder } = yield select(state => state.turnInListReducer.details)
    const { ISFUSS } = finder
    const newFields = {
        ...fields,
        ISFUSS
    }
    let response = {}
    const requestBody = yield call(RequestOptions, 'POST', newFields)
    response = yield call(request, `${API_URL}/sales/callcard`, requestBody)
    const { status, data, message } = response
    yield put(updateTurnInListSuccess({
        res: status === "Error" ? {
            message,
            status
        } : {
            data,
            status,
            message
        }
    }))
}

function* fetchAddressSalesTurnIn(payload) {
    const { location, province } = payload.payload
    const response = yield call(request, `${API_URL}/sales/address?province=${province}&municipality=${location}`)
    yield put(getTurnInAddressSuccess({
        res: {
            data: response.barangays
        }
    }))
}

function* fetchAddressMunicipality(action){
    const { province } = action.payload
    const response = yield call(request, `${API_URL}/sales/address/municipality?province=${province}`)
    const { municipality } = response
    yield put(fetchMunicipalitySuccess({
        res: {
            data: municipality
        }        
    }))
}

function* fetchDetailsSalesTurnIn(payload) {
    try {
        const { id } = payload.payload
        const response = yield call(request, `${API_URL}/sales/callcard/${id}`)
        const { data, message, status } = response
        yield put(getTurnInDetailsSuccess({
            res: {
                result: data,
                response: {
                    message,
                    status
                }
            }
        }))
    } catch (error) {
        console.log(error.message)
    }
}

function* updateStatusSalesTurnIn(payload) {
    const { value, id, name } = payload.payload
    const fields = {
        STATUS: value
    }
    const requestBody = yield call(RequestOptions, 'PATCH', fields)
    const response = yield call(request, `${API_URL}/sales/callcard/${id}/status`, requestBody)

    yield put(updateTurnInStatusSuccess({
        res: {
            status: value,
            response: {
                message: response.message,
                status: response.status,
                name
            }
        }
    }))
}

function* updateEachTurnInList(payload) {
    const fields = payload.payload
    const newFields = {
        ...fields,
    }
    const requestBody = yield call(RequestOptions, 'PUT', newFields)
    const response = yield call(request, `${API_URL}/sales/callcard/${fields.ID}`, requestBody)
    yield put(updateEachTurnInListSuccess({
        res: {
            message: response.message,
            status: response.status
        }
    }))
}

function* fetchEmployeesTurnIn() {
    const response = yield call(request, `${API_URL}/common/employees`)
    yield put(getEmployeesTurnListSuccess({
        res: {
            count: response.count ?? 0,
            list: response.employees ?? []
        }
    }))
}

function* fetchStatusCardGraph() {
    const { dateRange } = yield select((state) => state.cardGraphCountersReducer.filter)
    const response = yield call(request, `${API_URL}/sales/callcard/dashboard/status?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    const data = {
        status: response.status_dashboard || [],
        ispScore: response.isp_count || []
    }

    let dataA = []
    let valuesA = []


    let dataB = []
    let valuesB = []


    data?.status?.forEach((el) => {
        dataA.push(el.status)
        valuesA.push(el.count)
    })

    data?.ispScore?.forEach((el) => {
        dataB.push(el.existing_isp)
        valuesB.push(el.count)
    })

    yield put(getStatusCardGraphSuccess({
        res: {
            status: {
                dataA,
                valuesA
            },
            isp: {
                dataB,
                valuesB
            }
        }
    }))
}

function* fetchSalesCounters() {
    const { dateRange } = yield select((state) => state.cardGraphCountersReducer.filter)
    const response = yield call(request, `${API_URL}/sales/callcard/dashboard/count?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    yield put(getCardTotalCountersSuccess({
        res: {
            response
        }
    }))
}

function* fetchPlanTypeCounters() {
    const { dateRange } = yield select((state) => state.cardGraphCountersReducer.filter)
    const response = yield call(request, `${API_URL}/sales/callcard/dashboard/plan?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    const { labels, values } = response

    let data = []
    let value = []

    labels.forEach((el) => {
        data.push(el.toLowerCase())
    })

    values.forEach((el) => {
        value.push(el)
    })

    yield put(getCardPlanTypesSuccess({
        res: {
            plan: {
                labels: data,
                values: value
            }
        }
    }))
}

function* updateSalesCounterTargets(payload) {
    const {
        aveSalesAgent,
        effectDate,
        succSalesAgent,
        totalSalesAgent
    } = payload.payload
    const fields = {
        TOTALSALESAGENT: totalSalesAgent,
        AVERAGEVISITSPERSALESAGENT: aveSalesAgent,
        AVERAGESUCCESSRATEPERVISITS: succSalesAgent,
        EFFECTIVEDATE: effectDate
    }
    try {
        const requestBody = yield call(RequestOptions, 'POST', fields)
        const response = yield call(request, `${API_URL}/sales/callcard/dashboard/kpi`, requestBody)
        yield put(updateCardTotalCountersSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }

}

function* exportTracker() {
    try {
        const { dateRange } = yield select((state) => state.cardGraphCountersReducer.filter)
        const response = yield call(request, `${API_URL}/sales/turnins/export?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
        yield put(exportTrackerSuccess({
            res: {
                url: `${API_URL}/sales/turnins/download?filename=${response.filename}`,
                status: response.status
            }
        }))
    }
    catch (error) {
        console.log(error)
    }
}

function* fetchAgentCountersClient() {
    const { dateRange, position } = yield select((state) => state.cardGraphCountersReducer.filter)
    const response = yield call(request, `${API_URL}/sales/callcard/dashboard/analysis?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&position=${position}`)
    yield put(getAgentCounterClientsSuccess({
        res: {
            list: response.agents,
            average: {
                visit: response.ave_visit_per_emp,
                closed: response.ave_cd_per_emp
            },
            status: response.status
        }
    }))
}

function* fetchCountVisitsPerLocation(payload) {
    const { filter } = payload.payload
    const { dateRange } = yield select((state) => state.cardGraphCountersReducer.filter)
    try {
        const response = yield call(request, `${API_URL}/sales/callcard/dashboard/location?municipality=${filter}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
        let locations = [],
            visits = [],
            closed = []

        response.forEach((el) => {
            locations.push(el.barangay?.toUpperCase())
            visits.push(el.visits)
            closed.push(el.closed)
        })
        yield put(getLocationCountersSuccess({
            res: {
                locations,
                visits,
                closed

            }
        }))
    } catch (error) {
        console.log(error)
    }
}


function* updateEachVerifyStatus(payload) {
    const { value, id, remarks } = payload.payload
    const fields = {
        ADMIN_STATUS: value,
        ADMIN_REMARKS: remarks === undefined ? '' : remarks
    }
    const requestBody = yield call(RequestOptions, 'PATCH', fields)
    const response = yield call(request, `${API_URL}/sales/callcard/${id}/status`, requestBody)

    yield put(updateVerifyStatusSuccess({
        res: {
            value,
            message: response.message,
            status: response.status
        }
    }))
}


function* exportAgentCountersClient() {
    const { dateRange } = yield select((state) => state.cardGraphCountersReducer.filter)
    const response = yield call(request, `${API_URL}/agents/totalclients/export?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`, {}, true)
    if (response?.status === 'error')
        yield put(exportAgentCounterClientsSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    else {
        var file = window.URL.createObjectURL(response);
        window.location.assign(file);
        yield put(exportAgentCounterClientsSuccess({}))
    }
}

function* getPositions() {
    const response = yield call(request, `${API_URL}/positions?department=Sales and Marketing Department`)

    yield put(getPositionsSuccess({
        res: {
            positions: response.positions
        }
    }))
}



function* cardTrackerSaga() {
    yield takeLatest('turnInList/getTurnInList', fetchTurnInList)
    yield takeEvery('turnInList/setCurDate', fetchCurrentDate)
    yield takeEvery('turnInList/updateTurnInList', updateTurnInList)
    yield takeLatest('turnInList/getTurnInAddress', fetchAddressSalesTurnIn)
    yield takeLatest('turnInList/getTurnInDetails', fetchDetailsSalesTurnIn)
    yield takeLatest('upTurnInList/updateTurnInStatus', updateStatusSalesTurnIn)
    yield takeLatest('turnInList/updateEachTurnInList', updateEachTurnInList)
    yield takeEvery('turnInList/getEmployeesTurnList', fetchEmployeesTurnIn)
    yield takeEvery('cardGraphCounter/getStatusCardGraph', fetchStatusCardGraph)
    yield takeEvery('cardGraphCounter/getCardTotalCounters', fetchSalesCounters)
    yield takeEvery('cardGraphCounter/getCardPlanTypes', fetchPlanTypeCounters)
    yield takeEvery('cardGraphCounter/updateCardTotalCounters', updateSalesCounterTargets)
    yield takeEvery('turnInList/exportTracker', exportTracker)
    yield takeEvery('cardGraphCounter/getAgentCounterClients', fetchAgentCountersClient)
    yield takeEvery('locationCounter/getLocationCounters', fetchCountVisitsPerLocation)
    yield takeEvery('turnInList/updateVerifyStatus', updateEachVerifyStatus)
    yield takeEvery('cardGraphCounter/exportAgentCounterClients', exportAgentCountersClient)
    yield takeEvery('cardGraphCounter/getPositions', getPositions)
    yield takeLatest('turnInList/fetchMunicipality', fetchAddressMunicipality)
}

export default cardTrackerSaga