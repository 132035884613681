import { takeEvery, call, put, select } from 'redux-saga/effects'
import request from 'src/utils/request'
import { RequestOptions, RequestOptionsForm } from 'src/helpers/RequestOptions'
import { getTargetSettingsSuccess, updateTargetSettingsSuccess } from '../reducer/targetSettingsReducer'
import { getHandleError, getHandleErrorVerify } from '../reducer/authHandlerReducer';
import { updatePasswordSuccess } from '../reducer/changePasswordReducer';
import { importCollectionSuccess } from '../reducer/collectionReducer';

const API_URL = process.env.REACT_APP_API_URL;

function* importCollection(data) {
    try {
        let formData = new FormData()
        let response

        if (data.payload.ibas)
        {
            formData.set('IBASFILE', data.payload.ibas[0])
            response = yield call(request, `${API_URL}/upload/csv/ibas`, {
                method: 'POST',
                body: formData
            })
        }
        else if (data.payload.ssp)
        {
            formData.set('SSPFILE', data.payload.ssp[0])
            response = yield call(request, `${API_URL}/upload/csv/ssp`, {
                method: 'POST',
                body: formData
            })
        }
        else if (data.payload.joweb)
        {
            formData.set('JOWEBFILE', data.payload.joweb[0])
            response = yield call(request, `${API_URL}/upload/csv/joweb`, {
                method: 'POST',
                body: formData
            })
        }

        if (response) {
            yield put(importCollectionSuccess({
                res: {
                    message: response.message,
                    status: response.status
                }
            }))
        }
    } catch (error) {
        yield put(getHandleErrorVerify({
            message: error.response.message
        }))
    }
}

// watcher saga: will listen for actions and call worker saga
// takeEvery({reducer name}/function, worker saga, args for worker saga)
function* collectionSaga() {
  yield takeEvery('collection/importCollection', importCollection)
}

export default collectionSaga
