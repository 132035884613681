import { createSlice } from "@reduxjs/toolkit";

const employeeSlice = createSlice({
    name: 'employee',
    initialState: {
        data: {
            isLoading: false,
            filter: {
                value: null
            },
            list: []
        },
        details: {
            isLoading: false,
            data: {
                id: '',
                empname: '',
                department: '',
                designation: '',
                endDate: '',
                joinDate: '',
                isFreelance: true,
                remarks: '',
            },
            response: {
                message: '',
                status: ''
            }
        },
        update: {
            sync: {
                isLoading: false
            }
        },
        role: {
            isLoading: false,
            list: []
        },
        company: {
            isLoading: false,
            list: []
        }
    },
    reducers: {
        getEmployeeList: (state) => {
            state.data.isLoading = true
        },
        getEmployeeListSuccess: (state, payload) => {
            state.data.isLoading = false
            state.data.list = { ...payload.payload.response }
        },
        addEmployeeList: (state) => {
            state.details.isLoading = true
        },
        addEmployeeListSuccess: (state, payload) => {
            state.details.isLoading = false
            state.details.response.message = payload.payload.res.message
            state.details.response.status = payload.payload.res.status
        },
        setField: (state, payload) => {
            state.details.data[payload.payload.field] = payload.payload.value
        },
        setDispose: (state, payload) => {
            state.details.response = payload.payload
        },
        updateAllowLogin: (state) => {
            state.details.isLoading = true
        },
        updateAllowLoginSuccess: (state, payload) => {
            state.details.isLoading = false
            state.details.response.message = payload.payload.res.message
            state.details.response.status = payload.payload.res.status

        },
        updateEmployeeRole: (state) => {
            state.details.isLoading = true
        },
        updateEmployeeRoleSuccess: (state, payload) => {
            state.details.isLoading = false
            state.details.response.message = payload.payload.res.message
            state.details.response.status = payload.payload.res.status
        },
        updateEmployeeCompany: (state) => {
            state.details.isLoading = true
        },
        updateEmployeeCompanySuccess: (state, payload) => {
            state.details.isLoading = false
            state.details.response.message = payload.payload.res.message
            state.details.response.status = payload.payload.res.status
        },
        getSyncEmployee: (state) => {
            state.update.sync.isLoading = true
        },
        getSyncEmployeeSuccess: (state, payload) => {
            state.update.sync.isLoading = false
            state.details.response.message = payload.payload.res.message
            state.details.response.status = payload.payload.res.status
        },
        updateEmployeeDetails: (state) => {
            state.details.isLoading = true
        },
        updateEmployeeDetailsSuccess: (state, payload) => {
            state.details.isLoading = false
            state.details.response.message = payload.payload.res.message
            state.details.response.status = payload.payload.res.status
        },
        getRoleList: (state) => {
            state.role.isLoading = true
        },
        getRoleListSuccess: (state, payload) => {
            state.role.isLoading = false
            state.role.list = payload.payload.res.list
        },
        getCompanyList: (state) => {
            state.company.isLoading = true
        },
        getCompanyListSuccess: (state, payload) => {
            state.company.isLoading = false
            state.company.list = payload.payload.res.list
        },
    }
})

export const {
    getEmployeeList,
    getEmployeeListSuccess,
    addEmployeeList,
    addEmployeeListSuccess,
    updateAllowLogin,
    updateAllowLoginSuccess,
    updateEmployeeRole,
    updateEmployeeRoleSuccess,
    updateEmployeeCompany,
    updateEmployeeCompanySuccess,
    getSyncEmployee,
    getSyncEmployeeSuccess,
    updateEmployeeDetails,
    updateEmployeeDetailsSuccess,
    setFlnceFields,
    setDispose,
    setField,
    getRoleList,
    getRoleListSuccess,
    getCompanyList,
    getCompanyListSuccess
} = employeeSlice.actions

export default employeeSlice.reducer