import { createSlice } from "@reduxjs/toolkit";

const RoleListSlice = createSlice({
  name: "rolelist",
  initialState: {
    role: {
      isLoading: false,
      list: [],
    },
  },
  reducers: {
    getRoleList: (state) => {
      state.role.isLoading = true;
    },
    getRoleListSuccess: (state, payload) => {
      state.role.isLoading = false;
      state.role.list = payload.res.list;
    },
  },
});

export const { getRoleList, getRoleListSuccess } = RoleListSlice.actions;

export default RoleListSlice.reducer;
