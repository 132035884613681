import { createSlice } from '@reduxjs/toolkit'

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: {
    isLoading: false,
    value: {
        OLDPASSWORD: '',
        NEWPASSWORD: '',
        CONFIRMPASSWORD: ''
    },
    apiResponse: {
      message: '',
      status: '',
    }
  },
  reducers: {
    setField(state, payload) {
      state.value[payload.payload.field] = payload.payload.value
    },
    updatePassword(state) {
      state.isLoading = true; // set isLoading to true
    },
    updatePasswordSuccess(state, payload) { // payload
      state.isLoading = false; // set isLoading to false
      state.apiResponse.message = payload.payload?.res.message;
      state.apiResponse.status = payload.payload?.res.status;
    },
    setDisposeMsg: (state, action) => {
      state.apiResponse = action.payload
    },
  }
})

export const { setField, updatePassword, updatePasswordSuccess, setDisposeMsg } = changePasswordSlice.actions
export default changePasswordSlice.reducer