import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "../helper";

const filterSalesSlice = createSlice({
    name: 'filterSales',
    initialState: {
        isLoading: false,
        params: {
            dateRange: datePresets[2].value,
            filtertype: 'Day'
        },
        data: {
            turnins: {
                dates: [],
                values: [],
                total: 0
            },
            installed: {
                dates: [],
                values: [],
                total: 0
            },
            target: 0
        }

    },
    reducers: {
        getFilterSalesSummary: (state) => {
            state.isLoading = true
        },
        getFilterSalesSummarySuccess: (state, payload) => {
            state.isLoading = false
            state.data = { ...state.data, ...payload.payload }
        },
        setParams: (state, payload) => {
            state.params = payload.payload
        }
    }
})

export const { setParams, getFilterSalesSummary, getFilterSalesSummarySuccess } = filterSalesSlice.actions
export default filterSalesSlice.reducer