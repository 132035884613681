import { takeEvery, call, put, select } from 'redux-saga/effects'
import request from 'src/utils/request'
import { RequestOptions } from 'src/helpers/RequestOptions'
import { getTargetSettingsSuccess, updateTargetSettingsSuccess } from '../reducer/targetSettingsReducer'
import { getHandleError, getHandleErrorVerify } from '../reducer/authHandlerReducer';
import { updatePasswordSuccess } from '../reducer/changePasswordReducer';

const API_URL = process.env.REACT_APP_API_URL;

function* updatePassword() {
    try {
        const { value } = yield select((state) => state.changePasswordReducer)

        const requestBody = yield call(RequestOptions, 'PUT', value)
        const response = yield call(request, `${API_URL}/auth/change-password`, requestBody)
    
        const { message, status } = response
    
        yield put(updatePasswordSuccess({
            res: {
                message, status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            message: error.response.status
        }))
    }
}

// watcher saga: will listen for actions and call worker saga
// takeEvery({reducer name}/function, worker saga, args for worker saga)
function* changePasswordSaga() {
  yield takeEvery('changePassword/updatePassword', updatePassword)
}

export default changePasswordSaga
