import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

const activationCountsSlice = createSlice({
    name: 'activationCounts',
    initialState: {
        port_util: {
            isLoading: false,
            value: {
                percent: 0
            }
        },
        target: {
            isLoading: false,
            value: {
                mtd: 0,
                mtd_p: 0,
                ytd: 0,
                ytd_p: 0
            },
            response: {
                message: '',
                status: ''
            }
        },
        actual: {
            isLoading: false,
            value: {
                mtd: 0,
                mtd_p: 0,
                ytd: 0,
                ytd_p: 0
            }
        },
        forecast: {
            isLoading: false,
            value: {
                mtd: 0,
                mtd_p: 0,
                ytd: 0,
                ytd_p: 0
            }
        },
        act_utilized: {
            isLoading: false,
            params: {
                dateRange: datePresets[3].value,
                filtertype: 'month'
            },
            data: {
                installed: {
                    dates: [],
                    values: [],
                    total: 0
                }
            }
        }
    },
    reducers: {
        getActivationCount: (state) => {
            state.port_util.isLoading = true
            state.target.isLoading = true
            state.actual.isLoading = true
            state.forecast.isLoading = true
        },
        getActivationCountSuccess: (state, payload) => {
            if (payload.payload.count) {
                state.port_util.isLoading = false
                state.port_util.value.percent = payload.payload.count
            }
            if (payload.payload.data) {
                state.target.isLoading = false
                state.target.value.mtd = payload.payload?.data?.act?.t_mtd ?? 0
                state.target.value.ytd = payload.payload?.data?.act?.t_ytd ?? 0
                state.target.value.mtd_p = payload.payload?.data?.act?.t_mtd_p ?? 0
                state.target.value.ytd_p = payload.payload?.data?.act?.t_ytd_p ?? 0

                state.actual.isLoading = false
                state.actual.value.mtd = payload.payload?.data?.act?.a_mtd ?? 0
                state.actual.value.ytd = payload.payload?.data?.act?.a_ytd ?? 0
                state.actual.value.mtd_p = payload.payload?.data?.act?.a_mdt_p ?? 0
                state.actual.value.ytd_p = payload.payload?.data?.act?.a_ytd_p ?? 0

                state.forecast.isLoading = false
                state.forecast.value.mtd = payload.payload?.data?.act?.f_mtd ?? 0
                state.forecast.value.ytd = payload.payload?.data?.act?.f_ytd ?? 0
                state.forecast.value.mtd_p = payload.payload?.data?.act?.f_mdt_p ?? 0
                state.forecast.value.ytd_p = payload.payload?.data?.act?.f_ytd_p ?? 0
            }
        },
        updateActivationTarget: (state) => {
            state.target.isLoading = true
        },
        updateActivationTargetSuccess: (state, payload) => {
            state.target.isLoading = false
            if (payload.payload.res) {
                state.target.response = payload.payload.res
            }
        },
        setDispose: (state, payload) => {
            state.target.response = payload.payload
        },
        getActivationUtilized: (state) => {
            state.act_utilized.isLoading = true
        },
        getActivationUtilizedSuccess: (state, payload) => {
            state.act_utilized.isLoading = false
            state.act_utilized = { ...state.act_utilized, data: payload.payload }
        }
    }
})

export const { getActivationCount, getActivationCountSuccess, updateActivationTarget, updateActivationTargetSuccess, setDispose, getActivationUtilized, getActivationUtilizedSuccess } = activationCountsSlice.actions
export default activationCountsSlice.reducer