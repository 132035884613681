import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    list: {
        filter: {
            type: '',
            schedule: ''
        },
        isLoading: false,
        data: [],
        isExporting:false
    },
    jo: {
        isLoading: false,
        data: []
    },
    details: {
        isLoading: false,
        data: []
    },
    teams: {
        isLoading: false,
        data: []
    },
    response: {
        message: '',
        status: ''
    },
    assign: {
        isLoading: false,
        field: {
            SPECIALINSTRUCTIONS: '',
            INSTALLERTEAM: ''
        },
    }
}

const installationSlice = createSlice({
    name: 'install',
    initialState,
    reducers: {
        setFilter: (state, action) => {
            state.list.filter = action.payload
            console.log(action.payload)
        },
        getInstallList: (state) => {
            state.list.isLoading = true
        },
        getInstallListSuccess: (state, action) => {
            state.list.isLoading = false
            state.list.data = action.payload.res.list
        },
        getJOList: (state) => {
            state.jo.isLoading = true
        },
        getJOListSuccess: (state, action) => {
            state.jo.isLoading = false
            state.jo.data = action.payload.res.list
        },
        updateEachList: (state) => {
            state.list.isLoading = true
        },
        updateEachListSuccess: (state, action) => {
            state.list.isLoading = false
            state.response = { ...action.payload.res }
        },
        setDisposeMsg: (state, action) => {
            state.response = action.payload
        },
        getEachInstallDetails: (state) => {
            state.details.isLoading = true
        },
        getEachInstallDetailsSuccess: (state, action) => {
            state.details.isLoading = false
            state.details.data = action.payload.res.data
        },
        getInstallerTeams: (state) => {
            state.teams.isLoading = true
        },
        getInstallerTeamsSuccess: (state, action) => {
            state.teams.isLoading = false
            state.teams.data = action.payload.res.data
        },
        setAssignFields: (state, action) => {
            state.assign.field[action.payload.field] = action.payload.value
        },
        addAssignInstaller: (state) => {
            state.assign.isLoading = true
        },
        addAssignInstallerSuccess: (state, action) => {
            state.assign.isLoading = false
            state.response = { ...action.payload.res }
        },
        exportSLIReport: (state) => {
            state.list.isExporting = true
        },
        exportSLIReportSuccess: (state, payload) => {
            state.list.isExporting = false
            if(payload.payload.res){
                state.counters.update.response.message = payload.payload?.res?.message
                state.counters.update.response.status = payload.payload?.res?.status
                state.counters.update.response.error = payload.payload?.res?.error
            }
        },


    }
})
export const {
    setFilter,
    setDisposeMsg,
    getInstallList,
    getInstallListSuccess,
    getJOList,
    getJOListSuccess,
    updateEachList,
    updateEachListSuccess,
    getEachInstallDetails,
    getEachInstallDetailsSuccess,
    getInstallerTeams,
    getInstallerTeamsSuccess,
    setAssignFields,
    addAssignInstaller,
    addAssignInstallerSuccess,
    exportSLIReport,
    exportSLIReportSuccess
} = installationSlice.actions
export default installationSlice.reducer