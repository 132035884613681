import { createSlice } from "@reduxjs/toolkit";

const farActivationSlice = createSlice({
    name: 'farActivationPort',
    initialState: {
        isLoading: false,
        filter: {
            location: 'city of calapan'
        },
        data: {
            city: [],
            value: []
        }
    },
    reducers: {
        getFarActivation: (state) => {
            state.isLoading = true
        },
        getFarActivationSuccess: (state, payload) => {
            state.isLoading = false
            state.data = payload.payload.data
        },
        setFilter: (state, payload) => {
            state.filter = payload.payload
        }
    }
})

export const { getFarActivation, getFarActivationSuccess, setFilter } = farActivationSlice.actions
export default farActivationSlice.reducer