import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

const initialState = {
    filter: {
        dateRange: datePresets[2].value
    },
    materials: {
        isLoading: false,
        counters: [],
        data: []
    },
    graph: {
        reasonoutage: {
            isLoading: false,
            data: []
        },
        issue: {
            isLoading: false,
            data: [],
            value: []
        },
    },
    counters: {
        isLoading: false,
        data: {
            ticketResolved: 0,
            ticketsReceived: 0,
            averageTimeResolvedInternal: 0,
            averageTimeResolvedConverge: 0,
            totalTeam: 0,
            totalSLRMembers: 0,
            ticketPercentageResolved: 0,
            averageRepairPerTeam: 0
        },
        update: {
            field: {
                TOTALJORECEIVED: '',
                AVERAGEINSTALLATIONTEAM: '',
                TOTALTEAM: '',
                TOTALINSTALLERS: '',
                AVERAGETIMESPENTINSTALLATION: '',
                EFFECTIVEDATE: ''
            },
            response: {
                message: '',
                status: '',
                error: ''
            }
        }
    },
    employee: {
        isLoading: false,
        data: {
            list: []
        },
        field: {
            name: '',
            members: []
        },
        teams: []
    }
}

const repairCounterSlice = createSlice({
    name: 'ticketCounter',
    initialState,
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        getMaterials: (state) => {
            state.materials.isLoading = true
        },
        getMaterialsSuccess: (state, action) => {
            state.materials.isLoading = false
            state.materials.data = action.payload.res.list
        },
        getMaterialsUsed: (state) => {
            state.materials.isLoading = true
        },
        getMaterialsUsedSuccess: (state, action) => {
            state.materials.isLoading = false
            state.materials.counters = action.payload.res.data
        },
        getReasonOutageCardGraph: (state) => {
            state.graph.reasonoutage.isLoading = true
        },
        getReasonOutageCardGraphSuccess: (state, payload) => {
            state.graph.reasonoutage.isLoading = false

            state.graph.reasonoutage.data = payload.payload.data
        },
        getIssueCardGraph: (state) => {
            state.graph.issue.isLoading = true
        },
        getIssueCardGraphSuccess: (state, payload) => {
            state.graph.issue.isLoading = false

            state.graph.issue.data = payload.payload.data
            state.graph.issue.value = payload.payload.value
        },
        getCardTotalCounters: (state) => {
            state.counters.isLoading = true
        },
        getCardTotalCountersSuccess: (state, action) => {
            state.counters.isLoading = false
            state.counters.data = { ...action.payload }
        },
        updateCardTotalCounters: (state) => {
            state.counters.isLoading = true
        },
        updateCardTotalCountersSuccess: (state, payload) => {
            state.counters.isLoading = false
            state.counters.update.response.message = payload.payload.res.message
            state.counters.update.response.status = payload.payload.res.status
            state.counters.update.response.error = payload.payload?.res?.error
        },
        setDispose: (state, payload) => {
            state.counters.update.response = payload.payload
        },
        getEmployeeListDept: (state) => {
            state.employee.isLoading = true
        },
        getEmployeeListDeptSuccess: (state, action) => {
            state.employee.isLoading = false
            state.employee.data = { ...action.payload.res }
            state.employee.teams = action.payload.res.teams
        },
        addTeams: (state) => {
            state.employee.isLoading = true
        },
        addTeamsSuccess: (state, action) => {
            state.employee.isLoading = false
        },
        setFieldEmp: (state, action) => {
            state.employee.field[action.payload.field] = action.payload.value
        },
        getEachTeams: (state) => {
            state.employee.isLoading = true
        },
        getEachTeamsSuccess: (state, action) => {
            state.employee.isLoading = false
            state.employee.field.members = action.payload.res.list
            state.employee.field.name = action.payload.res.name
        },
        setSelectedMember: (state, action) => {
            state.employee.field.members = action.payload
        }
    }
})

export const {
    setFilter,
    getMaterials,
    getMaterialsSuccess,
    getMaterialsUsed,
    getMaterialsUsedSuccess,
    getReasonOutageCardGraph,
    getReasonOutageCardGraphSuccess,
    getIssueCardGraph,
    getIssueCardGraphSuccess,
    getCardTotalCounters,
    getCardTotalCountersSuccess,
    updateCardTotalCounters,
    updateCardTotalCountersSuccess,
    setDispose,
    getEmployeeListDept,
    getEmployeeListDeptSuccess,
    addTeams,
    addTeamsSuccess,
    setFieldEmp,
    getEachTeams,
    getEachTeamsSuccess,
    setSelectedMember
} = repairCounterSlice.actions

export default repairCounterSlice.reducer