import { createSlice } from "@reduxjs/toolkit";

const salesRateSlice = createSlice({
    name: 'salesRateCounts',
    initialState: {
        desc: {
            isLoading: false,
            pos: 0,
            pre: 0,
            sme: 0,
            ics: 0
        },
        counts: {
            isLoading: false,
            numAgents: 0,
            utlRate: 0
        }
    },
    reducers: {
        getSalesCountsDesc: (state) => {
            state.desc.isLoading = true
        },
        getSalesCountsDescSuccess: (state, payload) => {
            state.desc.isLoading = false

            const { icsCount, postpaid, prepaid, smeCount } = payload.payload?.res

            state.desc.ics = icsCount
            state.desc.pos = postpaid
            state.desc.pre = prepaid
            state.desc.sme = smeCount
        },
        getSalesCountsAgent: (state) => {
            state.counts.isLoading = true
        },
        getSalesCountsAgentSuccess: (state, payload) => {
            state.counts.isLoading = false
            const { agentsCount, aveSalesPerAgent } = payload.payload?.res
            state.counts.numAgents = agentsCount
            state.counts.utlRate = (aveSalesPerAgent === null) ? 0 : aveSalesPerAgent
        }
    }
})

export const { getSalesCountsDesc, getSalesCountsDescSuccess, getSalesCountsAgent, getSalesCountsAgentSuccess } = salesRateSlice.actions
export default salesRateSlice.reducer