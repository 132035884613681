import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getHandleErrorVerify } from "src/container/DashboardPage/reducer/authHandlerReducer";
import { RequestOptions } from "src/helpers/RequestOptions";
import { formatDate } from "src/utils/formatter";
import request from "src/utils/request";
import { getActivationCountSuccess, getActivationUtilizedSuccess, updateActivationTargetSuccess } from "../reducer/activationCountsReducer";
import { getFarActivationSuccess } from "../reducer/activationPortReducer";
import { getPortDataPerMunSuccess } from "../reducer/portDataPerMunReducer";
import { getPortUtilizationSuccess } from "../reducer/portUtilizationReducer";
import { getSalesCountsAgentSuccess, getSalesCountsDescSuccess } from "../reducer/salesRateCountsReducer";

const API_URL = process.env.REACT_APP_API_URL;

function* fetchFarthestActivation(payload) {
    const { location } = payload.payload
    const api = yield call(request, `${API_URL}/get-farthest-activation?municipality=${location}`)
    let city = []
    let value = []
    api?.farthest.forEach(el => {
        city.push(el._id)
        value.push(el.count_days)
    });
    yield put(getFarActivationSuccess({
        data: {
            states: city,
            values: value
        }
    }))
}

function* fetchSalesPortData(payload) {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const location = payload.payload.location
    const response = yield call(request, `${API_URL}/get-port-per-barangay?municipality=${location}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    let city = []
    let util = []
    response[0]?.results?.forEach((el) => {
        city.push(el._id)
        util.push((el.utilized_ports / el.deployed_ports * 100).toFixed(2))
    })
    const data = {
        city: !city ? [] : city,
        utilized: !util ? [] : util,
    }
    yield put(getPortUtilizationSuccess({
        data
    }))
}

function* fetchPortUtilized() {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const port_util = yield call(request, `${API_URL}/port-utilization-rate?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    const count = port_util[0].value.length > 0 ? port_util[0].value[0].count : 0;
    yield put(getActivationCountSuccess({
        count
    }))
}

function* fetchTarget() {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const act_target = yield call(request, `${API_URL}/activation-target?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    let mtd,
        ytd = 0
    mtd = act_target.data.act_mtd === null ? 0 : act_target.data.act_mtd
    ytd = act_target.data.act_ytd === null ? 0 : act_target.data.act_ytd
    yield put(getActivationCountSuccess({
        target: {
            mtd,
            ytd
        }
    }))
}

function* fetchActual() {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const act_actual = yield call(request, `${API_URL}/get-activation-actual?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    let mtd, mtd_p, ytd, ytd_p = 0
    mtd = act_actual.mtd !== null ? act_actual.mtd : 0
    mtd_p = act_actual.mtd_p !== null ? act_actual.mtd_p : 0
    ytd = act_actual.ytd !== null ? act_actual.ytd : 0
    ytd_p = act_actual.ytd_p !== null ? act_actual.ytd_p : 0
    yield put(getActivationCountSuccess({
        actual: {
            mtd,
            mtd_p,
            ytd,
            ytd_p
        }
    }))
}

function* fetchForecast() {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const act_forecast = yield call(request, `${API_URL}/get-activation-forecast?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    let mtd, mtd_p, ytd, ytd_p = 0
    mtd = act_forecast.mtd !== null ? act_forecast.mtd : 0
    mtd_p = act_forecast.mtd_p !== null ? act_forecast.mtd_p : 0
    ytd = act_forecast.ytd !== null ? act_forecast.ytd : 0
    ytd_p = act_forecast.ytd_p !== null ? act_forecast.ytd_p : 0
    yield put(getActivationCountSuccess({
        forecast: {
            mtd,
            mtd_p,
            ytd,
            ytd_p
        }
    }))
}

function* fetchEachActivation() {
    yield all([
        call(fetchPortUtilized),
        call(fetchTarget),
        call(fetchActual),
        call(fetchForecast)
    ])
}

function* fetchActivation() {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const api = yield call(request, `${API_URL}/get-activations?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    yield put(getActivationCountSuccess({
        data: {
            act: api.data
        }
    }))
}

function* putSalesActivationTarget(fields) {
    try {
        const requestBody = yield call(RequestOptions, 'post', fields.payload)
        const update = yield call(request, `${API_URL}/activation-target`, requestBody)
        yield put(updateActivationTargetSuccess({
            res: {
                message: update.message,
                status: update.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }

}

function* fetchSalesDesc() {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const res = yield call(request, `${API_URL}/sales-description?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    yield put(getSalesCountsDescSuccess({
        res
    }))
}

function* fetchAgentCounters() {
    const { dateRange } = yield select((state) => state.filterSalesDashboardReducer.params)
    const res = yield call(request, `${API_URL}/agents/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    yield put(getSalesCountsAgentSuccess({
        res
    }))
}

function* fetchActivationUtilized() {
    const { params } = yield select((state) => state.activationCountReducer.act_utilized)
    const res = yield call(request, API_URL + '/number-of-installed-accounts?filter_type=' + params.filtertype + '&start_date=' + formatDate(params.dateRange[0]) + '&end_date=' + formatDate(params.dateRange[1]));
    let dates = []
    let values = []

    res.result.forEach((el) => {
        dates.push(el._id)
        values.push(el.count)
    })
    yield put(getActivationUtilizedSuccess({
        installed: {
            dates,
            values,
            total: res.totalCount
        }
    }))
}

function* fetchPortPerMun() {
    // const d = yield select((state) => state.salesPortPerMunReducer)
    yield put(getPortDataPerMunSuccess({

    }))
}


function* salesSaga() {
    yield takeLatest('farActivationPort/getFarActivation', fetchFarthestActivation)
    yield takeLatest('salesPortData/getPortUtilization', fetchSalesPortData)
    yield takeEvery('activationCounts/getActivationCount', fetchEachActivation)
    yield takeEvery('activationCounts/updateActivationTarget', putSalesActivationTarget)
    yield takeLatest('activationCounts/getActivationCount', fetchActivation)
    yield takeLatest('salesRateCounts/getSalesCountsDesc', fetchSalesDesc)
    yield takeLatest('salesRateCounts/getSalesCountsAgent', fetchAgentCounters)
    yield takeLatest('activationCounts/getActivationUtilized', fetchActivationUtilized)
    yield takeLatest('portDataPerMun/getPortDataPerMun', fetchPortPerMun)
}

export default salesSaga