import {
  call,
  put,
  takeLatest,
  all,
  // select 
} from 'redux-saga/effects'
import request from 'src/utils/request';
import { getPortsSuccess } from '../reducer/portCardReducer';
import { getPortPerLocationDataSuccess } from '../reducer/portPerLocationDataReducer';

const API_URL = process.env.REACT_APP_API_URL;

function* fetchDeployed() {
  const deployed = yield call(request, API_URL + '/deployed-ports');
  yield put(getPortsSuccess({
    deployed,
  }));
}

function* fetchUtilizedRate() {
  const utilization_rate = yield call(request, API_URL + '/port-utilization-rate');
  yield put(getPortsSuccess({
    utilization_rate,
  }));
}

function* fetchUtilized() {
  const utilized = yield call(request, API_URL + '/utilized-ports');
  yield put(getPortsSuccess({
    utilized,
  }));
}

function* fetchAvailable() {
  const available = yield call(request, API_URL + '/available-ports');
  yield put(getPortsSuccess({
    available,
  }));
}

function* fetchReserved() {
  const reserved = yield call(request, API_URL + '/reserved-ports');
  yield put(getPortsSuccess({
    reserved,
  }));
}


function* fetchEachPorts() {
  yield all([
    call(fetchDeployed),
    call(fetchUtilizedRate),
    call(fetchUtilized),
    call(fetchAvailable),
    call(fetchReserved)
  ])
}

function* fetPortsPerData() {
  const api = yield call(request, `${API_URL}/get-port-per-municipality`)
  let location = [];
  let utilized = [];
  let targets = [];
  let deployed = [];

  api[0]?.results?.map((obj) => location.push(obj._id.municipality))
  api[0]?.results?.map((obj) => utilized.push(obj.utilized_ports))
  api[0]?.results?.map((obj) => deployed.push(obj.deployed_ports))
  api[0]?.results?.map((obj) => targets.push(obj.targets))

  const data = {
    locations: location.length >= 0 ? location : [],
    deployed: deployed.length >= 0 ? deployed : [],
    utilized: utilized.length >= 0 ? utilized : [],
    targets: targets.length >= 0 ? targets : []
  }

  yield put(getPortPerLocationDataSuccess({
    data
  }))

}
// watcher saga: will listen for actions and call worker saga
// takeEvery({reducer name}/function, worker saga, args for worker saga)
function* portCardSaga() {
  yield takeLatest('ports/getPorts', fetchEachPorts)
  yield takeLatest('portLocationData/getPortPerLocationData', fetPortsPerData)
}

export default portCardSaga
