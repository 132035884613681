import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { getHandleErrorVerify } from "src/container/DashboardPage/reducer/authHandlerReducer";
import { RequestOptions } from "src/helpers/RequestOptions";
import request from "src/utils/request";
import { addEmployeeListSuccess, getCompanyListSuccess, getEmployeeListDetails, getEmployeeListSuccess, getRoleListSuccess, getSyncEmployeeSuccess, updateAllowLoginSuccess, updateEmployeeCompanySuccess, updateEmployeeDetailsSuccess, updateEmployeeRoleSuccess } from "../reducer/employeeReducer"


const API_URL = process.env.REACT_APP_API_URL;

function* fetchEmpList() {
    const response = yield call(request, `${API_URL}/employees`)
    yield put(getEmployeeListSuccess({
        response
    }))
}

function* addEmpList(payload) {
    const fields = {
        NAME: payload.payload.empname,
        DEPARTMENT: payload.payload.department,
        DESIGNATION: payload.payload.designation,
        JOININGDATE: payload.payload.joinDate,
        ENDDATE: payload.payload.endDate,
        ISFREELANCE: payload.payload.isFreelance,
        REMARKS: payload.payload.remarks
    }
    try {
        const requestBody = yield call(RequestOptions, 'post', fields)
        const response = yield call(request, `${API_URL}/employees/freelance`, requestBody)

        yield put(addEmployeeListSuccess({
            res: {
                fields,
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status,
                status: error.status
            }
        }))
    }

}

function* updateLoginStatus(payload) {
    const { value, id, name } = payload.payload
    const fields = {
        value: value
    }
    try {
        const requestBody = yield call(RequestOptions, 'PATCH', fields)
        const response = yield call(request, `${API_URL}/employees/${id}/islogin`, requestBody)
        yield put(updateAllowLoginSuccess({
            res: {
                message: `${name} ${value ? `is allowed to login` : `unable to login`}`,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status,
                status: error.status
            }
        }))
    }

}

function* updateEmployeeRole(payload) {
    const { value, id } = payload.payload
    const fields = {
        value: value
    }
    try {
        const requestBody = yield call(RequestOptions, 'PATCH', fields)
        const response = yield call(request, `${API_URL}/employees/${id}/role`, requestBody)

        yield put(updateEmployeeRoleSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status,
                status: error.status
            }
        }))
    }
}

function* getSyncEmployee() {
    try {
        const response = yield call(request, `${API_URL}/employees/sync`)
        yield put(getSyncEmployeeSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status,
                status: error.status
            }
        }))
    }
}

function* updateEachEmployeeDetails(payload) {
    const fields = {
        _id: payload.payload.id,
        NAME: payload.payload.empname,
        DEPARTMENT: payload.payload.department,
        DESIGNATION: payload.payload.designation,
        JOININGDATE: payload.payload.joinDate,
        ENDDATE: payload.payload.endDate,
        ISFREELANCE: payload.payload.isFreelance,
        REMARKS: payload.payload.remarks
    }

    try {
        const requestBody = yield call(RequestOptions, 'PUT', fields)
        const response = yield call(request, `${API_URL}/employees/${fields._id}`, requestBody)
        yield put(updateEmployeeDetailsSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {

    }

}

function* fetchRolelist() {
    const obj = yield call(request, `${API_URL}/role`)
    const { data, message, status } = obj
    yield put(getRoleListSuccess({
        res: { list: data, message, status }
    }))
}

function* fetchCompanyList() {
    const response = yield call(request, `${API_URL}/company`)
    const { data, message, status } = response

    yield put(getCompanyListSuccess({
        res: { list: data, message, status }
    }))
}

function* updateEmployeeCompany(payload) {
    const { value, id } = payload.payload
    const fields = {
        COMPANY: value
    }
    try {
        const requestBody = yield call(RequestOptions, 'PATCH', fields)
        const response = yield call(request, `${API_URL}/employees/${id}/assign/company`, requestBody)

        yield put(updateEmployeeCompanySuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status,
                status: error.status
            }
        }))
    }
}

function* employeeSaga() {
    yield takeLatest('employee/getEmployeeList', fetchEmpList)
    yield takeLatest('employee/addEmployeeList', addEmpList)
    yield takeLatest('employee/updateAllowLogin', updateLoginStatus)
    yield takeEvery('employee/updateEmployeeRole', updateEmployeeRole)
    yield takeEvery('employee/updateEmployeeCompany', updateEmployeeCompany)
    yield takeEvery('employee/getSyncEmployee', getSyncEmployee)
    yield takeLatest('employee/updateEmployeeDetails', updateEachEmployeeDetails)
    yield takeLatest('employee/getRoleList', fetchRolelist)
    yield takeLatest('employee/getCompanyList', fetchCompanyList)
}

export default employeeSaga