import { createSlice } from "@reduxjs/toolkit";

const portDataSlice = createSlice({
    name: 'data',
    initialState: {
        isLoading: false,
        data: {
            deployed: 0,
            utilized: 0,
            available: 0,
            // reserved: 0,
            utilization_rate: 0
        }
    },
    reducers: {
        getLocationData: (state) => {
            state.isLoading = true
        },
        getLocationDataSuccess: (state, payload) => {
            state.isLoading = false
            state.data = payload.payload
        }
    }
})

export const { getLocationData, getLocationDataSuccess } = portDataSlice.actions
export default portDataSlice.reducer