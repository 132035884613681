import { createSlice } from '@reduxjs/toolkit'

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoading: false,
    user: null,
    message: '',
    redirect: {
      state: false,
      url: '',
    }
  },
  reducers: {
    tryLogin: (state) => {
      state.isLoading = true
    },
    loginSuccess: (state, payload) => {
      state.isLoading = false
      state.user = payload.payload
      state.redirect.state = true

      // /* Checking the role of the user and redirecting them to the appropriate page. */
      // if (payload.payload.ROLE === "ADMIN" || payload.payload.ROLE === "SUPER ADMIN") {
      //   console.log(payload.payload.ROLE)
      //   state.redirect.url = '/dashboard'
      //   break;
      // } else if (payload.payload.ROLE === "SALES AGENT" || payload.payload.ROLE === "SALES SUPERVISOR") {
      //   console.log(payload.payload.ROLE)
      //   state.redirect.url = '/transaction/card-tracker'
      // } else if (payload.payload.ROLE === "OMD") {
      //   console.log(payload.payload.ROLE)
      //     state.redirect.url = '/transaction/omd'
      // } else if (payload.payload.ROLE === "DISPATCH" || payload.payload.ROLE === "SLI" || payload.payload.ROLE === "SLI SUPERVISOR") {
      //   console.log(payload.payload.ROLE)
      //     state.redirect.url = '/transaction/installation'
      // } else if (payload.payload.ROLE === "CX AGENT" || payload.payload.ROLE === "CX SUPERVISOR") {
      //   console.log(payload.payload.ROLE)
      //     state.redirect.url = '/transaction/call-logs'
      // } else {
      //     state.redirect.url = '/403'
      // }

      switch (payload.payload.ROLE) { // for confirmation
        case "admin":
        case "super admin":
          state.redirect.url = '/dashboard'
          break;
        case "sales agent":
        case "sales supervisor":
          state.redirect.url = '/transaction/card-tracker/x'
          break;
        case "omd":
          state.redirect.url = '/transaction/omd'
          break;
        case "dispatch":
        case "sli":
        case "sli supervisor":
          state.redirect.url = '/transaction/installation'
          break;
        case "cx agent":
        case "cx supervisor":
          state.redirect.url = '/transaction/call-logs'
          break;
        default:
          break;
      }
    },
    loginError: (state, payload) => {
      state.isLoading = false;
      state.message = payload.payload
    },
    setDisposeRedirect: (state) => {
      state.redirect.state = false
    }
  }
})

export const { tryLogin, loginSuccess, loginError, setDisposeRedirect } = loginSlice.actions
export default loginSlice.reducer