import moment from "moment";
// format to philippine currency
export const formatToPhilippineCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', {
        style: 'currency',
        currency: 'PHP',
        minimumFractionDigits: 2
    }).format(value);
}
// format to percentage
export const formatToPercentage = (value) => {
    return new Intl.NumberFormat('en-PH', {
        style: 'percent',
        minimumFractionDigits: 2
    }).format(value/100);
}
// format to hours
export const formatToHours = (value) => {
    return new Intl.NumberFormat('en-PH', {
        style: 'decimal',
        minimumFractionDigits: 2
    }).format(value) + ' hrs';
}
// format from hours to days
export const formatHoursToDays = (value) => {
    return new Intl.NumberFormat('en-PH', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value/24) + ' days';
}
// format date to MM/DD/YYYY
export const formatDate = (date,format='MM-DD-YYYY') => {
    return moment(date).format(format);
}