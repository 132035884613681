import React, { Component, Suspense, useState, useEffect } from 'react'
import {
  HashRouter, Route, Routes,
  Navigate,
  Outlet,
  useNavigate,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './scss/style.scss'
import './assets/css/style.css'
import { Oval } from 'react-loader-spinner'
import myfilogo from './assets/images/logo/myfi-logo.png'
import LS from 'src/helpers/LocalStorage'
import "rsuite/dist/rsuite.min.css";
import { getUserDetails, setDispose } from './container/DashboardPage/reducer/authHandlerReducer'

const loading = (
  <div className=" d-flex justify-content-center align-items-center" style={{ height: '100vh' }} >
    <div className="d-inline-block text-center" >
      <img src={myfilogo} alt="logo" height={85} width={85} />
      <Oval height={25} />
    </div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./container/LoginPage'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'))
// url from env
const API_URL = process.env.REACT_APP_API_URL;

// check token
const PrivateRoute = () => {

  /* `useNavigate` is a hook that is used to navigate to a different route. `useDispatch` is a hook
  that is used to dispatch an action. */
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { message } = useSelector(state => state.authHandlerReducer.error)
  /* This is a hook that is used to check if the user is authenticated or not. If the user is not
  authenticated, then the user is redirected to the login page. */
  useEffect(() => {
    if (message === 401) {
      navigate('/401')
    }
    setTimeout(() => { dispatch(setDispose({ ...message, message: null })) }, 1000)
  }, [dispatch, message])

  /* Reading the token from the local storage. */
  const token = LS.readLocalStorage('token')
  /* A state variable that is used to store the component that is to be rendered. */
  const [component, setComponent] = useState(<div>Redirecting...</div>)

  /* Calling the `getUserDetails` action creator. */
  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  /* This is a function that is called when the component is mounted. It is used to check if the token
  is valid or not. */
  useEffect(() => {
    fetch(API_URL + '/auth/verify', {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
    }).then(res => res.json())
      .then(data => {
        return new Promise(resolve => resolve(data.status !== 'error'))
      }).then(a => {
        setComponent(a ? <Outlet /> : <Navigate to="/login" />)
      }).catch((error) => console.log(error))
  }, [token])

  /* This is a check to see if the user is authenticated or not. If the user is not authenticated,
  then the user is redirected to the login page. */
  if (!token) {
    return <Navigate to={'/login'} replace />;
  }
  return component
};

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/401" name="Page 401" element={<Page401 />} />
            <Route path="*" element={<PrivateRoute />} >
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}
export default App
