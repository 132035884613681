import { createSlice } from '@reduxjs/toolkit'

const collectionReducerSlice = createSlice({
  name: 'collection',
  initialState: {
    importing: {
      isLoading: false
    },
    response: {
      message: '',
      status: ''
    }
  },
  reducers: {
    importCollection(state) {
      state.importing.isLoading = true
    },
    importCollectionSuccess(state, payload) {
      state.importing.isLoading = false
      state.response.message = payload.payload?.res.message
      state.response.status = payload.payload?.res.status
    },
    setDisposeMsg: (state, action) => {
      state.importing.isLoading = false
      state.response = action.payload
    },
  }
})

export const { importCollection, importCollectionSuccess, setDisposeMsg } = collectionReducerSlice.actions
export default collectionReducerSlice.reducer