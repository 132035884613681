import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

const filterSummarySlice = createSlice({
    name: 'filterSummary',
    initialState: {
        params: {
            dateRange: datePresets[2].value,
            time: 1,
            filtertype: 'Day' 
        },
    },
    reducers: {
        setParams(state, payload){
            state.params = payload.payload
        }
    }
})

export const { setParams } = filterSummarySlice.actions
export default filterSummarySlice.reducer