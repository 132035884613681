import CreateSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'

import filterReducer from './container/DashboardPage/reducer/filterReducer'
import totalJOReducer from './container/SummaryGraphPage/reducer/totalJOReducer'
import totalTicketReducer from './container/SummaryGraphPage/reducer/totalTicketReducer'


import filterSummaryReducer from './container/SummaryGraphPage/reducer/filterSummaryReducer'
import summaryGraphSaga from './container/SummaryGraphPage/saga/saga'
import salesSummaryGraphReducer from './container/SummaryGraphPage/reducer/salesSummaryGraphReducer'
import ticketSummaryGraphReducer from './container/SummaryGraphPage/reducer/ticketSummaryGraphReducer'

// import sidebar reducer
import sidebarReducer from './container/DashboardPage/reducer/sidebarReducer'

//import ports reducers and saga
import portPerLocationDataReducer from './container/DashboardPage/reducer/portPerLocationDataReducer'
import portCardReducer from './container/DashboardPage/reducer/portCardReducer'
import portCardSaga from './container/DashboardPage/saga/portCardSaga'


// import dashboard page reducers and saga
import counterReducer from './container/DashboardPage/reducer/counterReducer'
import salesSummaryReducer from './container/DashboardPage/reducer/salesSummaryReducer'
import ticketSummaryReducer from './container/DashboardPage/reducer/ticketSummaryReducer'
import recentActivityListReducer from './container/DashboardPage/reducer/recentActivityListReducer'
import netPromoterScoreReducer from './container/DashboardPage/reducer/netPromoterScoreReducer'
import dashboardSaga from './container/DashboardPage/saga/saga'

// import target settings reducers and saga
import targetSettingsReducer from './container/DashboardPage/reducer/targetSettingsReducer'
import targetSettingsSaga from './container/DashboardPage/saga/targetSettingsSaga'

// import clients page reducers and saga
import clientsListReducer from './container/ClientPage/reducer/clientsListReducer'
import clientSaga from './container/ClientPage/saga/saga'

import portLocationReducer from './container/PortLocationPage/reducer/portLocationReducer'
import portLocationDataReducer from './container/PortLocationPage/reducer/portLocationDataReducer'
import portFilterReducer from './container/PortLocationPage/reducer/portFilterReducer'
import portLocationGraphReducer from './container/PortLocationPage/reducer/portLocationGraphReducer'
import portSaga from './container/PortLocationPage/saga/saga'

import filterSalesReducer from './container/DashboardPage/reducer/filterSalesReducer'
import filterTicketReducer from './container/DashboardPage/reducer/filterTicketReducer'
import portCityReducer from './container/PortLocationPage/reducer/portCityReducer'

import salesSaga from './container/SalesPage/saga/saga'
import { default as filterSalesDashboardReducer } from './container/SalesPage/reducer/filterReducer'
import { default as farthestActivationPortReducer } from './container/SalesPage/reducer/activationPortReducer'
import { default as salesPortUtilizationReducer } from './container/SalesPage/reducer/portUtilizationReducer'
import { default as activationCountReducer } from './container/SalesPage/reducer/activationCountsReducer'
import { default as salesCountDescReducer } from './container/SalesPage/reducer/salesRateCountsReducer'
import { default as salesPortPerMunReducer } from './container/SalesPage/reducer/portDataPerMunReducer'

import cardTrackerSaga from './container/Transaction/CardTrackerPage/saga/saga'
import { default as turnInListReducer } from './container/Transaction/CardTrackerPage/reducer/TurnListReducer'
import { default as updateTurnInListReducer } from './container/Transaction/CardTrackerPage/reducer/updateTurnInListReducer'
import { default as cardGraphCountersReducer } from './container/Transaction/CardTrackerPage/reducer/cardGraphCountersReducer'
import { default as locGraphCountersReducer } from './container/Transaction/CardTrackerPage/reducer/locationGraphCountersReducer'

import employeeSaga from './container/AgentsPage/saga/saga'
import { default as employeeReducer } from './container/AgentsPage/reducer/employeeReducer'

import authSaga from './container/DashboardPage/saga/authSaga'
import authHandlerReducer from './container/DashboardPage/reducer/authHandlerReducer'

import loginReducer from './container/LoginPage/reducer'
import loginSaga from './container/LoginPage/saga'

import modelSaga from './container/Transaction/OmdPage/saga/saga'
import { default as modelObjDevReducer } from './container/Transaction/OmdPage/reducer/modelReducer'

import callLogsSaga from './container/Transaction/CallLogsPage/saga/saga'
import { default as callLogsReducer } from './container/Transaction/CallLogsPage/reducer/callLogsReducer'
import { default as countersCallLogsReducer } from './container/Transaction/CallLogsPage/reducer/counterCallLogsReducer'

import changePasswordReducer from './container/DashboardPage/reducer/changePasswordReducer'
import changePasswordSaga from './container/DashboardPage/saga/changePasswordSaga'

import installSaga from './container/Transaction/InstallationPage/saga/'
import { default as installReducer } from './container/Transaction/InstallationPage/reducer/installReportReducer'
import { default as installCReducer } from './container/Transaction/InstallationPage/reducer/installCounterReportReducer'

import ticketingSaga from './container/Transaction/TicketingPage/saga/'
import { default as ticketReducer } from './container/Transaction/TicketingPage/reducer/ticketReportReducer'
import { default as ticketCReducer } from './container/Transaction/TicketingPage/reducer/ticketCounterReportReducer'

import roleSaga from './container/Transaction/RoleListPage/saga/saga'
import { default as roleTableReducer } from './container/Transaction/RoleListPage/reducer/roleTableReducer'

// settings
import settingsSaga from './container/Settings/saga/index'
import { default as settingsReducer } from './container/Settings/reducer/settingsReducer'

// import to collection from third party
import collectionSaga from './container/DashboardPage/saga/collectionSaga'
import collectionReducer from './container/DashboardPage/reducer/collectionReducer'

import cardTrackerBidaSaga from './container/Transaction/CardTrackerPageBida/saga/saga'
import turnInListBidaReducer from './container/Transaction/CardTrackerPageBida/reducer/TurnListReducer'
import updateTurnInListBidaReducer from './container/Transaction/CardTrackerPageBida/reducer/updateTurnInListReducer'
import cardGraphCountersBidaReducer from './container/Transaction/CardTrackerPageBida/reducer/cardGraphCountersReducer'
import locGraphCountersBidaReducer from './container/Transaction/CardTrackerPageBida/reducer/locationGraphCountersReducer'

import cmsReducer from './container/CMSPage/reducer/cmsReducer'
import cmsSaga from './container/CMSPage/saga/'

// create saga middleware
const sagaMiddleware = CreateSagaMiddleware()
// define middlewares
const middlewares = [sagaMiddleware]

// create store from reducers and middlewares
const store = configureStore({
  reducer: {
    salesSummaryGraphReducer,
    ticketSummaryGraphReducer,
    totalJOReducer,
    totalTicketReducer,
    filterSummaryReducer,
    filterReducer,
    sidebarReducer,
    portPerLocationDataReducer,
    portCardReducer,
    counterReducer,
    salesSummaryReducer,
    targetSettingsReducer,
    recentActivityListReducer,
    netPromoterScoreReducer,
    ticketSummaryReducer,
    clientsListReducer,
    portLocationReducer,
    portLocationDataReducer,
    portLocationGraphReducer,
    portFilterReducer,
    portCityReducer,
    filterSalesReducer,
    filterTicketReducer,
    filterSalesDashboardReducer,
    farthestActivationPortReducer,
    salesPortUtilizationReducer,
    activationCountReducer,
    salesCountDescReducer,
    salesPortPerMunReducer,
    turnInListReducer,
    updateTurnInListReducer,
    loginReducer,
    cardGraphCountersReducer,
    employeeReducer,
    authHandlerReducer,
    locGraphCountersReducer,
    modelObjDevReducer,
    callLogsReducer,
    countersCallLogsReducer,
    changePasswordReducer,
    installReducer,
    installCReducer,
    settingsReducer,
    // roleTableReducer
    collectionReducer,
    turnInListBidaReducer,
    updateTurnInListBidaReducer,
    cardGraphCountersBidaReducer,
    locGraphCountersBidaReducer,
    ticketReducer,
    ticketCReducer,
    cmsReducer
  },
  middleware: middlewares,
  // preloadedState: initialState,
})

// run middleware

sagaMiddleware.run(dashboardSaga)
sagaMiddleware.run(targetSettingsSaga)
sagaMiddleware.run(portCardSaga)
sagaMiddleware.run(clientSaga)
sagaMiddleware.run(summaryGraphSaga)
sagaMiddleware.run(portSaga)
sagaMiddleware.run(salesSaga)
sagaMiddleware.run(cardTrackerSaga)
sagaMiddleware.run(loginSaga)
sagaMiddleware.run(authSaga)
sagaMiddleware.run(employeeSaga)
sagaMiddleware.run(modelSaga)
sagaMiddleware.run(callLogsSaga)
sagaMiddleware.run(changePasswordSaga)
sagaMiddleware.run(installSaga)
sagaMiddleware.run(settingsSaga)
// sagaMiddleware.run(roleSaga)
sagaMiddleware.run(collectionSaga)
sagaMiddleware.run(cardTrackerBidaSaga)
sagaMiddleware.run(ticketingSaga)
sagaMiddleware.run(cmsSaga)
// const store = createStore(changeState)

export default store
