import { createSlice } from "@reduxjs/toolkit";

const portGraphSlice = createSlice({
    name: 'graph',
    initialState: {
        isLoading: false,
        data:{
            utilized:{
                dates:[],
                values:[],
              
            },
            reserved:{
                dates:[],
                values:[],
            }
          }
    },
    reducers: {
        getLocationGraph: (state) => {
            state.isLoading = true
        },
        getLocationGraphSuccess: (state, payload) => {
            state.isLoading = false
            state.data = payload.payload
        }
    }
})

export const { getLocationGraph, getLocationGraphSuccess } = portGraphSlice.actions
export default portGraphSlice.reducer
