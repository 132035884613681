import { createSlice } from "@reduxjs/toolkit";

const TurnInListSlice = createSlice({
    name: 'turnInList',
    initialState: {
        params: {
            filter: {
                search: null,
                agentId: null,
                statList: [],
                planType: 'All'
            }
        },
        list: {
            isLoading: false,
            data: []
        },
        address: {
            params: {
                location: ''
            },
            isLoading: false,
            data: [],
            municipality: []
        },
        details: {
            isLoading: false,
            data: {
                //actual column name from documents collection
                ID: '',
                NAME: '',
                FIRSTNAME: '',
                MIDDLENAME: '',
                LASTNAME: '',
                STREET: '',
                BARANGAY: '',
                MUNICIPALITY: '',
                PROVINCE: '',
                CONTACTNO: '',
                EMAIL: '',
                EXISTINGISP: '',
                STATUS: '',
                ENGAGEDDATE: '',
                EOCDATE: '',
                REMARKS: '',
                PLANTYPE: '',
                TYPE: '',
                DATETIMESTARTED: '',
                DATETIMEENDED: '',
                EMPLOYEEID: '',
                ADMIN_STATUS: '',
                ADMIN_REMARKS: '',
                ADMIN_NAME: '',
            },
            finder: {
                ISFUSS: true,
                data: {
                    list: [],
                    message: ''
                },
                signal: false
            }
        },
        employees: {
            isLoading: false,
            data: {
                totalCount: 0,
                list: []
            }
        },
        response: {
            message: '',
            status: ''
        },
        responseVerify: {
            value: '',
            message: '',
            status: ''
        },
        file_export: {
            isLoading: false,
            message: '',
            status: '',
            url: ''
        }
    },
    reducers: {
        getTurnInList: (state) => {
            state.list.isLoading = true
        },
        getTurnInListSuccess: (state, payload) => {
            state.list.isLoading = false
            state.list.data = payload.payload.res.data
        },
        updateTurnInList: (state) => {
            state.details.isLoading = true
        },
        updateTurnInListSuccess: (state, payload) => {
            const { data } = payload.payload.res
            if (Array.isArray(data) && typeof data !== 'undefined') {
                state.details.finder.data.list = payload.payload.res.data
                state.details.finder.data.message = payload.payload.res.message
                state.details.finder.signal = true
            } else {
                state.response = payload.payload.res
            }

            state.details.isLoading = false
        },
        updateEachTurnInList: (state) => {
            state.details.isLoading = true
        },
        updateEachTurnInListSuccess: (state, payload) => {
            state.details.isLoading = false
            state.response = payload.payload.res
        },
        getTurnInAddress: (state) => {
            state.address.isLoading = true
        },
        getTurnInAddressSuccess: (state, payload) => {
            state.address.isLoading = false
            state.address.data = payload.payload.res.data
        },
        getTurnInDetails: (state) => {
            state.details.isLoading = true
        },
        getTurnInDetailsSuccess: (state, payload) => {
            state.details.isLoading = false
            const {
                _id,
                NAME,
                FIRSTNAME,
                MIDDLENAME,
                LASTNAME,
                COMPLETEADDRESS,
                STREET,
                BARANGAY,
                MUNICIPALITY,
                PROVINCE,
                CONTACTNO,
                EMAIL,
                ENGAGEDDATE,
                EOCDATE,
                EXISTINGISP,
                REMARKS,
                STATUS,
                PLANTYPE,
                TYPE,
                DATETIMESTARTED,
                DATETIMEENDED,
                EMPLOYEEID,
                ADMIN_STATUS,
                ADMIN_REMARKS,
                ADMIN_NAME
            } = payload?.payload?.res?.result[0]

            // const address = COMPLETEADDRESS.split(',')

            state.details.data.ID = _id
            state.details.data.NAME = NAME
            state.details.data.FIRSTNAME = FIRSTNAME
            state.details.data.MIDDLENAME = MIDDLENAME
            state.details.data.LASTNAME = LASTNAME
            state.details.data.STREET = STREET
            state.details.data.BARANGAY = BARANGAY
            state.details.data.MUNICIPALITY = MUNICIPALITY
            state.details.data.PROVINCE = PROVINCE
            state.details.data.CONTACTNO = CONTACTNO
            state.details.data.EMAIL = EMAIL
            state.details.data.ENGAGEDDATE = ENGAGEDDATE
            state.details.data.EOCDATE = EOCDATE
            state.details.data.EXISTINGISP = EXISTINGISP
            state.details.data.STATUS = STATUS
            state.details.data.TYPE = TYPE
            state.details.data.REMARKS = REMARKS

            state.details.data.PLANTYPE = PLANTYPE
            state.details.data.DATETIMESTARTED = DATETIMESTARTED
            state.details.data.DATETIMEENDED = DATETIMEENDED
            state.details.data.EMPLOYEEID = EMPLOYEEID
            state.details.data.ADMIN_STATUS = ADMIN_STATUS
            state.details.data.ADMIN_REMARKS = ADMIN_REMARKS
            state.details.data.ADMIN_NAME = ADMIN_NAME

            state.address.params.location = MUNICIPALITY?.toLowerCase()
        },
        getEmployeesTurnList: (state) => {
            state.employees.isLoading = true
        },
        getEmployeesTurnListSuccess: (state, payload) => {
            state.employees.isLoading = false
            state.employees.data.list = payload.payload.res.list
        },
        setField: (state, payload) => {
            state.details.isLoading = false
            const target = payload.payload.field

            switch (target) {
                case 'ISFUSS':
                    state.details.finder[payload.payload.field] = payload.payload.value
                    break;
                case 'CONFIRM':
                    state.details.finder.signal = payload.payload.value
                    break;
                default:
                    state.details.data[payload.payload.field] = payload.payload.value
                    break;
            }
        },
        setCurDate: (state) => {
            state.details.isLoading = true
        },
        setDispose: (state, payload) => {
            state.response = payload.payload
        },
        setFilter: (state, payload) => {
            state.params = payload.payload
        },
        exportTracker: (state) => {
            state.file_export.isLoading = true
        },
        exportTrackerSuccess: (state, payload) => {
            state.file_export.isLoading = false
            state.file_export.url = payload.payload.res.url
            state.file_export.status = payload.payload.res.status
        },
        updateVerifyStatus: (state) => {
            state.list.isLoading = true
        },
        updateVerifyStatusSuccess: (state, payload) => {
            state.list.isLoading = true
            state.responseVerify = payload.payload.res
        },
        setDisponseVerify: (state, payload) => {
            state.responseVerify = payload.payload
        },
        fetchMunicipality: (state) => {
            state.address.isLoading = true
        },
        fetchMunicipalitySuccess: (state, action) => {
            state.address.isLoading = false
            state.address.municipality = action.payload.res.data
        }
    }
})
export const {
    getTurnInList,
    getTurnInListSuccess,
    updateTurnInList,
    updateTurnInListSuccess,
    getTurnInAddress,
    getTurnInAddressSuccess,
    setDispose,
    getTurnInDetails,
    getTurnInDetailsSuccess,
    setFilter,
    setField,
    setCurDate,
    updateEachTurnInList,
    updateEachTurnInListSuccess,
    getEmployeesTurnList,
    getEmployeesTurnListSuccess,
    exportTracker,
    exportTrackerSuccess,
    updateVerifyStatus,
    updateVerifyStatusSuccess,
    setDisponseVerify,
    fetchMunicipality,
    fetchMunicipalitySuccess
} = TurnInListSlice.actions

export default TurnInListSlice.reducer