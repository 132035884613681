import { createSlice } from "@reduxjs/toolkit";

const portCitySlice = createSlice({
    name: 'portCity',
    initialState: {
        isLoading: false,
        data: {
            utilized: [],
            deployed: [],
            targets: []
        },
        importing: {
            isImporting: false,
            message: '',
            reload: false
        },
        isSettingPort: false
    },
    reducers: {
        getPortCity: (state) => {
            state.isLoading = true
        },
        getPortCitySuccess: (state, payload) => {
            state.isLoading = false
            state.data = payload.payload.data
        },
        importDeployedPorts: (state) => {
            state.importing.isImporting = true;
        },
        importSuccess: (state, payload) => {
            state.importing.isImporting = false;
            state.importing.message = payload.payload.message
            state.importing.reload = true
        },
        setPort: (state, payload) => {
            state.isSettingPort = true;
        },
        setPortSuccess: (state, payload) => {
            state.isSettingPort = false;
            state.importing.message = payload.payload.message
            state.importing.reload = true
        },
        setPortError: (state, payload) => {
            state.isSettingPort = false;
            state.importing.message = payload.payload.message
        }
    }
})

export const { getPortCity, getPortCitySuccess, importDeployedPorts, importSuccess, setPort, setPortSuccess, setPortError } = portCitySlice.actions
export default portCitySlice.reducer