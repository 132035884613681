import { createSlice } from '@reduxjs/toolkit'
import { datePresets } from '../helper'

const salesSummarySlice = createSlice({
  name: 'salesSummary',
  initialState: {
    isLoading: false,
    params: {
      dateRange: datePresets[2].value,
      filtertype: 'Month',
    },
    data: {
      turnins: {
        dates: [],
        values: [],
        total: 0
      },
      installed: {
        dates: [],
        values: [],
        total: 0
      },
      target: 0
    }
  },
  reducers: {
    getSalesSummary(state) {
      state.isLoading = true;
    },
    getSalesSummarySuccess(state, payload) {
      state.isLoading = false;
      state.data = { ...state.data, ...payload.payload };
    },
    setParams(state, payload) {
      state.params = payload.payload;
    }

  }
})

export const { getSalesSummary, getSalesSummarySuccess, setParams } = salesSummarySlice.actions
export default salesSummarySlice.reducer