import LocalStorage from './LocalStorage';

export function AuthHeader() {
  // return authorization header with jwt token
  const token = JSON.parse(
    JSON.stringify(LocalStorage.readLocalStorage('token')),
  );

  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}
