import { createSlice } from '@reduxjs/toolkit'
import { datePresets } from '../helper'

const ticketSummarySlice = createSlice({
  name: 'ticketSummary',
  initialState: {
    isLoading: false,
    params: {
      dateRange: datePresets[2].value,
      filtertype: 'Month',
    },
    data: {
      recieved: {
        dates: [],
        values: [],
        total: 0
      },
      resolved: {
        dates: [],
        values: [],
        total: 0
      },
      target: 0
    }
  },
  reducers: {
    getTicketsSummary(state) {
      state.isLoading = true;
    },
    getTicketsSummarySuccess(state, payload) {
      state.isLoading = false;
      state.data = { ...state.data, ...payload.payload }
    },
    setParams(state, payload) {
      state.params = payload.payload;
    }
  }
})

export const { getTicketsSummary, getTicketsSummarySuccess, setParams } = ticketSummarySlice.actions
export default ticketSummarySlice.reducer