import moment from "moment";
import { startOfDay, endOfDay, subDays } from 'date-fns';

export const handleChangeFilter1 = (param, arr) => {
  let data = []
  switch (param) {
    case "Day":
      arr.map((obj) => (
        data.push(obj.split(' ')[0] + ' ' + obj.split(' ')[1])
      ))
      break;
    case "Month":
      arr.map((obj) => (
        data.push(obj.split(' ')[0] + ' ' + obj.split(' ')[3])
      ))
      break;
    case "Week":
      arr.forEach((obj) => {
        const dt = moment(obj, "MMMM DD dddd YYYY hh:m:ss A");
        data.push('Week ' + dt.week() + ', ' + dt.format('YYYY'))
      })
      break;
    case "Quarter":
      let month = []
      arr.map((obj) => month.push(obj.split(' ')[0] + ' ' + obj.split(' ')[3]))
      let filtMonthByQuart = [...new Set(month)]
      filtMonthByQuart?.filter(obj => {
        if (obj.includes("January")) { data.push((obj + "-Q1").split(' ')[1]) }
        else if (obj.includes("Febraury")) { data.push((obj + "-Q1").split(' ')[1]) }
        else if (obj.includes("March")) { data.push((obj + "-Q1").split(' ')[1]) }
        else if (obj.includes("April")) { data.push((obj + "-Q2").split(' ')[1]) }
        else if (obj.includes("May")) { data.push((obj + "-Q2").split(' ')[1]) }
        else if (obj.includes("June")) { data.push((obj + "-Q2").split(' ')[1]) }
        else if (obj.includes("July")) { data.push((obj + "-Q3").split(' ')[1]) }
        else if (obj.includes("August")) { data.push((obj + "-Q3").split(' ')[1]) }
        else if (obj.includes("September")) { data.push((obj + "-Q3").split(' ')[1]) }
        else if (obj.includes("October")) { data.push((obj + "-Q4").split(' ')[1]) }
        else if (obj.includes("November")) { data.push((obj + "-Q4").split(' ')[1]) }
        else if (obj.includes("December")) { data.push((obj + "-Q4").split(' ')[1]) }
      });
      break;
    case "Annual":
      arr.map((obj) => (
        data.push(obj.split(' ')[3])
      ))
      break;
    default:
      console.log("Error")
      break;
  }
  return [...new Set(data)] || []
}

export const getDaysArray = (s, e) => { for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) { const temp = new Date(d); a.push(moment(temp, "MM/DD/YYYY").format("MMMM DD dddd YYYY hh:m:ss A")); } return a; };

export const dateRangeParam = (e) => {
  const d = e.split('-')
  const start = moment(d[0], "MM/DD/YYYY").format("YYYY/MM/DD")
  const end = moment(d[1], "MM/DD/YYYY").format("YYYY/MM/DD")
  return getDaysArray(start, end)
}

export const getDaysbyDates = (dates) => {
  const start = moment(dates[0], "MMMM DD dddd YYYY hh:m:ss A").format("YYYY/MM/DD")
  const end = moment(dates[1], "MMMM DD dddd YYYY hh:m:ss A").format("YYYY/MM/DD")
  return getDaysArray(start, end)
}

export const alignDataWithDatesFilter = (datadates, data, dates, filter) => {
  let newData = new Array(dates.length)
  newData.fill(0)
  switch (filter.toLowerCase()) {
    case "day":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(moment([obj.year, obj.month, obj.day].join('-')).format("MMMM DD"))
        if (pos > -1) {
          newData[pos] = data[index]
        }
      })
      break;
    case "week":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf('Week ' + obj.week + ", " + obj.year)
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    case "month":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(moment([obj.year, obj.month].join('-')).format("MMMM YYYY"))
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    case "quarter":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(obj.year + '-Q' + obj.quarter)
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    case "annual":
      datadates.forEach((obj, index) => {
        let pos = dates.indexOf(obj.year.toString())
        if (pos > -1) {
          newData[pos] = data[index]
        }
      }
      )
      break;
    default:
      console.error(' filter type not found')
      break;
  }
  return newData
}

// date formatter
export const dateFormatter = (date, format, fromFormat = "MMMM DD dddd YYYY hh:m:ss A") => {
  return moment(date, "MMMM DD dddd YYYY hh:m:ss A").format(format)
}
// get date range string from start and end date
export const getDateRangeString = (start, end) => {
  // e.toLocaleString().split(',')[0]+'-'+e.toLocaleString().split(',')[2]
}
export const datePresets = [
  {
    label: 'Today',
    value: [startOfDay(new Date()), endOfDay(new Date())]
  },
  {
    label: 'Last 7 Days',
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
  },
  {
    label: 'This Month',
    value: [moment().startOf('month')["_d"], startOfDay(new Date()),]
  },
  {
    label: 'This Year',
    value: [startOfDay(new Date(new Date().getFullYear(), 0, 1)), endOfDay(new Date())]
  },

]
export const getGraphLabels = (dateRange, filter) => handleChangeFilter1(filter, getDaysbyDates(dateRange))

