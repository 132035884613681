import { createSlice } from "@reduxjs/toolkit";

const ticketSummarySlice = createSlice({
    name: 'ticketSummaryGraph',
    initialState: {
        isLoading: false,
        data: {
            dates:[],
            values:[],
            total:[],
        },
    },
    reducers: {
        getResolvedSLA: (state) => {
            state.isLoading = true;
        },
        getResolvedSLASuccess: (state, payload) => {
            state.isLoading = false;
            state.data = {...state.data, ...payload.payload.data};
        }
    }
})

export const { getResolvedSLA, getResolvedSLASuccess } = ticketSummarySlice.actions
export default ticketSummarySlice.reducer

