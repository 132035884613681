import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

const callLogsSlice = createSlice({
    name: 'callLogsCounter',
    initialState: {
        filter: {
            dateRange: datePresets[2].value,
            fiber: 'fiberx'
        },
        graph: {
            purpose: {
                isLoading: false,
                data: [],
                value: []
            },
            logsCounter: {
                isLoading: false,
                data: {
                    inbound: 0,
                    outbound: 0,
                    smm: 0
                }
            },
            location: {
                filtered: {
                    location: 'city of calapan'
                },
                isLoading: false,
                values: {
                    locations: [],
                    inbounds: [],
                    outbound: []
                }
            }
        },
        list: {
            inbound: {
                isLoading: false,
                data: []
            },
            outbound: {
                isLoading: false,
                data: []
            },
            callcard: {
                isLoading: false,
                data: []
            }
        }

    },
    reducers: {
        setFilterMain: (state, action) => {
            state.filter = action.payload
        },
        setFilterLocation: (state, action) => {
            state.graph.location.filtered = { ...action.payload }
        },
        getCounterPurpose: (state) => {
            state.graph.purpose.isLoading = true
        },
        getCounterPurposeSuccess: (state, payload) => {
            state.graph.purpose.isLoading = false
            state.graph.purpose.data = payload.payload.res.labels
            state.graph.purpose.value = payload.payload.res.values
        },
        getCounterLogsCall: (state) => {
            state.graph.logsCounter.isLoading = true
        },
        getCounterLogsCallSuccess: (state, payload) => {
            state.graph.logsCounter.isLoading = false
            state.graph.logsCounter.data = { ...payload.payload.res.newCounter }
        },
        getCounterLogList: (state) => {
            state.list.inbound.isLoading = true
            state.list.outbound.isLoading = true
            state.list.callcard.isLoading = true
        },
        getCounterLogListSuccess: (state, payload) => {
            if (payload.payload.inbound) {
                state.list.inbound.isLoading = false
                state.list.inbound.data = payload.payload.inbound
            }
            if (payload.payload.outbound) {
                state.list.outbound.isLoading = false
                state.list.outbound.data = payload.payload.outbound
            }
            if (payload.payload.call_card) {
                state.list.callcard.isLoading = false
                state.list.callcard.data = payload.payload.call_card
            }
        },
        getCounterPerLocation: (state) => {
            state.graph.location.isLoading = true
        },
        getCounterPerLocationSuccess: (state, action) => {
            state.graph.location.isLoading = false
            state.graph.location.values = { ...action.payload.res }
        },
    }
})

export const {
    setFilterMain,
    setFilterLocation,
    getCounterPurpose,
    getCounterPurposeSuccess,
    getCounterLogList,
    getCounterLogListSuccess,
    getCounterLogsCall,
    getCounterLogsCallSuccess,
    getCounterPerLocation,
    getCounterPerLocationSuccess
} = callLogsSlice.actions
export default callLogsSlice.reducer