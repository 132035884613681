import { createSlice } from "@reduxjs/toolkit";

const portDataSlice = createSlice({
    name: 'salesPortData',
    initialState: {
        isLoading: false,
        filter: {
            location: 'city of calapan'
        },
        values: {
            city: [],
            utilized: []
        }
    },
    reducers: {
        getPortUtilization: (state) => {
            state.isLoading = true
        },
        getPortUtilizationSuccess: (state, payload) => {
            state.isLoading = false
            state.values.city = payload.payload.data.city
            state.values.utilized = payload.payload.data.utilized

        },
        setFilter: (state, payload) => {
            state.filter = payload.payload
        }
    }
})
export const { getPortUtilization, getPortUtilizationSuccess, setFilter } = portDataSlice.actions
export default portDataSlice.reducer