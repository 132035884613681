import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

/* Creating a slice of the redux store. */
const portFilterSlice = createSlice({
    name: 'filter',
    initialState: {
        params: {
            location: 'city of calapan',
            dateRange: datePresets[2].value,
            filtertype: 'Day',
        }
    },
    reducers: {
        setParams: (state, payload) => {
            state.params = payload.payload
        } 
    }
})

export const { setParams, params } = portFilterSlice.actions
export default portFilterSlice.reducer