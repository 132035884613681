import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import { getHandleErrorVerify } from "src/container/DashboardPage/reducer/authHandlerReducer";
import { RequestOptions } from "src/helpers/RequestOptions";
import request from "src/utils/request";
import { addAssignInstallerSuccess, exportSLIReportSuccess, getEachInstallDetailsSuccess, getInstallerTeamsSuccess, getInstallListSuccess, getJOListSuccess, updateEachListSuccess } from "../reducer/installReportReducer";
import { formatDate } from "canvasjs-react-charts/canvasjs.min";
import { getMaterialsSuccess, getMaterialsUsedSuccess, getStatusCardGraphSuccess, getIssueCardGraphSuccess, getCardTotalCountersSuccess, updateCardTotalCountersSuccess, getEmployeeListDeptSuccess, getEachTeamsSuccess } from "../reducer/installCounterReportReducer";

const API_URL = process.env.REACT_APP_API_URL;


function* fetchInstallList() {

    const { type, schedule } = yield select((state) => state.installReducer.list.filter)
    const { dateRange, fiber } = yield select((state) => state.installCReducer.filter)
    const paramType = type === "All" || type === undefined ? "" : type

    let response = {}

    try {
        if (fiber === "fiberx") {
            response = yield call(request, `${API_URL}/sales/turnins/jo/list?type=${paramType}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&schedule=${schedule}`)
        } else if (fiber === "fiberbida") {
            response = yield call(request, `${API_URL}/sales/bida/turnins/jo/list?type=${paramType}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&schedule=${schedule}`)
        }

        yield put(getInstallListSuccess({
            res: {
                list: response.data
            }
        }))

    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response?.status
            }
        }))
    }

}

function* fetchEachInstallList(action) {
    const { cctid } = action.payload
    const { fiber } = yield select((state) => state.installCReducer.filter)

    let response = {}

    try {
        if (fiber === "fiberx") {
            response = yield call(request, `${API_URL}/sales/turnins/jo/list/${cctid}`)
        } else if (fiber === "fiberbida") {
            response = yield call(request, `${API_URL}/sales/bida/turnins/jo/list/${cctid}`)
        }

        yield put(getEachInstallDetailsSuccess({
            res: {
                data: response.data || []
            }
        }))
    } catch (error) {
        console.log(error.message)
    }
}

function* fetchJOList() {
    const response = yield call(request, `${API_URL}/ir/jos`)
    yield put(getJOListSuccess({
        res: {
            list: response.JOs
        }
    }))
}

function* fetchMaterialList() {
    const list = yield call(request, `${API_URL}/ir/materials`)
    yield put(getMaterialsSuccess({
        res: {
            list: list.materials
        }
    }))
}

function* fetchMaterialUsed() {
    const { dateRange, fiber } = yield select((state) => state.installCReducer.filter)
    
    let response = {}
    
    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/ir/materials/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/ir/bida/materials/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    }
    
    yield put(getMaterialsUsedSuccess({
        res: {
            data: response.matsUsed
        }
    }))
}

function* updateEachReport(action) {

    const field = action.payload
    const { fiber } = yield select((state) => state.installCReducer.filter)

    let UPLOADS = []
    let UPLOADSNAME = []
    let MATERIALSUSED = []

    let formData = new FormData()

    field.materials.map((el, idx) => {
        // formData.append("MATERIALSUSED", { MAT: el.item, QUANTITY: Number(el.qty) })
        MATERIALSUSED.push({ MAT: el.item, QUANTITY: Number(el.qty) })
    })

    field.upload.map((el) => {
        formData.append("UPLOADSNAME[]", el.desc)
        formData.append("UPLOADS[]", el.file)
    })

    formData.append("JONO", field.joNum)
    formData.append("DATETIMESTARTED", field.dateStart)
    formData.append("DATETIMEENDED", field.dateEnd)
    formData.append("STATUS", field.status)
    formData.append("ISSUE", field.issue)
    // formData.append("INSTALLATION_SCHEDULE", field.resched)
    formData.append("REMARKS", field.remarks)
    formData.append("ACCEPTEDBY", field.acceptBy)

    const fields = {
        JONO: field.joNum,
        DATETIMESTARTED: field.dateStart.replace('T', ' '),
        DATETIMEENDED: field.dateEnd.replace('T', ' '),
        STATUS: field.status,
        ISSUE: field.issue,
        // INSTALLATION_SCHEDULE: field.resched,
        ACCEPTEDBY: field.acceptBy,
        REMARKS: field.remarks,
        ACCEPTEDBY: field.acceptBy,
        MATERIALSUSED
    }

    let response = {}

    const requestBody = yield call(RequestOptions, 'PUT', fields)

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/ir/${field.cctid}`, requestBody)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/ir/bida/${field.cctid}`, requestBody)
    }

    yield put(updateEachListSuccess({
        res: {
            message: response.message,
            status: response.status
        }
    }))
}


function* fethInstallerTeams() {
    const response = yield call(request, `${API_URL}/ir/teams`)
    yield put(getInstallerTeamsSuccess({
        res: {
            data: response
        }
    }))
}

function* fetchStatusCardGraph() {
    const { dateRange, fiber } = yield select((state) => state.installCReducer.filter)

    let response = {}

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/ir/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&groupby=status`)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/ir/bida/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&groupby=status`)
    }

    let data = []
    let value = []

    response?.forEach((i) => {
        data.push(i.STATUS)
        value.push(i.count)
    })

    yield put(getStatusCardGraphSuccess({
        data,
        value
    }))
}

function* fetchIssueCardGraph() {
    const { dateRange, fiber } = yield select((state) => state.installCReducer.filter)

    let response = {}

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/ir/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&groupby=issue`)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/ir/bida/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&groupby=issue`)
    }
    
    let data = []
    let value = []

    response?.forEach((i) => {
        data.push(i.ISSUE)
        value.push(i.count)
    })

    yield put(getIssueCardGraphSuccess({
        data,
        value
    }))
}

function* fetchCardTotalCounters() {
    const { dateRange, fiber } = yield select((state) => state.installCReducer.filter)

    let actual = {}
    let kpi = {}

    if (fiber === 'fiberx') {
        actual = yield call(request, `${API_URL}/ir/totals?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    }
    else if (fiber === 'fiberbida') {
        actual = yield call(request, `${API_URL}/ir/bida/totals?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    }

    kpi = yield call(request, `${API_URL}/ir/kpi?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    const data = {
        total_jo_received: actual.data?.jo_created || 0,
        total_foc_installed: actual.data?.foc_installed,
        total_jo_completed: actual.data?.jo_completed,
        jo_percent_completion: actual.data?.jo_completion_percentage,
        average_installation_per_team: actual.data?.ave_installation_per_team,
        total_team: actual.data?.total_team,
        total_installers: actual.data?.total_installers,
        average_time_spent_per_installation: actual.data?.ave_time_per_installation,
        kpi_total_jo_received: kpi.totalJOReceived || 0,
        kpi_total_foc_installed: kpi.totalFOCInstalled,
        kpi_total_jo_completed: kpi.totalJOCompleted,
        kpi_jo_percent_completion: kpi.JOPercentCompletion,
        kpi_average_installation_per_team: kpi.averageInstallationPerTeam || 0,
        kpi_total_team: kpi.totalTeam || 0,
        kpi_total_installers: kpi.totalInstallers || 0,
        kpi_average_time_spent_per_team: kpi.averageTimePerInstallation || 0
    }

    yield put(getCardTotalCountersSuccess(data))
}

function* updateCardTotalCounters(payload) {
    const fields = {
        TOTALJORECIEVED: payload.payload.totalJOReceived,
        AVERAGEINSTALLATIONPERTEAM: payload.payload.averageInstallationPerTeam,
        TOTALTEAM: payload.payload.totalTeam,
        TOTALINSTALLERS: payload.payload.totalInstallers,
        AVERAGETIMESPENTPERINSTALLATION: payload.payload.averageTimeSpentPerInstallation,
        EFFECTIVEDATE: payload.payload.effectiveDate
    }

    try {
        const requestBody = yield call(RequestOptions, 'POST', fields)
        const response = yield call(request, `${API_URL}/ir/kpi`, requestBody)

        yield put(updateCardTotalCountersSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }
}


function* addAssignInstaller(action) {
    const { fields, id } = action.payload
    const { fiber } = yield select((state) => state.installCReducer.filter)

    let response = {}

    try {
        const requestBody = yield call(RequestOptions, 'POST', fields)

        if (fiber === "fiberx") {
            response = yield call(request, `${API_URL}/ir/assign-team/${id}`, requestBody)
        } else if (fiber === "fiberbida") {
            response = yield call(request, `${API_URL}/ir/bida/assign-team/${id}`, requestBody)
        }

        yield put(addAssignInstallerSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }
}

function* fetchEmployeeDept(action) {
    const param = action.payload
    const response = yield call(request, `${API_URL}/employee/byfilter?department=${param}`)
    const { count, employees } = response

    const teams = yield call(request, `${API_URL}/ir/teams`)

    const empList = employees.map((el) => {
        return { value: el._id, label: el.NAME }
    }) || []

    yield put(getEmployeeListDeptSuccess({
        res: {
            count,
            list: empList.length > 0 ? empList : [],
            teams
        }
    }))
}


function* addTeamDept(action) {

    try {
        const { name, member } = action.payload
        const newFields = {
            TEAM: name,
            MEMBERS: member
        }
        const requestBody = yield call(RequestOptions, 'POST', newFields)
        const response = yield call(request, `${API_URL}/ir/team`, requestBody)
        yield put(addAssignInstallerSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }
}

function* fetchEachTeams(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, `${API_URL}/ir/team/${id}`)
        const { MEMBERS, TEAM } = response
        const list = MEMBERS.map((el) => {
            return { value: el._id, label: el.NAME }
        })
        yield put(getEachTeamsSuccess({
            res: {
                list,
                name: TEAM
            }
        }))
    } catch (error) {
        console.log(error.response.status)
    }

}

function* exportSLIReport() {
    try {
        const { dateRange } = yield select((state) => state.installCReducer.filter)
        const response = yield call(request, `${API_URL}/report/SLIReport/export?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`,{},true)
        if(response?.status=='error')
        yield put(exportSLIReportSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
        else{
            var file = window.URL.createObjectURL(response);
            window.location.assign(file);
            
            yield put(exportSLIReportSuccess({}))
        }
    }
    catch (error) {
        console.log(error)
    }
}


function* installSaga() {
    yield takeLatest('install/getInstallList', fetchInstallList)
    yield takeLatest('install/getJOList', fetchJOList)
    yield takeLatest('installCounter/getMaterials', fetchMaterialList)
    yield takeEvery('install/updateEachList', updateEachReport)
    yield takeLatest('installCounter/getMaterialsUsed', fetchMaterialUsed)
    yield takeEvery('install/getEachInstallDetails', fetchEachInstallList)
    yield takeLatest('install/getInstallerTeams', fethInstallerTeams)
    yield takeEvery('installCounter/getStatusCardGraph', fetchStatusCardGraph)
    yield takeEvery('installCounter/getIssueCardGraph', fetchIssueCardGraph)
    yield takeEvery('installCounter/getCardTotalCounters', fetchCardTotalCounters)
    yield takeEvery('installCounter/updateCardTotalCounters', updateCardTotalCounters)
    yield takeEvery('install/addAssignInstaller', addAssignInstaller)
    yield takeLatest('installCounter/getEmployeeListDept', fetchEmployeeDept)
    yield takeEvery('installCounter/addTeams', addTeamDept)
    yield takeLatest('installCounter/getEachTeams', fetchEachTeams)
    yield takeLatest('install/exportSLIReport', exportSLIReport)
}

export default installSaga