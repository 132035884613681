import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

const cardGraphCounterSlice = createSlice({
    name: 'cardGraphCounter',
    initialState: {
        filter: {
            type: 'All',
            dateRange: datePresets[2].value,
            position: ''
        },
        graph: {
            status: {
                isLoading: false,
                data: [],
                values: []
            },
            isp: {
                isLoading: false,
                data: [],
                values: []
            },
            plan: {
                isLoading: false,
                data: [],
                values: []
            }
        },
        counters: {
            isLoading: false,
            data: {
                totalSalesAgent: 0,
                totalSalesAgentVisit: 0,
                totalClosedPost: 0,
                totalClosedPrep: 0,
                aveVSalesAgent: 0,
                aveTimeSaleVisit: 0,
                aveSuccessPerVisit: 0,
                totalClosedDeal: 0,
                kpiTotalSalesAgent: 0,
                kpiTotalSaleSagentVisit: 0,
                kpiTotalClosedDeal: 0,
                kpiAverageVisitsPerSalesAgent: 0,
                kpiAverageTimeSpentPerVisit: 0,
                kpiAverageSuccessRatePerVisits: 0
            },
            update: {
                field: {
                    TOTALSALESAGENT: '',
                    AVERAGEVISITSPERSALESAGENT: '',
                    AVERAGESUCCESSRATEPERVISITS: '',
                    EFFECTIVEDATE: ''
                },
                response: {
                    message: '',
                    status: '',
                    error: ''
                }
            }

        },
        agent: {
            isImporting:false,
            isLoading: false,
            clients: {
                list: [],
                status: '',
                average: {
                    visit: 0,
                    closed: 0
                }
            }
        },
        position: {
            isLoading: false,
            list: []
        }
    },
    reducers: {
        getStatusCardGraph: (state) => {
            state.graph.status.isLoading = true
            state.graph.isp.isLoading = true
        },
        getStatusCardGraphSuccess: (state, payload) => {
            state.graph.status.isLoading = false
            state.graph.isp.isLoading = false

            state.graph.status.data = payload.payload.res.status.dataA
            state.graph.status.values = payload.payload.res.status.valuesA

            state.graph.isp.data = payload.payload.res.isp.dataB
            state.graph.isp.values = payload.payload.res.isp.valuesB
        },
        getCardTotalCounters: (state) => {
            state.counters.isLoading = true
        },
        getCardTotalCountersSuccess: (state, payload) => {
            state.counters.isLoading = false
            const { average_success_rate_per_visits,
                average_time_spent_per_visit,
                average_visits_per_sales_agent,
                total_closed_postpaid,
                total_closed_prepaid,
                total_sales_agent,
                total_sales_agent_visit,
                total_closed_deal,
                kpi_total_sales_agent,
                kpi_total_sales_agent_visit,
                kpi_total_closed_deal,
                kpi_average_visits_per_sales_agent,
                kpi_average_time_spent_per_visit,
                kpi_average_success_rate_per_visits
            } = payload.payload.res.response
            state.counters.data.totalSalesAgent = total_sales_agent
            state.counters.data.totalSalesAgentVisit = total_sales_agent_visit
            state.counters.data.totalClosedPost = total_closed_postpaid
            state.counters.data.totalClosedPrep = total_closed_prepaid
            state.counters.data.aveVSalesAgent = average_visits_per_sales_agent
            state.counters.data.aveTimeSaleVisit = average_time_spent_per_visit
            state.counters.data.aveSuccessPerVisit = average_success_rate_per_visits
            state.counters.data.totalClosedDeal = total_closed_deal
            state.counters.data.kpiTotalSalesAgent = kpi_total_sales_agent
            state.counters.data.kpiTotalSaleSagentVisit = kpi_total_sales_agent_visit
            state.counters.data.kpiTotalClosedDeal = kpi_total_closed_deal
            state.counters.data.kpiAverageVisitsPerSalesAgent = kpi_average_visits_per_sales_agent
            state.counters.data.kpiAverageTimeSpentPerVisit = kpi_average_time_spent_per_visit
            state.counters.data.kpiAverageSuccessRatePerVisits = kpi_average_success_rate_per_visits

            state.counters.update.field.TOTALSALESAGENT = kpi_total_sales_agent
            state.counters.update.field.AVERAGEVISITSPERSALESAGENT = kpi_total_sales_agent_visit
            state.counters.update.field.AVERAGESUCCESSRATEPERVISITS = kpi_average_success_rate_per_visits

        },
        updateCardTotalCounters: (state) => {
            state.counters.isLoading = true
        },
        updateCardTotalCountersSuccess: (state, payload) => {
            state.counters.isLoading = false
            state.counters.update.response.message = payload.payload.res.message
            state.counters.update.response.status = payload.payload.res.status
            state.counters.update.response.error = payload.payload?.res?.error
        },
        setDispose: (state, payload) => {
            state.counters.update.response = payload.payload
        },
        getCardPlanTypes: (state) => {
            state.graph.plan.isLoading = true
        },
        getCardPlanTypesSuccess: (state, payload) => {
            state.graph.plan.isLoading = false
            state.graph.plan.data = payload.payload.res.plan.labels
            state.graph.plan.values = payload.payload.res.plan.values
        },
        getAgentCounterClients: (state) => {
            state.agent.isLoading = true
        },
        getAgentCounterClientsSuccess: (state, payload) => {
            state.agent.isLoading = false
            state.agent.clients.list = payload.payload.res.list
            state.agent.clients.status = payload.payload.res.status
            state.agent.clients.average = { ...payload.payload.res.average }
        },
        setFilterMain: (state, payload) => {
            state.filter = payload.payload
        },
        exportAgentCounterClients: (state) => {
            state.agent.isExporting = true
        },
        exportAgentCounterClientsSuccess: (state,payload) => {
            state.agent.isExporting = false
            if(payload.payload.res){
                state.counters.update.response.message = payload.payload.res.message
                state.counters.update.response.status = payload.payload.res.status
                state.counters.update.response.error = payload.payload?.res?.error
            }
        },
        getPositions: (state) => {
            state.position.isLoading = true
        },
        getPositionsSuccess: (state, payload) => {
            state.position.isLoading = false
            state.position.list = payload.payload.res.positions
        }
    }
})

export const {
    setFilterMain,
    getStatusCardGraph,
    getStatusCardGraphSuccess,
    getCardTotalCounters,
    getCardTotalCountersSuccess,
    getCardPlanTypes,
    getCardPlanTypesSuccess,
    updateCardTotalCounters,
    updateCardTotalCountersSuccess,
    setDispose,
    getAgentCounterClients,
    getAgentCounterClientsSuccess,
    exportAgentCounterClients,
    exportAgentCounterClientsSuccess,
    getPositions,
    getPositionsSuccess
} = cardGraphCounterSlice.actions
export default cardGraphCounterSlice.reducer
