import { call, put, takeEvery, takeLatest }  from "redux-saga/effects";
import { RequestOptions } from "src/helpers/RequestOptions";
import request from "src/utils/request";
import { fileUploadSuccess, getArticleSuccess, getCategorySuccess, getFileUploadSuccess, makeArticleSuccess, manageCategorySuccess, removeArticleSuccess } from "../reducer/cmsReducer";

const API_URL = process.env.REACT_APP_API_URL;

function* requestArticle(action){
    const {  fields, params, req } = action.payload

    const obj = {
        TITLE: fields.title,
        CATEGORYID: fields.category,
        BODY: fields.content
    }

    let response, requestBody = {}
    switch (req) {
        case 'add':
            requestBody = yield call(RequestOptions, 'POST', obj)
            response = yield call(request, `${API_URL}/cms/admin/articles/addArticles`, requestBody)
        
            break;
        case 'edit':
            const {  id } = params
            if(id.trim().length === 0) return;

            requestBody = yield call(RequestOptions, 'POST', obj)
            response = yield call(request, `${API_URL}/cms/admin/articles/updateArticles/${id}`, requestBody)
            break;
        default:
            break;
    }
    yield put(makeArticleSuccess({
        res: {
            message: response.message,
            status: response.status
        }
    }))
}

function* fetchArticles(action){
    const { params, req } = action.payload
    let response = {}
    switch (req) {
        case 'all':
            response = yield call(request, `${API_URL}/cms/articles/getArticles`)
            break;
        case 'each':
            const { id } = params
            if(id.trim().length === 0) return;
            response = yield call(request, `${API_URL}/cms/articles/getArticles?id=${id}`)
            break;
        default:
            break;
    }
    yield put(getArticleSuccess({
        res: {
            req,
            list: response.data
        }
    }))

}


function* requestEntryCategory(action){
    const {  fields, params, req } = action.payload
    let response = {}
    let formData = new FormData()
    formData.append('NAME', fields.name)
    formData.append('DESCRIPTION', fields.desc)
    formData.append('file', fields.logo)
    switch (req) {
        case 'add':
            response = yield call(request, `${API_URL}/cms/admin/articles/addCategory`, {
                method: 'POST',
                body: formData,
            });
        break;
        case 'edit':
            const { value } = params.edit
            response = yield call(request, `${API_URL}/cms/admin/articles/updateCategory/${value}`, {
                method: 'POST',
                body: formData,
            });
            break;    
        default:
            break;
    }
    yield put(manageCategorySuccess({
        res: {
            message: response.message,
            status: response.status
        }
    }))

    
}

function* requestGetCategory(action){
    const { req, params } = action.payload
    let response = {}
    switch (req) {
        case 'all':
                response = yield call(request, `${API_URL}/cms/articles/getCategory`)
            break;
        case 'details':
                const { id } = params
                response = yield call(request, `${API_URL}/cms/articles/getCategory/${id}`)
            break;
        default:
            break;
    }
    
    yield put(getCategorySuccess({
        res: {
            req,
            list: response.data
        }
    }))
    
}

function* uploadFileRequest({ payload }){
    const { target, file, req, params } = payload
    let response, requestBody = {}
    switch (target) {
        case 'banner':
            if(req==='add'){
                let formData = new FormData()
                formData.append('file', file)
                response = yield call(request, `${API_URL}/cms/admin/banner/uploadBanner`, {
                    method: 'POST',
                    body: formData,
                });
            }else if(req==='remove'){
                const {  id } = params
                if(id.trim().length === 0) return;

                const obj = {
                    _id: id
                }
                
                requestBody = yield call(RequestOptions, 'DELETE', obj)
                response = yield call(request, `${API_URL}/cms/admin/banner/deleteBanner/${id}`, requestBody)
            }
            break;
    
        default:
            break;
    }

    yield put(fileUploadSuccess({
        target,
        res: {
            status: response.status,
            message: response.message
        }
    }))
}

function* requestGetFilesBanner({ payload }) {
    const { req } = payload
    let response = {}
    switch (req) {
        case 'all':
            response = yield call(request, `${API_URL}/cms/banner/getBanner`)
            break;
        default:
            break;
    }

    yield put(getFileUploadSuccess({
        res: {
            list: response.data
        }
    }))
    
}

function* removeArticle(action) {
    const { id } = action.payload.params
    try {
        if(id.trim().length === 0)return;
        const obj = {
            _id: id
        }
        const reqBody = yield call(RequestOptions, 'DELETE', obj)
        const response = yield call(request, `${API_URL}/cms/admin/articles/deleteArticle/${id}`, reqBody)
        yield put(removeArticleSuccess({
            res: {
                status: response.status,
                message: response.message
            }
        }))
    } catch (error) {
        yield put(removeArticleSuccess({
            res: {
                status: "Error",
                message: error
            }
        }))
    }
}


function* cmsSaga(){
    yield takeEvery('cms/makeArticle', requestArticle)
    yield takeEvery('cms/manageCategory', requestEntryCategory)
    yield takeLatest('cms/getCategory', requestGetCategory)
    yield takeEvery('cms/getArticle', fetchArticles)
    yield takeEvery('cms/fileUpload', uploadFileRequest)
    yield takeLatest('cms/getFileUpload', requestGetFilesBanner)
    yield takeEvery('cms/removeArticle', removeArticle)
}


export default cmsSaga