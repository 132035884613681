import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import { getHandleErrorVerify } from "src/container/DashboardPage/reducer/authHandlerReducer";
import { RequestOptions } from "src/helpers/RequestOptions";
import request from "src/utils/request";
import { addAssignInstallerSuccess, exportSLIReportSuccess, getEachTicketDetailsSuccess, getInstallerTeamsSuccess, getTicketListSuccess, getJOListSuccess, updateEachListSuccess, getOutageSuccess } from "../reducer/ticketReportReducer";
import { formatDate } from "canvasjs-react-charts/canvasjs.min";
import { getMaterialsSuccess, getMaterialsUsedSuccess, getReasonOutageCardGraphSuccess, getIssueCardGraphSuccess, getCardTotalCountersSuccess, updateCardTotalCountersSuccess, getEmployeeListDeptSuccess, getEachTeamsSuccess } from "../reducer/ticketCounterReportReducer";

const API_URL = process.env.REACT_APP_API_URL;


function* fetchTicketList() {
    const { dateRange } = yield select((state) => state.ticketCReducer.filter)

    try {
        const response = yield call(request, `${API_URL}/tickets?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

        yield put(getTicketListSuccess({
            res: {
                list: response.data
            }
        }))

    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response?.status
            }
        }))
    }

}

function* fetchEachTicketList(action) {
    const { tID } = action.payload

    try {
        const response = yield call(request, `${API_URL}/tickets/${tID}`)
        yield put(getEachTicketDetailsSuccess({
            res: {
                data: response.data || []
            }
        }))
    } catch (error) {
        console.log(error.message)
    }
}

function* fetchJOList() {
    const response = yield call(request, `${API_URL}/ir/jos`)
    yield put(getJOListSuccess({
        res: {
            list: response.JOs
        }
    }))
}

function* fetchMaterialList() {
    const list = yield call(request, `${API_URL}/ir/materials`)
    yield put(getMaterialsSuccess({
        res: {
            list: list.materials
        }
    }))
}

function* fetchMaterialUsed() {
    const { dateRange } = yield select((state) => state.ticketCReducer.filter)

    const response = yield call(request, `${API_URL}/count/material?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    yield put(getMaterialsUsedSuccess({
        res: {
            data: response.data
        }
    }))
}

function* updateEachReport(action) {
    const field = action.payload

    let MATERIALSUSED = []
    let formData = new FormData()

    field.materials.map((el, idx) => {
        MATERIALSUSED.push({ MAT: el.item, QUANTITY: Number(el.qty) })
    })

    // field.upload.map((el) => {
    //     formData.append("UPLOADSNAME[]", el.desc)
    //     formData.append("UPLOADS[]", el.file)
    // })

    formData.append("TICKETNO", field.ticketId)
    formData.append("REASONOFOUTAGE", field.reasonOutage.replace("T", " "))
    formData.append("STATUS", field.status)
    formData.append("DATETIMERESOLVED", field.dateTimeRes)
    formData.append("REMARKS", field.remarks)
    formData.append("MATERIALSUSED", MATERIALSUSED)

    const fields = {
        TICKETNO: field.ticketId,
        REASONOFOUTAGE: field.reasonOutage.replace("T", " "),
        STATUS: field.status,
        DATETIMERESOLVED: field.dateTimeRes,
        REMARKS: field.remarks,
        MATERIALSUSED: MATERIALSUSED
    }

    const requestBody = yield call(RequestOptions, 'PUT', fields)

    const response = yield call(request, `${API_URL}/tickets/${field.cxid}/manage`, requestBody)

    yield put(updateEachListSuccess({
        res: {
            message: response.message,
            status: response.status
        }
    }))
}


function* fethInstallerTeams() {
    const response = yield call(request, `${API_URL}/ir/teams`)
    yield put(getInstallerTeamsSuccess({
        res: {
            data: response
        }
    }))
}

function* fetchReasonOutageCardGraph() {
    const { dateRange } = yield select((state) => state.ticketCReducer.filter)

    const response = yield call(request, `${API_URL}/settings/counts/outagereason?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    yield put(getReasonOutageCardGraphSuccess({
        data: response
    }))
}

function* fetchIssueCardGraph() {
    const { dateRange, fiber } = yield select((state) => state.installCReducer.filter)

    let response = {}

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/ir/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&groupby=issue`)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/ir/bida/counters?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&groupby=issue`)
    }

    let data = []
    let value = []

    response?.forEach((i) => {
        data.push(i.ISSUE)
        value.push(i.count)
    })

    yield put(getIssueCardGraphSuccess({
        data,
        value
    }))
}

function* fetchCardTotalCounters() {
    const { dateRange } = yield select((state) => state.ticketCReducer.filter)

    // let actual = {}
    // let kpi = {}

    // if (fiber === 'fiberx') {
    //     actual = yield call(request, `${API_URL}/ir/totals?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    // }
    // else if (fiber === 'fiberbida') {
    //     actual = yield call(request, `${API_URL}/ir/bida/totals?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    // }

    // kpi = yield call(request, `${API_URL}/ir/kpi?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    const response = yield call(request, `${API_URL}/tickets/totals?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    const data = {
        ticketResolved: response.data.tickets_resolved || 0,
        ticketsReceived: response.data.tickets_received || 0,
        averageTimeResolvedInternal: response.data.average_time_resolved_internal || 0,
        averageTimeResolvedConverge: response.data.average_time_resolved_converge || 0,
        totalTeam: response.data.total_team || 0,
        totalSLRMembers: response.data.total_slr_members || 0,
        ticketPercentageResolved: response.data.ticket_percentage_of_resolved || 0,
        averageRepairPerTeam: response.data.average_repair_per_team || 0
    }

    yield put(getCardTotalCountersSuccess(data))
}

function* updateCardTotalCounters(payload) {
    const fields = {
        TOTALJORECIEVED: payload.payload.totalJOReceived,
        AVERAGEINSTALLATIONPERTEAM: payload.payload.averageInstallationPerTeam,
        TOTALTEAM: payload.payload.totalTeam,
        TOTALINSTALLERS: payload.payload.totalInstallers,
        AVERAGETIMESPENTPERINSTALLATION: payload.payload.averageTimeSpentPerInstallation,
        EFFECTIVEDATE: payload.payload.effectiveDate
    }

    try {
        const requestBody = yield call(RequestOptions, 'POST', fields)
        const response = yield call(request, `${API_URL}/ir/kpi`, requestBody)

        yield put(updateCardTotalCountersSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }
}


function* addAssignInstaller(action) {
    const { fields, id } = action.payload
    try {
        const requestBody = yield call(RequestOptions, 'PUT', fields)
        const response = yield call(request, `${API_URL}/tickets/${id}/assign`, requestBody)


        yield put(addAssignInstallerSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }
}

function* fetchEmployeeDept(action) {
    const param = action.payload
    const response = yield call(request, `${API_URL}/employee/byfilter?department=${param}`)
    const { count, employees } = response

    const teams = yield call(request, `${API_URL}/ir/teams`)

    const empList = employees.map((el) => {
        return { value: el._id, label: el.NAME }
    }) || []

    yield put(getEmployeeListDeptSuccess({
        res: {
            count,
            list: empList.length > 0 ? empList : [],
            teams
        }
    }))
}


function* addTeamDept(action) {

    try {
        const { name, member } = action.payload
        const newFields = {
            TEAM: name,
            MEMBERS: member
        }
        const requestBody = yield call(RequestOptions, 'POST', newFields)
        const response = yield call(request, `${API_URL}/ir/team`, requestBody)
        yield put(addAssignInstallerSuccess({
            res: {
                message: response.message,
                status: response.status
            }
        }))
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }
}

function* fetchEachTeams(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, `${API_URL}/ir/team/${id}`)
        const { MEMBERS, TEAM } = response
        const list = MEMBERS.map((el) => {
            return { value: el._id, label: el.NAME }
        })
        yield put(getEachTeamsSuccess({
            res: {
                list,
                name: TEAM
            }
        }))
    } catch (error) {
        console.log(error.response.status)
    }

}

function* exportSLIReport() {
    try {
        const { dateRange } = yield select((state) => state.installCReducer.filter)
        const response = yield call(request, `${API_URL}/report/SLIReport/export?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`, {}, true)
        if (response?.status == 'error')
            yield put(exportSLIReportSuccess({
                res: {
                    message: response.message,
                    status: response.status
                }
            }))
        else {
            var file = window.URL.createObjectURL(response);
            window.location.assign(file);

            yield put(exportSLIReportSuccess({}))
        }
    }
    catch (error) {
        console.log(error)
    }
}

function* fetchOutageReason() {
    const response = yield call(request, `${API_URL}/settings/outagereason`)
    yield put(getOutageSuccess({
        res: {
            list: response
        }
    }))
}


function* ticketingSaga() {
    yield takeLatest('ticket/getTicketList', fetchTicketList)
    yield takeLatest('install/getJOList', fetchJOList)
    yield takeLatest('ticketCounter/getMaterials', fetchMaterialList)
    yield takeEvery('ticket/updateEachList', updateEachReport)
    yield takeLatest('ticketCounter/getMaterialsUsed', fetchMaterialUsed)
    yield takeEvery('ticket/getEachTicketDetails', fetchEachTicketList)
    yield takeLatest('ticket/getInstallerTeams', fethInstallerTeams)
    yield takeEvery('ticketCounter/getReasonOutageCardGraph', fetchReasonOutageCardGraph)
    yield takeEvery('installCounter/getIssueCardGraph', fetchIssueCardGraph)
    yield takeEvery('ticketCounter/getCardTotalCounters', fetchCardTotalCounters)
    yield takeEvery('installCounter/updateCardTotalCounters', updateCardTotalCounters)
    yield takeEvery('ticket/addAssignInstaller', addAssignInstaller)
    yield takeLatest('ticketCounter/getEmployeeListDept', fetchEmployeeDept)
    yield takeEvery('ticketCounter/addTeams', addTeamDept)
    yield takeLatest('ticketCounter/getEachTeams', fetchEachTeams)
    yield takeLatest('ticket/exportSLIReport', exportSLIReport)
    yield takeLatest('ticket/getOutage', fetchOutageReason)
}

export default ticketingSaga