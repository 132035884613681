import { AuthHeader } from './AuthHeader';

export function RequestOptions(Method, request = {}) {
  let requestOptions;
  const header = AuthHeader();
  if (Method === 'GET') {
    requestOptions = {
      method: Method,
      headers: {
        ...header,
        'Content-Type': 'application/json',
      },
    };
  } else {
    requestOptions = {
      method: Method,
      headers: {
        ...header,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    };
  }
  return requestOptions;
}

export function RequestOptionsForm(Method, request = {}) {
  let requestOptions;
  const header = AuthHeader();
  requestOptions = {
    method: Method,
    headers: {
      ...header,
      'Accept': '*/*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: request,
  };

  return requestOptions;
}
