import { createSlice } from '@reduxjs/toolkit'

const netPromoterScoreSlice = createSlice({
  name: 'netPromoterScore',
  initialState: {
    isLoading: false,
    data: {
      mgm: 0,
      erp: 0,
      others: 0,
    },
  },
  reducers: {
    getNetPromoterScore(state) {
      // set isLoading to true
      state.isLoading = true;
    },
    getNetPromoterScoreSuccess(state, payload) {
      // set isLoading to false
      state.isLoading = false;
      // set data
      state.data.mgm = payload.payload.mgm;
      state.data.erp = payload.payload.erp;
      state.data.others = payload.payload.others;
    },
  }
})

export const { getNetPromoterScore, getNetPromoterScoreSuccess } = netPromoterScoreSlice.actions
export default netPromoterScoreSlice.reducer