import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import { RequestOptions } from "src/helpers/RequestOptions";
import request from "src/utils/request";
import { getDataSuccess, getLocationSuccess, setNotif } from "../reducer/settingsReducer";
const API_URL = process.env.REACT_APP_API_URL;


function* fetchData(){
    const { activeTab } = yield select((state) => state.settingsReducer)
    if(activeTab==='province'||activeTab==='city'||activeTab==='barangay'){
        const response = yield call(request,`${API_URL}/locations`)
        let locations={
            province:response.filter(el=>el.TYPE==='province'),
            city:response.filter(el=>el.TYPE==='city'),
            barangay:response.filter(el=>el.TYPE==='barangay')
        }
        yield put(getDataSuccess({locations,type:'location'}))
    }
    if(activeTab==='installation issue'){
        const response = yield call(request,`${API_URL}/irissues`)
        yield put(getDataSuccess({issues:response,type:'issue'}))
    }
    
    if(activeTab==='installation materials'){
        const response = yield call(request,`${API_URL}/materials`)
        yield put(getDataSuccess({materials:response,type:'material'}))
    }
    
    if(activeTab==='inbound'||activeTab==='outbound'){
        const response = yield call(request,`${API_URL}/clpurpose`)
        let inbound=response.filter(el=>el.TYPE==='inbound')
        let outbound=response.filter(el=>el.TYPE==='outbound')

        yield put(getDataSuccess({outbound,inbound,type:activeTab}))
    }
  
}
function* addData(action){
    const { activeTab } = yield select((state) => state.settingsReducer)
    if(activeTab==='province'||activeTab==='city'||activeTab==='barangay'){
        const requestBody = yield call(RequestOptions, 'POST', action.payload)
        const response = yield call(request, `${API_URL}/location`, requestBody)
        
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    
    if(activeTab==='installation issue'){
        const requestBody = yield call(RequestOptions, 'POST', action.payload)
        const response = yield call(request, `${API_URL}/irissues`, requestBody)
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    if(activeTab==='installation materials'){
        const requestBody = yield call(RequestOptions, 'POST', action.payload)
        const response = yield call(request, `${API_URL}/materials`, requestBody)
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    if(activeTab==='inbound'||activeTab==='outbound'){
        const requestBody = yield call(RequestOptions, 'POST', action.payload)
        const response = yield call(request, `${API_URL}/clpurpose`, requestBody)
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    
}

function* deleteData(action){
    const { activeTab } = yield select((state) => state.settingsReducer)
    
    if(activeTab==='province'||activeTab==='city'||activeTab==='barangay'){
        const response = yield call(request, `${API_URL}/location/`+action.payload, {method:'delete'})
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    
    if(activeTab==='installation issue'){
        const response = yield call(request, `${API_URL}/irissues/`+action.payload, {method:'delete'})
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    if(activeTab==='installation materials'){
        const response = yield call(request, `${API_URL}/materials/`+action.payload, {method:'delete'})
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    if(activeTab==='inbound'||activeTab==='outbound'){
        const response = yield call(request, `${API_URL}/clpurpose/`+action.payload, {method:'delete'})
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    
}

function* updateData(action){
    const { activeTab } = yield select((state) => state.settingsReducer)
    if(activeTab==='province'||activeTab==='city'||activeTab==='barangay'){
        const requestBody = yield call(RequestOptions, 'PUT', action.payload.body)
        const response = yield call(request, `${API_URL}/location/`+action.payload.id, requestBody)
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    if(activeTab==='installation issue'){
        const requestBody = yield call(RequestOptions, 'PUT', action.payload.body)
        const response = yield call(request, `${API_URL}/irissues/`+action.payload.id, requestBody)
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    if(activeTab==='installation materials'){
        const requestBody = yield call(RequestOptions, 'PUT', action.payload.body)
        const response = yield call(request, `${API_URL}/materials/`+action.payload.id, requestBody)
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    if(activeTab==='inbound'||activeTab==='outbound'){
        const requestBody = yield call(RequestOptions, 'PUT', action.payload.body)
        const response = yield call(request, `${API_URL}/clpurpose/`+action.payload.id, requestBody)
        yield call(fetchData)
        yield put(setNotif({message:response.message,status:response.status}))
    }
    
}


function* installSaga() {
    yield takeLatest('settings/getData', fetchData)
    yield takeLatest('settings/addData', addData)
    yield takeLatest('settings/deleteData', deleteData)
    yield takeLatest('settings/updateData', updateData)
}

export default installSaga