import { createSlice } from "@reduxjs/toolkit";

const portCardSlicer = createSlice({
    name: 'ports',
    initialState: {
        ports: {
            deployed: {
                value: 0,
                target: 0,
                isLoading: false
            },
            utilization_rate: {
                value: 0,
                target: 0,
                isLoading: false
            },
            utilized: {
                value: 0,
                target: 0,
                isLoading: false
            },
            available: {
                value: 0,
                target: 0,
                isLoading: false
            },
            reserved: {
                target: 0,
                value: 0,
                isLoading: false
            }
        }
    },
    reducers: {
        getPorts(state){
            // set loading when browser refresh
            state.ports.deployed.isLoading = true
            state.ports.utilized.isLoading = true
            state.ports.available.isLoading = true
            state.ports.utilization_rate.isLoading = true
            state.ports.reserved.isLoading = true

        },
        getPortsSuccess(state, payload){
             //set the deployed ports score and target
            if(payload.payload.deployed){
                state.ports.deployed.isLoading = false
                state.ports.deployed.value = payload.payload.deployed[0]?.count??0
                state.ports.deployed.target = 0
            }

            //set the port utilization rate score and target
            if(payload.payload.utilized){
                state.ports.utilized.isLoading = false
                state.ports.utilized.value = payload.payload.utilized?.result[0]?.count??0
                state.ports.utilized.target = 0
            }

            //set the utilized ports score and target
            if(payload.payload.available){
                state.ports.available.isLoading = false
                state.ports.available.value = payload.payload?.available[0]?.value??0
                state.ports.available.target = 0
            }

              //set the available ports score and target
            if(payload.payload.utilization_rate){
                state.ports.utilization_rate.isLoading = false
                state.ports.utilization_rate.value = payload.payload.utilization_rate[0].value[0]?.count??0
                state.ports.utilization_rate.target = payload.payload.utilization_rate[0]?.targets??0
            }        
        }
    }
})

export const { getPorts, getPortsSuccess } = portCardSlicer.actions
export default portCardSlicer.reducer