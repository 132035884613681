import { call, put, select, takeLatest } from "redux-saga/effects";
import { formatDate } from "src/utils/formatter";
import request from "src/utils/request";
import { getLocationDataSuccess } from "../reducer/portLocationDataReducer";
import { getLocationsSuccess } from "../reducer/portLocationReducer";
import { getLocationGraphSuccess } from "../reducer/portLocationGraphReducer";
import { getPortCitySuccess, importSuccess, setPortSuccess, setPortError } from "../reducer/portCityReducer";
import { RequestOptions } from 'src/helpers/RequestOptions'
import { getHandleErrorVerify } from "src/container/DashboardPage/reducer/authHandlerReducer";


const API_URL = process.env.REACT_APP_API_URL;
/* This is a generator function that is used to fetch the locations from the API. */
function* fetchLocations() {
    const location = yield call(request, `${API_URL}/get-municipalities`)
    yield put(getLocationsSuccess({
        location
    }))
}

/* This is a generator function that is used to fetch the locations data from the API. */
function* fetchLocationsData() {
    const { location, dateRange } = yield select(state => state.portFilterReducer.params)
    const deployed = yield call(request, `${API_URL}/deployed-ports?municipality=${location}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`);
    const utilized = yield call(request, `${API_URL}/utilized-ports?municipality=${location}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`);
    const available = yield call(request, `${API_URL}/available-ports?municipality=${location}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`);
    const utilization_rate = yield call(request, `${API_URL}/port-utilization-rate?municipality=${location}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`);

    let dep, util, avb, util_r = 0
    try {
        dep = deployed[0]?.count
        util = utilized.result[0]?.count
        avb = available[0]?.value
        util_r = utilization_rate[0].value[0]?.count
    } catch (error) {
        console.log(error.message)
    }
    yield put(getLocationDataSuccess({
        deployed: !dep ? 0 : dep,
        utilized: !util ? 0 : util,
        available: !avb ? 0 : avb,
        utilization_rate: !util_r ? 0 : util_r,
    }))

}

/* This is a generator function that is used to fetch the locations graph data from the API. */
function* fetchLocationGraph() {
    const { location, filtertype, dateRange } = yield select(state => state.portFilterReducer.params)
    const util = yield call(request, `${API_URL}/utilized-ports?municipality=${location}&filter_type=${filtertype}&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)

    let dts2 = []
    let vls2 = []
    util.utilized_ports.forEach((item) => {
        dts2.push(item._id)
        vls2.push(item.count)
    })

    yield put(getLocationGraphSuccess({
        utilized: {
            dates: dts2,
            values: vls2
        }
    }))

}

function* fetchCityLocation() {
    const { location } = yield select((state) => state.portFilterReducer.params)
    const api = yield call(request, `${API_URL}/get-port-per-barangay?municipality=${location}`)

    let city = []
    let utilized = []
    let deployed = []
    let targets = []

    api[0]?.results?.forEach((el) => {
        city.push(el._id)
        utilized.push(el.utilized_ports)
        deployed.push(el.deployed_ports)
        targets.push(el.targets)
    })

    const data = {
        city: !city ? [] : city,
        utilized: !utilized ? [] : utilized,
        deployed: !deployed ? [] : deployed,
        targets: !targets ? [] : targets
    }

    yield put(getPortCitySuccess({
        data
    }))

}

function* importPort(data) {
    try {
        let fd = new FormData();
        fd.set('file', data.payload[0])
        const res = yield call(request, API_URL + '/deployed/import', {
            method: 'POST',
            body: fd
        });
        yield put(importSuccess(res));
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }


}

function* setPort(payload) {
    const obj = {
        ...payload.payload
    };

    try {
        const ro = yield call(RequestOptions, 'post', obj);
        const res = yield call(request, API_URL + '/deployed-ports', ro);
        if (res?.status === 'Error')
            yield put(setPortError({ message: res.message }));
        else
            yield put(setPortSuccess(res));
    } catch (error) {
        yield put(getHandleErrorVerify({
            res: {
                message: error.response.status
            }
        }))
    }

}

function* portSaga() {
    yield takeLatest('location/getLocations', fetchLocations)
    yield takeLatest('data/getLocationData', fetchLocationsData)
    yield takeLatest('graph/getLocationGraph', fetchLocationGraph)
    yield takeLatest('portCity/getPortCity', fetchCityLocation)
    yield takeLatest('portCity/importDeployedPorts', importPort)
    yield takeLatest('portCity/setPort', setPort)
}

export default portSaga