import { takeEvery, call, put } from 'redux-saga/effects'
import request from '../../../utils/request'
import { getClientsListSuccess } from '../reducer/clientsListReducer'

const API_URL = process.env.REACT_APP_API_URL;

function* fetchClientsList(filters) {

  const { search, type } = filters.payload;
  // call endpoint for counters
  const clients = yield call(request, API_URL + '/clients?search=' + search + '&type=' + type);
  yield put(getClientsListSuccess({
    clients
  }));
}

// watcher saga: will listen for actions and call worker saga
// takeEvery({reducer name}/function, worker saga, args for worker saga)
function* clientSaga() {
  yield takeEvery('clientsList/getClientsList', fetchClientsList)
}

export default clientSaga
