import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { RequestOptions } from "src/helpers/RequestOptions"
import { formatDate } from "src/utils/formatter"
import request from "src/utils/request"
import { getListModelSuccess, getStatusCardGraphSuccess, updateVerifyStatusOmdSuccess } from "../reducer/modelReducer"

const API_URL = process.env.REACT_APP_API_URL

function* fetchListOmd() {
    const { dateRange, type, search, fiber } = yield select((state) => state.modelObjDevReducer.filter)
    const filter_type = type === "All" || type === undefined ? '' : type
    const filter_search = search === "" || search === undefined ? '' : search

    let response = {}

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/omd?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&installation_type=${filter_type}&keyword=${filter_search}`)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/omd/bida?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&installation_type=${filter_type}&keyword=${filter_search}`)
    }
    // const response = yield call(request, `${API_URL}/omd?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}&installation_type=${filter_type}&keyword=${filter_search}`)
    
    yield put(getListModelSuccess({
        res: {
            data: response.data,
        }
    }))
}

function* updateStatusVerifyOmd(payload) {
    const { value, id, remarks } = payload.payload
    const { fiber } = yield select((state) => state.modelObjDevReducer.filter)

    let response = {}
    const fields = {
        OMD_STATUS: value,
        OMD_REMARKS: remarks === undefined ? '' : remarks
    }

    const requestBody = yield call(RequestOptions, 'PATCH', fields)

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/omd/${id}/status`, requestBody)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/omd/bida/${id}/status`, requestBody)
    }

    yield put(updateVerifyStatusOmdSuccess({
        res: {
            value,
            message: response.message,
            status: response.status
        }
    }))
}

function* fetchStatusCardGraph() {
    const { dateRange, fiber } = yield select((state) => state.modelObjDevReducer.filter)

    let response = {}

    if (fiber === 'fiberx') {
        response = yield call(request, `${API_URL}/omd/status/counter?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    } else if (fiber === 'fiberbida') {
        response = yield call(request, `${API_URL}/omd/bida/status/counter?start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`)
    }
    

    let data = []
    let value = []

    response?.data?.forEach((i) => {
        data.push(i._id)
        value.push(i.count)
    })

    yield put(getStatusCardGraphSuccess({
        data,
        value
    }))
}


function* modelSaga() {
    yield takeEvery('modelDev/getListModel', fetchListOmd)
    yield takeLatest('modelDev/updateVerifyStatusOmd', updateStatusVerifyOmd)
    yield takeEvery('modelDev/getStatusCardGraph', fetchStatusCardGraph)
}

export default modelSaga