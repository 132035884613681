import { createSlice } from '@reduxjs/toolkit'

const targetSettingsSlice = createSlice({
  name: 'targetSettings',
  initialState: {
    isLoading: false,
    targetSettings: {
      AVERAGEREVENUEPERLINE: 0,
      PORTUTILIZATIONRATE: 0,
      TURNINACCOUNTS: 0,
      CLAWBACKRATE: 0,
      CUSTOMERCHURNRATE: 0,
      TICKETSRESOLVEDPERDAY: 0,
      AVERAGERESOLUTIONTIMET1: 0,
      AVERAGERESOLUTIONTIMET2: 0,
      AVERAGEINSTALLATIONTIME: 0,
      SALESFORECASTRATE: 0
    },
    apiResponse: {
      message: '',
      status: '',
    }
  },
  reducers: {
    getTargetSettings(state) {
      state.isLoading = true; // set isLoading to true
    },
    getTargetSettingsSuccess(state, payload) {
      state.isLoading = false; // set isLoading to false
      state.targetSettings = payload.payload.targets.data ?? state.targetSettings; // set targetSettings
    },
    setField(state, payload) {
      state.targetSettings[payload.payload.field] = payload.payload.value
    },
    updateTargetSettings(state) {
      state.isLoading = true; // set isLoading to true
    },
    updateTargetSettingsSuccess(state, payload) { // payload
      state.isLoading = false; // set isLoading to false
      state.apiResponse.message = payload.payload?.targets?.message;
      state.apiResponse.status = payload.payload?.targets?.status;
    },
  }
})

export const { getTargetSettings, getTargetSettingsSuccess, setField, updateTargetSettings, updateTargetSettingsSuccess } = targetSettingsSlice.actions
export default targetSettingsSlice.reducer