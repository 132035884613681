import { createSlice } from '@reduxjs/toolkit'
import {datePresets} from '../helper'

const countersSlice = createSlice({
  name: 'counters',
  initialState: {
    params:{
        dateRange: datePresets[3].value,
    },
    counters:{
        ave_rev_per_line:{
            value:0,
            target:0,
            isLoading: false,
        },
        total_subscribers:{
            value:0,
            // target:0,
            isLoading: false,
        },
        installed_ratio_vs_turnin:{
            value:0,
            // target:0,
            isLoading: false,
        },
        ave_time_turnin_to_installation:{
            value:0,
            target:0,
            isLoading: false,
        },
        ave_res_time:{
            value:0,
            // target:0,
            isLoading: false,
        }
    }
  },
  reducers: {
    getCounters(state) {
        // set isLoading to true
        state.isLoading = true;
        state.counters.ave_rev_per_line.isLoading = true;
        state.counters.installed_ratio_vs_turnin.isLoading = true;
        state.counters.ave_time_turnin_to_installation.isLoading = true;
        state.counters.ave_res_time.isLoading = true;
    },
    getCountersSuccess(state,payload) {

      
        // set average revenue per line
        if(payload.payload.ave_rev_per_line) {
            state.counters.ave_rev_per_line.isLoading = false;
            state.counters.ave_rev_per_line.value=payload.payload.ave_rev_per_line?.ave_rev_per_line?.filter(item => item._id.status==='ACT' )[0]?.average??0;
            state.counters.ave_rev_per_line.target=payload.payload.ave_rev_per_line?.targets??0;
        }

        // set total subscribers
        if(payload.payload.total_subscribers) {
            state.counters.total_subscribers.isLoading = false;
            state.counters.total_subscribers.value = payload.payload.total_subscribers?.count[0]?.metadata[0]?.totalCount??0;  
        }
        
        // set installed ratio vs turnin
        if(payload.payload.installed_ratio_vs_turnin) {
            state.counters.installed_ratio_vs_turnin.isLoading = false;
            state.counters.installed_ratio_vs_turnin.value=payload.payload.installed_ratio_vs_turnin.result[0]?.count??0
        }
        
        // set average time turnin to installation
        if(payload.payload.ave_time_turnin_to_installation) {
            state.counters.ave_time_turnin_to_installation.isLoading = false;
            state.counters.ave_time_turnin_to_installation.value=payload.payload.ave_time_turnin_to_installation.ave_time_turn_in_to_install[0]?.average_hours??0;
            state.counters.ave_time_turnin_to_installation.target=payload.payload.ave_time_turnin_to_installation.targets??0;
        }
        
        // set average response time
        if(payload.payload.ave_res_time) {
            state.counters.ave_res_time.isLoading = false;
            state.counters.ave_res_time.value= payload.payload.ave_res_time.average_resolution_time.length > 0 ? payload.payload.ave_res_time.average_resolution_time[0]?.average_hours : 0;
            state.counters.ave_res_time.target=payload.payload.ave_res_time.targets??0;
        }
    },
    setParams(state, payload) {
        state.params = payload.payload;
    }
  }
})

export const { getCounters, getCountersSuccess, setParams } = countersSlice.actions
export default countersSlice.reducer