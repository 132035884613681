import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "src/utils/helper";

/* Creating a slice of the redux store. */
const totalJOSlice = createSlice({
    name: 'totalJO',
    initialState: {
        params: {
            dateRange: datePresets[2].value,
        },
        isLoading:false,
        data: {
            total_jo_created: 0,
            jo_returned: 0,
        }
    },
    reducers: {
        getTotalJO(state) {
            state.isLoading = true;
        },
        getTotalJOSuccess(state, payload) {
            state.isLoading = false;
            state.data={...state.data,...payload.payload.data}
        },
    }
})

/* Exporting the actions and reducer. */
export const { setTotalJO, getTotalJO, getTotalJOSuccess } = totalJOSlice.actions
export default totalJOSlice.reducer