import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    notif:{
        message:'',
        status:'success',
    },
    locations:{
        province:[],
        city:[],
        barangay:[],
    },
    issues:[],
    materials:[],
    purposes:{
        inbound:[],
        outbound:[]
    },
    isModalOpen:false,
    modalState:'add',
    updateId:'',
    isloading:false,
    activeTab:'province'
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
      setNotif:(state,action)=>{
        state.notif=action.payload
        if(action.payload.status?.toLowerCase()==='success')
            state.isModalOpen=false
      },
      setDisposeNotif:(state,action)=>{
        state.notif.message=''
        state.notif.status=''
      },
      setTab:(state,action)=>{
        state.activeTab=action.payload
      },
      getData:(state,action)=>{
        state.isloading=true;
      },
      getDataSuccess:(state,action)=>{
        const {payload} =action
        state.isloading=false;
        if(payload.type==='location')
            state.locations=action.payload.locations
        if(payload.type==='issue')
            state.issues=action.payload.issues
        if(payload.type==='material')
            state.materials=action.payload.materials
        if(payload.type==='inbound')
            state.purposes.inbound=action.payload.inbound
        if(payload.type==='outbound')
            state.purposes.outbound=action.payload.outbound
      },
      addData:(state,action)=>{

      },
      updateData:(state,action)=>{
      },
      changeModalState:(state,action)=>{
        state.modalState=action.payload.mode
        state.updateId=action.payload.id??''
      },
      deleteData:(state,action)=>{
      },
      toggleModal:(state,action)=>{
        state.isModalOpen=action.payload
      }
    }
})
export const {
    setNotif,
    setDisposeNotif,
    setTab,
    getData,
    getDataSuccess,
    addData,
    updateData,
    deleteData,
    toggleModal,
    changeModalState
} = settingsSlice.actions
export default settingsSlice.reducer