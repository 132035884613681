import { createSlice } from '@reduxjs/toolkit'

const clientsListSlice = createSlice({
  name: 'clientsList',
  initialState: {
    isLoading: false,
    data: [
      // {
      //   "ACCOUNT_NO": "1626202005117",
      //   "SUBNAME": "DINGLASAN, MARIETTA CASTILLO",
      //   "PACKAGENAME": "FIBER X 1500 50MBPS (NO UPFRONT FEES)",
      //   "STATUS": "ACT",
      //   "DATEINSTALLED": "2022-04-01T05:19:00.000Z",
      //   "ADDRESS": "NA, SITIO BARACAN I, TAGUMPAY, BACO, ORIENTAL MINDORO, PHILIPPINES",
      //   "DATEAPPLIED": "2022-03-27T06:18:00.000Z",
      //   "getFrom": "IBAS"
      // }
    ],
  },
  reducers: {
    getClientsList(state, filter) {
        // set isLoading to true
        state.isLoading = true;
    },
    getClientsListSuccess(state, payload) {
      // set isLoading to false
      state.isLoading = false;
      // set data
      state.data = payload.payload.clients;
    },

  }
})

export const { getClientsList, getClientsListSuccess } = clientsListSlice.actions
export default clientsListSlice.reducer