import { put, takeEvery, select, call, takeLatest } from "redux-saga/effects"
import LocalStorage from "src/utils/LocalStorage";
import request from "src/utils/request";
import { getHandleErrorVerify, getUserDetailsSuccess } from "../reducer/authHandlerReducer"

const API_URL = process.env.REACT_APP_API_URL;

function* ErrorHandler() {
    const res = yield select(state => state.errorHandlerReducer.error)
    yield put(getHandleErrorVerify({
        res
    }))
}

function* fetchUserDetails() {
    const response = yield call(request, `${API_URL}/auth/verify`, {
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': LocalStorage.readLocalStorage('token')
        }
    })
    yield put(getUserDetailsSuccess({
        res: {
            response
        }
    }))
}

function* authSaga() {
    yield takeEvery('authHandler/getHandleError', ErrorHandler)
    yield takeLatest('authHandler/getUserDetails', fetchUserDetails)
}

export default authSaga