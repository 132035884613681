import { createSlice } from "@reduxjs/toolkit";
import { datePresets } from "../helper";
const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        params: {
            dateRange: datePresets[2].value,
            filtertype: 'Day'
        }
    },
    reducers: {
        setParams: (state, payload) => {
            state.params = payload.payload;
        }
    }
})

export const { setParams } = filtersSlice.actions
export default filtersSlice.reducer
